import { FC, useEffect, useState } from 'react';
import LanguageSelector from './index';
import { LanguageSelectorControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageEmployee } from '../../../../store/employee';
import { getLanguageCompany } from '../../../../store/company';
import UserService from '../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../store/internal';
import { useNavigate } from 'react-router-dom';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';
import { ptBR } from 'date-fns/locale/pt-BR';

registerLocale('es', es);
registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('pt-BR', ptBR);
registerLocale('pt-PT', ptBR);

const ModalLanguageSelectorController: FC<LanguageSelectorControllerInterface> = (props) => {
  const { t, i18n } = useTranslation('ModalLanguageSelector');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const values = {
    employee_language: useSelector(getLanguageEmployee),
    company_language: useSelector(getLanguageCompany)
  }

  const currentLngStorage = localStorage.getItem('i18nextLng');

  const AppRequesterConst = new AppRequesterController();

  const [language, setLanguage] = useState(values.employee_language || values.company_language || currentLngStorage || '');
  const [currentLanguage, setCurrentLanguage] = useState(values.employee_language || values.company_language || currentLngStorage || '');
  const [isLoading, setIsLoading] = useState(false);

  const languages = [
    { language: 'pt-br', flag: 'br', title: 'Português (BR)' },
    { language: 'pt-pt', flag: 'pt', title: 'Português (PT)' },
    { language: 'es', flag: 'es', title: 'Español' },
    { language: 'en', flag: 'gb', title: 'English' },
    { language: 'fr', flag: 'fr', title: 'Français' },
  ];

  const i18nAvailable = ['pt-br', 'pt-pt', 'es', 'en', 'fr'];

  useEffect(() => {
    if (language.toLocaleLowerCase() === 'br') {
      i18n.changeLanguage('pt-BR');
      localStorage.setItem("i18nextLng", 'pt-BR');
    } else if (language.toLowerCase() === 'pt') {
      i18n.changeLanguage('pt-PT');
      localStorage.setItem("i18nextLng", 'pt-PT');
    } else if (language.toLowerCase() === 'en') {
      i18n.changeLanguage('en');
      localStorage.setItem("i18nextLng", 'en');
    } else if (language.toLowerCase() === 'fr') {
      i18n.changeLanguage('fr');
      localStorage.setItem("i18nextLng", 'fr');
    } else {
      i18n.changeLanguage(language?.toLowerCase() || currentLngStorage);
      localStorage.setItem('i18nextLng', language?.toLowerCase() || currentLngStorage);
    }
    setDefaultLocale(language || 'pt-BR');
  }, [language]);

  const onSubmit = async () => {
    if (language !== currentLanguage) {
      const headers = UserService.getHeaders()
      const payload = {
        employee_language: language
      }
      
      await AppRequesterConst.Patch(
        '/agent/update/employee-language', payload, { headers },
        (response: Object) => {
          return response;
        },
        (data: { data: { user_id: string; }; }) => {
          props.logoutBySetLanguage();
          dispatch(setShowAlertFeedback({ message: t("sucess_put"), visibility: true, signalIcon: true }));
          closeModal();
        },
        (error) => {
          dispatch(setShowAlertFeedback({ message: t("put_error"), visibility: true, signalIcon: false }));
        }, navigate, dispatch, setIsLoading
      );
    } else {
      setCurrentLanguage(language);
      closeModal();
    }
  }

  const getAvailableLanguages = () => {
    const available = languages.filter(item => i18nAvailable.includes(item.language));

    const userLanguage = available.find(item => item.language === values.employee_language);
    const ordered = userLanguage ? [userLanguage] : [];
    available.sort((a, b) => a.title.localeCompare(b.title)).forEach((item) => {
      if (item.language !== values.employee_language) {
        ordered.push(item);
      }
    });

    return ordered;
  }

  const isSelectedLanguage = (employee_language: string, language: string): boolean => {
    return employee_language?.toLocaleLowerCase() === language?.toLowerCase();
  }

  const closeModal = (submited?: boolean) => {
    props.setShowModal(false);
    setLanguage(submited ? language : currentLanguage);
  }

  return (
    <LanguageSelector 
      t={t}
      showModal={props.showModal}
      setShowModal={props.setShowModal}
      onSubmit={onSubmit}
      isLoading={isLoading}
      getAvailableLanguages={getAvailableLanguages}
      isSelectedLanguage={isSelectedLanguage}
      language={language}
      setLanguage={setLanguage}
      closeModal={closeModal}
    />
  );
};

export default ModalLanguageSelectorController;
