import { Dispatch } from "redux"
import { SocketService } from "../../core/context/socket-context";
import { CallcenterContextType } from "../../core/context/callcenter-context";
import SocketEvents from "../../core/constants/socket-events";
import { Ticket } from "../../domain/entities/ticket";
import { CallStatusEvent, SIPmlType, SipCallSession, SipConfigCallSession, SipConfigRegisterSession, SipConfigStack, SipRegisterSession, SipStack } from "./callcenter-service.model";
import { SocketReceiveAppEvents } from "../../core/context/socket-context-v2";
import { MutableRefObject } from "react";

declare const SIPml: SIPmlType;

export class CallcenterService {
  private INFRA_WSS: string;

  // Infra servers
  private infras = {
    'infra_staging': 'staging.sip.elife.com.br',
    'infra_staging2': 'staging2.sip.elife.com.br',
    'infra_br': 'sip.elife.com.br',
    'infra_eu': 'sip.eu.elife.com.br',
    'infra_lasa': 'lasa.sip.elife.com.br'
  }

  // States
  private s_session_status: 'disconnected' | 'connecting' | 'connected';
  private s_session_callcenter_id: string;
  private s_session_credentials: { username: string, password: string, display_name: string };
  private s_session_transport_error: boolean;

  private s_events_type: string;
  private s_events_description: string;
  private events_logs: { date: Date, type: string, description: string, sip_event: 'sip_stack_event' | 'sip_session_event' }[];

  private s_call_status_event: CallStatusEvent;
  private s_call_status_direction: 'incoming' | 'outgoing' | undefined;
  private s_call_status_incoming_number: string;
  private s_call_status_incoming_name: string;
  private s_call_status_is_muted: boolean;
  private s_call_status_is_hold: boolean;
  private s_call_status_is_active: boolean;
  private s_call_status_duration: number;
  private s_call_status_start_date: Date;

  private s_call_info_display: string;
  private s_call_info_destiny: string;
  private s_call_info_destiny_transfer: string;
  private s_call_info_id_response: string;
  private s_call_info_is_transfer_assisted: boolean;

  private s_ticket_info: any;

  // SIPML5
  private sip_stack: SipStack;
  private sip_session_call: SipCallSession;
  private sip_session_register: SipRegisterSession;
  private sip_session_transfer: any;

  private sip_config_stack: SipConfigStack;
  private sip_config_call_session: SipConfigCallSession;
  private sip_config_register_session: SipConfigRegisterSession;

  // SIP Audio
  private audioRemote: HTMLAudioElement;
  private audioIncomingRingtone: HTMLAudioElement;
  private audioOutgoingRingbacktone: HTMLAudioElement;
  private audioDtmfTone: HTMLAudioElement;
  private audioDtmfTones: {
    '0': HTMLAudioElement;
    '1': HTMLAudioElement;
    '2': HTMLAudioElement;
    '3': HTMLAudioElement;
    '4': HTMLAudioElement;
    '5': HTMLAudioElement;
    '6': HTMLAudioElement;
    '7': HTMLAudioElement;
    '8': HTMLAudioElement;
    '9': HTMLAudioElement;
    '#': HTMLAudioElement;
    '*': HTMLAudioElement;
  }

  // Others
  private call_timer:  NodeJS.Timeout;
  private reconnect_interval:  NodeJS.Timeout;
  private script_loaded: boolean = false;
  private cancel_reconnect: boolean;

  constructor (private main: MainType) {
    // console.log(`::: CONSTRUCTOR - CallcenterService - ${new Date().toLocaleString('pt-BR')} :::`);
    this.INFRA_WSS = process.env.REACT_APP_INFRA_WSS === 'staging2.sip.elife.com.br' ? process.env.REACT_APP_INFRA_WSS : this.INFRA_WSS;
    this.events_logs = [];

    this.resetStates('all');
    this.loadAudios();

    // Load sockets events
    this.loadSocketEventNewTicket();
  }

  /* ======= SIPML ======= */
  public initEngine = (scriptLoaded?: boolean) => {
    if (!this.existsSIPml() && !scriptLoaded) {
      this.loadSIPml();
    } else {
      this.audioRemote.autoplay = true;
      this.audioIncomingRingtone.loop = true;
      this.audioOutgoingRingbacktone.loop = true;

      this.s_session_transport_error = false;
      this.cancel_reconnect = false;
      this.main.context.setSessionTransportError(false);

      try {
        SIPml.init();
        SIPml.setDebugLevel('fatal');

        this.sip_config_stack = {
          realm: this.INFRA_WSS,
          impi: undefined, // <extension> // PrivateIdentity
          impu: undefined, // 'sip:<extension>@staging.sip.elife.com.br', // PublicIdentity
          password: undefined,
          display_name: undefined,
          websocket_proxy_url: `wss://${this.INFRA_WSS}:8089/ws`,
          outbound_proxy_url: null,
          ice_servers: '[]', // "[{ url: 'stun:stun.l.google.com:19302' }]",
          enable_rtcweb_breaker: true,
          enable_early_ims: true,
          enable_media_stream_cache: true,
          bandwidth: { audio: 64, video: 0 },
          video_size: null,
          sip_headers: [
            { name: 'User-Agent', value: 'IM-client/OMA1.0 sipML5-v1.2016.03.04' },
            { name: 'Organization', value: 'VozXpress' }
          ],
          events_listener: { events: '*', listener: e => this.onSipStackEvents(e) },
        }

        this.sip_config_call_session = {
          audio_remote: this.audioRemote,
          events_listener: { events: '*', listener: (e: any) => this.onSipSessionEvents(e) }
        }

        this.sip_config_register_session = {
          expires: 300, // Tempo de envio de status para a infra - 20 -> 10s; 300 -> 150s
          events_listener: { events: '*', listener: e => this.onSipSessionEvents(e) },
          sip_caps: [
            { name: '+g.oma.sip.im', value: null },
            { name: '+sip.ice' },
            { name: '+audio', value: null },
            { name: 'language', value: '\"en,pt-br\"' }
          ]
        };

      } catch (error: any) {
        console.log('\n::: CALLCENTER - Error on init SIPML5 libraries');
        console.log(error);
      }
    }
  }

  public register = (): number => {
    try {
      this.setSessionStatus('connecting');
      this.cancel_reconnect = false;
      this.main.context.setCancelReconnection(false);

      const { username, password, display_name } = this.s_session_credentials;

      if (window.navigator.onLine) {
        if (username && password && this.INFRA_WSS) {
          this.sip_config_stack.impi = username;
          this.sip_config_stack.password = password;
          this.sip_config_stack.display_name = display_name || 'Unknow';
          this.sip_config_stack.impu = `sip:${username}@${this.INFRA_WSS}`;
          this.sip_config_stack.realm = this.INFRA_WSS;
          this.sip_config_stack.websocket_proxy_url = `wss://${this.INFRA_WSS}:8089/ws`;

          this.sip_stack = new SIPml.Stack(this.sip_config_stack);

          const stackStatus = this.sip_stack.start();

          if (stackStatus === 0) {
            this.setSessionTransportError(false);
            clearInterval(this.reconnect_interval);
          }

          return stackStatus;

        } else {
          this.setSessionStatus('disconnected');
          return -1;
        }
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on register()');
      console.log(error);
      this.main.context.setSessionStatus('disconnected');
      return -1;
    }
  }

  public unregister = () => {
    if (this.sip_stack) {
      this.sip_stack.stop();
    }

    this.resetStates('call_info');
    this.resetStates('call_status');
    this.resetStates('events');
    this.setSessionTransportError(false);
  }

  public startCall = () => {
    if (this.sip_stack || this.sip_session_call) {
      if (this.sip_stack && !this.sip_session_call && this.s_call_info_display.length > 0) {
        this.setCallStatusDirection('outgoing');
        this.setCallStatusEvent('trying');
        this.setCallInfoDestiny(this.s_call_info_display);

        this.sip_session_call = this.sip_stack.newSession('call-audio', this.sip_config_call_session);

        // console.log('startCall()', this.s_call_info_display);

        const callStatus = this.sip_session_call.call(this.s_call_info_display);

        if (callStatus !== 0) { // Falha na ligação
          this.sip_session_call = null;
        } else {

        }
      } else if (this.sip_session_call) {
        this.sip_session_call.accept(this.sip_config_call_session);
      }
    } else {
      console.log('::: CALLCENTER - Você não está conectado :::');
    }
  }

  public hangup = () => {
    if (this.sip_session_call) {
      this.sip_session_call.hangup({ events_listener: { events: '*', listener: (e: any) => this.onSipSessionEvents(e) } });
    }
  }

  public acceptCall = () => {
    try {
      if (this.sip_session_call) {
        this.sip_session_call.accept();
        this.s_call_status_is_hold = false;
        this.s_call_status_is_muted = false;
        this.main.context.setCallStatusIsHold(false);
        this.main.context.setCallStatusIsMuted(false);
        this.setCallStatusEvent('in-call');
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on acceptCall()');
      console.log(error);
    }
  }

  public rejectCall = () => {
    try {
      if (this.sip_session_call) {
        this.sip_session_call.reject();
        this.s_call_status_is_hold = false;
        this.s_call_status_is_muted = false;
        this.main.context.setCallStatusIsHold(false);
        this.main.context.setCallStatusIsMuted(false);
        this.setCallStatusEvent(undefined);
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on rejectCall()');
      console.log(error);
    }
  }

  public transferCall = (command: string, number: string) => {
    try {
      if (this.sip_session_call) {
        if (number) {
          try {
            if (number.startsWith('0')) { // Externa
              const transferStatus = this.sip_session_call.transfer(number);
  
              if (transferStatus !== 0) {
                return;
              }
            } else {
              command.split('').map(chr => {
                this.sip_session_call.dtmf(chr);
              });
              setTimeout(() => {
                number.split('').map(chr => {
                  this.sip_session_call.dtmf(chr);
                });
              }, 3000);
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on transferCall()');
      console.log(error);
    }
  }

  public toggleHoldResume = () => {
    try {
      if (this.sip_session_call) {
        const holdStatus = this.s_call_status_is_hold ? this.sip_session_call.resume() : this.sip_session_call.hold();
        this.s_call_status_is_hold = !this.s_call_status_is_hold;
        this.main.context.setCallStatusIsHold(this.s_call_status_is_hold);
  
        if (holdStatus !== 0) {
          //console.log('======= FALHA AO COLOCAR CHAMADA EM ESPERA OU RESUMIR CHAMADA');
          return;
        }
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on toggleHoldResume()');
      console.log(error);
    }
  }

  public toggleMute() {
    try {
      if (this.sip_session_call) {
        const bMute = !this.sip_session_call.bMute;
        const mutedStatus = this.sip_session_call.mute('audio', bMute);
        if (mutedStatus === 0) {
          this.sip_session_call.bMute = bMute;
          this.s_call_status_is_muted = bMute;
          this.main.context.setCallStatusIsMuted(bMute);
        }
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on toggleMute()');
      console.log(error);
    }
  }

  public sendDTMF = (c: string) => {
    try {
      // console.log(c);
      if (this.sip_session_call && c) {
        // console.log(c);
        const dtmfStatus = this.sip_session_call.dtmf(c);
        if (dtmfStatus === 0) {
          try {
            if (this.audioDtmfTones[c]) {
              this.audioDtmfTones[c].play();
            } else {
              this.audioDtmfTone.play();
            }
          } catch (e: any) {
            //console.log('SIP SEND DTMF ERROR');
            console.log(e);
          }
        }
      } else {
        if (this.audioDtmfTones[c]) {
          this.audioDtmfTones[c].play();
        } else {
          this.audioDtmfTone.play();
        }
      }
    } catch (error) {
      console.log('\n::: CALLCENTER - Error on sendDTMF()');
      console.log(error);
    }
  }

  /* ======= Listeners ======= */

  private onSipStackEvents = (event: any) => {
    // console.log('\n===onSipStackEvents');
    // console.log({ a: 'onSipStackEvents', type: event.type, desc: event.description}, '\n');
    // console.log('stack:', event.type, ' -', event.description);

    this.events_logs.unshift({
      date: new Date(Date.now()), type: event.type, description: event.description, sip_event: 'sip_stack_event'
    });

    this.setSessionTransportError(false);
    this.setInfoEvent(event.type, event.description);

    switch (event.type) {
      case 'starting': {
        // console.log('::: INICIANDO STACK EVENTS');
        if (this.sip_session_call) {
          this.sip_session_call.hangup();
          this.sip_session_call = null;
        }
        break;
      }
      case 'started': {
        try {
          this.sip_session_register = this.sip_stack.newSession('register', this.sip_config_register_session);
          this.sip_session_register.register();
        } catch (error) {
          console.log('\n::: CALLCENTER - Error on onSipStackEvents() -', event.type);
          console.log(error);
        }
        break;
      }

      case 'stopping':
      case 'stopped':
      case 'failed_to_start':
      case 'failed_to_stop': {
        // console.log('\n\n\n')
        // console.log('== register session?', event.session === this.sip_session_register);
        // console.log('== call session?', event.session === this.sip_session_call);

        if (this.sip_session_call) {
          this.sip_session_call.hangup();
        }

        this.sip_stack = null;
        this.sip_session_register = null;
        this.sip_session_call = null;

        this.audioIncomingRingtone.pause();
        this.audioOutgoingRingbacktone.pause();

        this.setSessionStatus('disconnected');
        this.resetStates("call_status");
        this.resetStates("call_info");

        if (this.s_session_transport_error && event.type === 'failed_to_start') {
          this.reconnect();
        }
        break;
      }

      case 'i_new_call': {
        if (this.sip_session_call) {
          // Não aceitar chamada recebida se já estiver em uma
          console.log('i_new_call - sip_session_call exists');
          event.newSessionn.hangup();
        } else {
          this.sip_session_call = event.newSession;
          this.sip_session_call.setConfiguration(this.sip_config_call_session);
          this.audioIncomingRingtone.play();
          
          this.setCallStatusEvent('ringing');
          this.setCallStatusDirection('incoming');
          this.setCallStatusIncomingNumber(this.sip_session_call.getRemoteFriendlyName() || '00000000000')
          this.setCallStatusIncomingName(this.getDisplayName(event));
          this.setCallInfoIdResponse(this.getIdResponse(event));
        }
        break;
      }

      case 'm_permission_requested': {
        // console.log('ENTROU AQUI - M_PERMISSION_REQUESTED');
        // console.log('direction:', this.s_call_status_direction);
        break;
      }

      case 'm_permission_accepted':
      case 'm_permission_refused': {
        if (event.type === 'm_permission_accepted') {

        } else {
          this.audioIncomingRingtone.pause();
          this.audioOutgoingRingbacktone.pause();
          this.setCallStatusEvent(undefined);
        }
        break;
      }
    }
  }

  private onSipSessionEvents = (event: any) => {
    // console.log('\n===onSipSessionEvents');
    // console.log({ a: 'onSipSessionEvents', type: event.type, desc: event.description}, '\n');
    // console.log('session:', event.type, ' -', event.description);

    this.events_logs.unshift({
      date: new Date(Date.now()), type: event.type, description: event.description, sip_event: 'sip_session_event'
    });

    this.setSessionTransportError(false);
    this.setInfoEvent(event.type, event.description);

    switch (event.type) {
      case 'sent_request': {
        // console.log('Enviando requisição de status pra infra', new Date().toLocaleString());
        break;
      }

      case 'connecting':
      case 'connected': {
        const isConnected = event.type === 'connected';

        if (event.session === this.sip_session_register) { // Evento de registro
          clearInterval(this.reconnect_interval);

          this.setSessionTransportError(false);
          this.setSessionStatus(event.type);
        } else if (event.session === this.sip_session_call) { // Evento de chamada
          if (event.description.toUpperCase() === 'IN CALL') {
            // console.log('ENTROU AQUI - IN CALL');
          }

          if (isConnected) {
            this.setCallStatusIsActive(true);
            this.audioOutgoingRingbacktone.pause();
            this.audioIncomingRingtone.pause();
            this.handleCallTimer('start');
            this.setCallStatusEvent('in-call');

            this.s_call_status_start_date = new Date();
            this.main.context.setCallStatusStartDate(this.s_call_status_start_date);
          }
        }

        break;
      }

      case 'terminating':
      case 'terminated': {
        const endCallDescriptions = [
          'CALL TERMINATED',
          'CALL REJECTED',
          'SERVICE UNAVAILABLE',
          'REQUEST CANCELLED',
          'REQUEST TERMINATED',
          'DECLINE',
          'FORBIDDEN'
        ];

        this.setTicketInfo(null);
        
        if (event.type === 'terminated' && endCallDescriptions.includes(event.description.toUpperCase())) {
          this.handleCallTimer('hangup');
          this.resetStates('call_status');
          this.resetStates('call_info');
        }

        if (event.session === this.sip_session_register) { // Evento de registro
          this.sip_session_register = null;
          this.sip_session_call = null;

          this.setSessionStatus('disconnected');
        } else if (event.session === this.sip_session_call) { // Evento de chamada
          this.sip_session_call = null;
          this.audioOutgoingRingbacktone.pause();
          this.audioIncomingRingtone.pause();

          this.setCallStatusEvent(undefined);
        }

        break;
      }

      case 'transport_error': {
        this.reconnect();
        this.cancel_reconnect = false;
        this.main.context.setCancelReconnection(false);

        this.setSessionTransportError(true);

        break;
      }

      case 'i_ect_new_call': {
        this.sip_session_transfer = event.session;

        break;
      }

      case 'i_ao_request': {
        //console.log('======= I_AO_REQUEST');
        if (event.session === this.sip_session_call) {
          const sipResponseCode = event.getSipResponseCode();
          if (sipResponseCode === 180 || sipResponseCode === 183) {
            this.audioOutgoingRingbacktone.play();
            this.setCallStatusEvent('calling');
          } else {
            // console.log('VERIFICAR - I_AO_REQUEST - ELSE');
            this.audioOutgoingRingbacktone.pause();
            this.setCallStatusEvent('trying');
          }
        }

        break;
      }

      case 'm_early_media': {
        //console.log('M_EARLY_MEDIA');
        if (event.session === this.sip_session_call) {
          this.audioOutgoingRingbacktone.pause();
          this.audioIncomingRingtone.pause();
          // console.log('VERIFICAR - m_early_media - set call status event');
          // console.log('======= EARLY MEDIA STARTED');
          this.setCallStatusEvent(undefined)
        }
        break;
      }

      case 'm_stream_audio_remote_added': {
        //console.log('======= MEDIASTREAM AUDIO REMOTE ADDED');
        break;
      }

      case 'm_local_hold_ok': { // Chamada em espera - REVISAR
        //console.log('M_LOCAL_HOLD_OK');
        if (event.session === this.sip_session_call) {
          if (this.sip_session_call.bTransfering) {
            this.sip_session_call.bTransfering = false;
          }
          this.sip_session_call.bHeld = true;

          this.setCallStatusIsHold(true);
        }
        break;
      }

      case 'm_local_hold_nok': { // Falha ao colocar chamada em espera
        //console.log('M_LOCAL_HOLD_NOK');
        if (event.session === this.sip_session_call) {
          this.sip_session_call.bTransfering = false;
          //console.log('======= FAILED TO PLACE REMOTE PARTY ON HOLD');
        }
        break;
      }

      case 'm_local_resume_ok': { // Chamada retirada da espera
        if (event.session === this.sip_session_call) {
          this.sip_session_call.bTransfering = false;
          this.sip_session_call.bHeld = false;
          this.setCallStatusIsHold(false);
        }
        break;
      }

      case 'm_local_resume_nok': { // Falha ao retirar chamada da espera
        //console.log('M_LOCAL_RESUME_NOK');
        if (event.session === this.sip_session_call) {
          this.sip_session_call.bTransfering = false;
          //console.log('======= FAILED TO UNHOLD CALL');
        }
        break;
      }

      case 'm_remote_hold': { // Colocado em espera pela parte remota
        //console.log('M_REMOTE_HOLD');
        if (event.session === this.sip_session_call) {
          //console.log('======= PLACED ON HOLD BY REMOTE PARTY');
        }
        break;
      }

      case 'm_remote_resume': { // Chamada retirada da espera pela parte remota
        //console.log('M_REMOTE_RESUME');
        if (event.session === this.sip_session_call) {
          //console.log('======= TAKEN OFF HOLD BY REMOTE PARTY');
        }
        break;
      }

      case 'o_ect_trying': { // Transferência de chamada em progresso
        // console.log('O_ECT_TRYING');
        if (event.session === this.sip_session_call) {
          // console.log('======= CALL TRANSFER IN PROGRESS...');
        }
        break;
      }

      case 'o_ect_accepted': { // Transferência de chamada aceita
        //console.log('O_ECT_ACCEPTED');
        if (event.session === this.sip_session_call) {
          //console.log('======= CALL TRANSFER ACCEPTED');
        }
        break;
      }

      case 'o_ect_completed':
      case 'i_ect_completed': { // Transferência de chamada completada
        // console.log('I_ECT_COMPLETED');
        // console.log('O_ECT_COMPLETED');
        if (event.session === this.sip_session_call) {
          if (this.sip_session_transfer) {
            this.sip_session_call = this.sip_session_transfer;
          }
          this.sip_session_transfer = null;
          // console.log('======= CALL TRANSFER COMPLETED');
        }
        break;
      }

      case 'o_ect_failed':
      case 'i_ect_failed': { // Falha na transferência de chamada
        // console.log('I_ECT_FAILED');
        // console.log('O_ECT_FAILED');
        if (event.session === this.sip_session_call) {
          // console.log('======= CALL TRANSFER FAILED');
        }
        break;
      }

      case 'o_ect_notify':
      case 'i_ect_notify': {
        //console.log('O_ECT_NOTIFY');
        //console.log('I_ECT_NOTIFY');
        if (event.session === this.sip_session_call) {
          //console.log('======= CALL TRANSFER:', event.getSipResponseCode());
          //console.log('======= CALL TRANSFER:', event.description);
          if (event.getSipResponseCode() >= 300) {
            if (this.sip_session_call.bHeld) {
              this.sip_session_call.resume();
            }
          }
        }
        break;
      }

      case 'i_ect_requested': {
        //console.log('I_ECT_REQUESTED');
        if (event.session === this.sip_session_call) {
          const s_message = 'Aceitar transferência de: [' + event.getTransferDestinationFriendlyName() + ']?'; // FIXME
          if (window.confirm(s_message)) {
            //console.log('======= CALL TRANSFER IN PROGRESS...');
            this.sip_session_call.acceptTransfer();
            break;
          }
          this.sip_session_call.rejectTransfer();
        }
        break;
      }

      default:
        break;
    }
  }

  /* ======= Aux ======= */
  private handleCallTimer = (action: 'start' | 'hangup') => {
    if (action === 'start') {
      this.s_call_status_duration = 0;
      this.main.context.setCallStatusDuration(0);

      this.call_timer = setInterval(() => {
        this.s_call_status_duration += 1;
        this.main.context.setCallStatusDuration(this.s_call_status_duration);
      }, 1000);
    } else {
      clearInterval(this.call_timer);
    }
  }

  public reconnect = () => {
    this.reconnect_interval = setInterval(() => {
      console.log('reconnecting...');
      try {
        if (this.cancel_reconnect) {
          this.setSessionStatus('disconnected');
          clearInterval(this.reconnect_interval);
        }

        const status = this.register();
        if (status === 0) {
          clearInterval(this.reconnect_interval);
        }

        if (this.s_session_status === 'connected') {
          clearInterval(this.reconnect_interval);
        }
      } catch (err) {
        clearInterval(this.reconnect_interval);
      }
    }, 10000); // 10s
  }

  private getIdResponse = (event: { o_event: { o_message: { ao_headers: Object[]; }; }; }): string => {
    const headers = event.o_event.o_message.ao_headers;
    let id_response = undefined;
    headers.forEach((header: { s_name: string; s_value: string; }) => {
      if (header.s_name === 'X-Response') {
        id_response = header.s_value;
      }
    });
    return id_response;
  }

  private getDisplayName(event: { o_event: { o_message: { o_hdr_From: { s_display_name: string; }; }; }; }): string {
    return event.o_event.o_message.o_hdr_From.s_display_name;
  }
  

  /* ======= Socket ======= */
  private loadSocketEventNewTicket = () => {
    const interval = setInterval(() => { // O interval é pra verificar se o socket já está instanciado
      if (this.main.socketInitializedRef?.current && this.main?.context) {

        const updateTicketInfoCallcenter = (ticket: Ticket) => {
          this.setTicketInfo(ticket);
        }
        
        this.main.registerSocketAppEvent('new-ticket-from-consumer-telephony', updateTicketInfoCallcenter);
        
        clearInterval(interval);
      }
    }, 1000);
  }


  /* ======= Sets ======= */
  public setCredentials = (credentials: { username: string, password: string, display_name: string }, callcenter_owner: string, infra_server: string) => {
    this.INFRA_WSS = process.env.REACT_APP_INFRA_WSS === 'staging2.sip.elife.com.br' ? process.env.REACT_APP_INFRA_WSS : this.infras[infra_server]; 
    this.s_session_credentials = credentials;
    this.s_session_callcenter_id = callcenter_owner;
    this.main.context.setSessionCredentials(credentials);
    this.main.context.setSessionCallcenterId(callcenter_owner);
  }

  private setSessionStatus = (value: 'disconnected' | 'connecting' | 'connected') => {
    this.s_session_status = value;
    this.main.context.setSessionStatus(value);
  }

  private setSessionTransportError = (value: boolean) => {
    this.s_session_transport_error = value;
    this.main.context.setSessionTransportError(value);
  }

  private setCallStatusEvent = (value: CallStatusEvent) => {
    this.s_call_status_event = value;
    this.main.context.setCallStatusEvent(value);
  }

  private setCallStatusDirection = (value: 'incoming' | 'outgoing') => {
    this.s_call_status_direction = value;
    this.main.context.setCallStatusDirection(value);
  }

  private setCallStatusIncomingNumber = (value: string) => {
    this.s_call_status_incoming_number = value;
    this.main.context.setCallStatusIncomingNumber(value);
  }

  private setCallStatusIncomingName = (value: string) => {
    this.s_call_status_incoming_name = value;
    this.main.context.setCallStatusIncomingName(value);
  }

  private setCallStatusIsActive = (value: boolean) => {
    this.s_call_status_is_active = value;
    this.main.context.setCallStatusIsActive(value);
  }

  private setCallStatusIsHold = (value: boolean) => {
    this.s_call_status_is_hold = value;
    this.main.context.setCallStatusIsHold(value);
  }

  public setCallInfoDisplay = (value: string) => {
    this.s_call_info_display = value;
    this.main.context.setCallInfoDisplay(value);
  }

  private setCallInfoDestiny = (value: string) => {
    this.s_call_info_destiny = value;
    this.main.context.setCallInfoDestiny(value);
  }

  private setCallInfoDestinyTransfer = (value: string) => {
    this.s_call_info_destiny_transfer = value;
    this.main.context.setCallInfoDestinyTransfer(value);
  }

  private setCallInfoIsTransferAssisted = (value: boolean) => {
    this.s_call_info_is_transfer_assisted = value;
    this.main.context.setCallInfoIsTransferAssisted(value);
  }

  private setCallInfoIdResponse = (value: string) => {
    this.s_call_info_id_response = value;
    this.main.context.setCallInfoIdResponse(value);
  }
  
  private setInfoEvent = (type: string, description: string) => {
    this.s_events_type = type;
    this.s_events_description = description;
    this.main.context.setEventType(type);
    this.main.context.setEventDescription(description);
  }
  
  private setTicketInfo = (value: Ticket) => {
    this.s_ticket_info = value;
    this.main.context.setTicketInfo(value);
  }


  /* ======= Resets ======= */
  private resetStates = (state: 'session' | 'events' | 'call_status' | 'call_info' | 'ticket' | 'all') => {
    if (state === 'session' || state === 'all') {
      this.s_session_status = 'disconnected';
      this.s_session_callcenter_id = undefined;
      this.s_session_credentials = { username: undefined, password: undefined, display_name: undefined };
      this.s_session_transport_error = false;

      this.main.context.setSessionStatus('disconnected');
      this.main.context.setSessionCallcenterId(undefined);
      this.main.context.setSessionCredentials(this.s_session_credentials);
    }
  
    if (state === 'events' || state === 'all') {
      this.s_events_type = undefined;
      this.s_events_description = undefined;

      this.main.context.setEventType(undefined);
      this.main.context.setEventDescription(undefined);
    }
  
    if (state === 'call_status' || state === 'all') {
      this.s_call_status_event = undefined;
      this.s_call_status_direction = undefined;
      this.s_call_status_incoming_number = undefined;
      this.s_call_status_incoming_name = undefined;
      this.s_call_status_is_muted = false;
      this.s_call_status_is_hold = false;
      this.s_call_status_is_active = false;
      this.s_call_status_duration = undefined;
      this.s_call_status_start_date = undefined;

      this.main.context.setCallStatusEvent(undefined);
      this.main.context.setCallStatusDirection(undefined);
      this.main.context.setCallStatusIncomingNumber(undefined);
      this.main.context.setCallStatusIncomingName(undefined);
      this.main.context.setCallStatusIsMuted(false);
      this.main.context.setCallStatusIsHold(false);
      this.main.context.setCallStatusIsActive(false);
      this.main.context.setCallStatusDuration(undefined);
      this.main.context.setCallStatusStartDate(undefined);
    }

    if (state === 'call_info' || state === 'all') {
      this.s_call_info_display = '';
      this.s_call_info_destiny = undefined;
      this.s_call_info_destiny_transfer = undefined;
      this.s_call_info_id_response = undefined;
      this.s_call_info_is_transfer_assisted = false;

      this.main.context.setCallInfoDisplay('');
      this.main.context.setCallInfoDestiny(undefined);
      this.main.context.setCallInfoDestinyTransfer(undefined);
      this.main.context.setCallInfoIdResponse(undefined);
      this.main.context.setCallInfoIsTransferAssisted(false);
    }

    if (state === 'ticket' || state === 'all') {
      this.s_ticket_info = undefined;

      this.main.context.setTicketInfo(undefined);
    }
  }

  /* ======= Loads ======= */
  private loadAudios = () => {
    this.audioRemote = new Audio();
    this.audioDtmfTone = new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf.wav');
    this.audioIncomingRingtone = new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/phone_ringing2.mp3');
    this.audioOutgoingRingbacktone = new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/ringbacktone.wav');
    this.audioDtmfTones = {
      '0': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-0.wav'),
      '1': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-1.wav'),
      '2': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-2.wav'),
      '3': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-3.wav'),
      '4': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-4.wav'),
      '5': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-5.wav'),
      '6': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-6.wav'),
      '7': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-7.wav'),
      '8': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-8.wav'),
      '9': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-9.wav'),
      '#': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-pound.wav'),
      '*': new Audio('https://storage.googleapis.com/vozxpress-assets/builds/audios/dtmf-star.wav')
    }
  }

  private existsSIPml = (): boolean => {
    const uri = 'https://storage.googleapis.com/vozxpress-assets/builds/js/SIPml-api.js';
    const scripts = document.getElementsByTagName('script') as HTMLCollectionOf<HTMLScriptElement>;
    for (let i = 0; i < scripts.length; i++) {
      const element = scripts[i];
      if (element.src === uri) {
        return true;
      }
    }
    return false;
  }

  private loadSIPml = (): void => {
    this.main.context.setScriptLoading(true);
    const uri = 'https://storage.googleapis.com/vozxpress-assets/builds/js/SIPml-api.js';
    const sipml = document.createElement('script');
    sipml.src = uri;
    document.head.appendChild(sipml);
    sipml.onload = () => {
      this.script_loaded = true;
      this.main.context.setScriptLoading(false);
      this.main.context.setScriptLoaded(true);
      this.initEngine(true);
    };
    sipml.onerror = () => {
      this.main.context.setScriptLoading(false);
      this.main.context.setScriptLoaded(false);
    }
  }
}

type MainType = {
  dispatch: Dispatch<any>;
  socketInitializedRef: MutableRefObject<boolean>;
  registerSocketAppEvent: (event: SocketReceiveAppEvents, listener: (socket_data?: any) => void) => void;
  unregisterSocketAppEvent: (event: SocketReceiveAppEvents, listener?: (socket_data: any) => void) => void;
  context: CallcenterContextType;
}