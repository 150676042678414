import { useEffect, useState } from "react"
import ServiceModalNew from "."
import UserService from "../../../../../../services/user-service"
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import InputTimeControllerNew from "../inputTimeNew/indexController"
import { setShowAlertFeedback } from "../../../../../../store/internal"

interface Schedule {
    start_time: string;
    end_time: string;
}

interface BodyPostItem {
    schedules: Schedule;
    id: string
}

interface ServiceModalNewControllerProps {
    show: boolean;
    onHide: VoidFunction;
    setIndex: VoidFunction;
    index: number;
    dayOfWeek: string;
    t: (key: string) => string;
    getGetStateCheckboxDay: (day: string) => boolean,
    getScheduleService: Function
}

const ServiceModalNewController = ({
    show,
    onHide,
    setIndex,
    index,
    dayOfWeek,
    t,
    getGetStateCheckboxDay,
    getScheduleService
}: ServiceModalNewControllerProps) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const JsonSend = {
        day: "",
        schedules: [] as Schedule[],
        is_enabled: false
    }

    const [isLoading, setIsLoading] = useState(false)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [inputId, setInputId] = useState("")
    const [items, setItems] = useState<JSX.Element[]>([])
    const [bodyPost, setBodyPost] = useState<BodyPostItem[]>([])
    const [bodyGet, setBodyGet] = useState<Schedule[]>([])
    const [error, setError] = useState(false)
    const [blockButtonSave, setBlockButtonSave] = useState(false)
    const [blockButtonAdd, setBlockButtonAdd] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const AppRequesterConst = new AppRequesterController()

    const isValidTime = (time: string): boolean => {
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Horas de 00:00 a 23:59
        return timeRegex.test(time)
    }

    const isEndTimeAfterStartTime = (startTime: string, endTime: string): boolean => {
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;

        return endTotalMinutes > startTotalMinutes;
    };

    const isMinimumInterval = (startTime: string, endTime: string, minMinutes: number = 15): boolean => {
        const start = timeToMinutes(startTime);
        const end = timeToMinutes(endTime)
        return end - start >= minMinutes
    }

    const timeToMinutes = (time: string): number => {
        if (typeof time !== "string" || !time.includes(":")) {
            console.error("Formato de horário inválido:", time);
            return NaN;
        }

        const [hours, minutes] = time.split(":").map(Number);
        if (isNaN(hours) || isNaN(minutes)) {
            console.error("Horário inválido:", time);
            return NaN
        }

        return hours * 60 + minutes
    }

    const isOverlapping = (interval1: Schedule, interval2: Schedule): boolean => {
        const start1 = timeToMinutes(interval1.start_time)
        const end1 = timeToMinutes(interval1.end_time)
        const start2 = timeToMinutes(interval2.start_time)
        const end2 = timeToMinutes(interval2.end_time)

        return start1 < end2 && start2 < end1
    }

    // Função para verificar se dois intervalos são iguais
    const isEqual = (interval1: Schedule, interval2: Schedule): boolean => {
        return (
            interval1.start_time === interval2.start_time &&
            interval1.end_time === interval2.end_time
        )
    }

    // const hasOverlapOrEqual = (bodyGet: Schedule[], bodyPost: Schedule[]): boolean => {
    //     for(const postInterval of bodyPost) {
    //         for (const getInterval of bodyGet) {
    //             if (isOverlapping(postInterval, getInterval) || isEqual(postInterval, getInterval)) {
    //                 return true //Há sobreposição ou igualdade
    //             }
    //         }
    //     }
    //     return false; //Não há sobreposição ou igualdade
    // }

    const uuidv4 = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
    }

    // Função para validar e adicionar um novo horário
    const validateAndAddSchedule = (newSchedule: Schedule) => {
        // Verifica se o formato do horário é válido
        if (!isValidTime(newSchedule.start_time) || !isValidTime(newSchedule.end_time)) {
            setError(true)
            setBlockButtonSave(true)
            setBlockButtonAdd(true)
            setErrorMessage(t('home_departments.schedule_service_tab.invalid_time_format'));
            return;
        } 
        // Verifica se o horário de fim é após o horário de início
        if (!isEndTimeAfterStartTime(newSchedule.start_time, newSchedule.end_time)) {
            setError(true)
            setBlockButtonSave(true)
            setBlockButtonAdd(true)
            setErrorMessage(t('home_departments.schedule_service_tab.the_end_time_must_be_after_the_start_time'));
            return;
        }
        // Verifica se o intervalo mínimo entre horários é respeitado (15minutos)
        if (!isMinimumInterval(newSchedule.start_time, newSchedule.end_time)) {
            setError(true)
            setBlockButtonSave(true)
            setBlockButtonAdd(true)
            setErrorMessage(t('home_departments.schedule_service_tab.the_minimum_interval_between_time_is_15_minutes'));
            return;
        }

        if (bodyPost.some((item) => isOverlapping(item.schedules, newSchedule) || isEqual(newSchedule, item.schedules))) {
            setError(true)
            setBlockButtonSave(true)
            setBlockButtonAdd(true)
            setErrorMessage(t('home_departments.schedule_service_tab.this_time_overlaps_another_time_alredy_registered'))
            return;
        }

        if (bodyGet.some((item) => isOverlapping(newSchedule, item) || isEqual(newSchedule, item))) {
            setError(true)
            setBlockButtonSave(true)
            setBlockButtonAdd(true)
            setErrorMessage(t('home_departments.schedule_service_tab.this_time_alredy_exists_in_the_database'))
            return;
        }

        setBodyPost((prev) => [...prev, { schedules: newSchedule, id: inputId }]);
        setBlockButtonSave(false)
        setBlockButtonAdd(false)
        setError(false)
        setErrorMessage("")
    }

    // Adiciona horários ao bodyPost
    useEffect(() => {
        if (startTime && endTime) {
            validateAndAddSchedule({ start_time: startTime, end_time: endTime })
        }
    }, [startTime, endTime])

    // Carrega horários do banco de dados
    useEffect(() => {
        const controller = new AbortController();
        if (show) {
            getSchedulesDay(controller, dayOfWeek);
        }

        return () => controller.abort()

    }, [dayOfWeek, show])

    // Impede o cadastro de mais de 5 horários de funcionamento
    useEffect(() => {
        if(items.length >= 5) {
            setBlockButtonAdd(true)
            setError(true)
            setErrorMessage(t('home_departments.schedule_service_tab.you_can_register_a_maximum_of_5_times'))
        } else {
            setBlockButtonAdd(false)
            setError(false)
            setErrorMessage("")
        }
    }, [items])

    const getSchedulesDay = async (controller: AbortController, dayOfWeek: string) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal;

        const schedules: JSX.Element[] = [];
        const schedulesHour: Schedule[] = [];

        await AppRequesterConst.Get(
            `/company/day-opening-hour/${dayOfWeek}`,
            { headers, signal },
            (response: Object) => {},
            (data: any) => {
                if (data.status === 200 && data.data.data.schedules.length > 0) {
                    data.data.data.schedules.forEach((item: string, idx: number) => {
                        const hours: Schedule = JSON.parse(item);
                        const uuid = uuidv4()

                        let schedule = { start_time: hours.start_time, end_time: hours.end_time, id: uuid }

                        schedulesHour.push(schedule);
                        schedules.push(
                            <InputTimeControllerNew
                                t={t}
                                index={idx}
                                setStartTime={setStartTime}
                                setEndTime={setEndTime}
                                start_time={hours.start_time}
                                end_time={hours.end_time}
                                deleteItem={deleteItem}
                                idNewItem={uuid}
                                key={uuidv4()}
                                setInputId={setInputId}
                            />
                        )
                    })
                    setItems(schedules)
                    setBodyGet(schedulesHour)
                } else {
                    setItems([...items, <InputTimeControllerNew
                        t={t}
                        index={index}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        deleteItem={deleteItem}
                        idNewItem={uuidv4()}
                        key={uuidv4()}
                        setInputId={setInputId}
                    />])
                }
            },
            (error: any) => {
                // setErrorMessage("Erro ao carregar horários. Tente novamente mais tarde.");
            }, navigate, dispatch, setIsLoading, {  }
        )
    }

    const deleteItem = (event) => {
        const id = event.currentTarget.id;
        const uuid = id.split("*ID*")[1].split('_');
        setItems((prevItems) => prevItems?.filter((item) => item.props.idNewItem != uuid[2].toString()));
        setBodyGet((prevItems) => prevItems?.filter((item: any) => item.id !== uuid[2].toString()));
        setBodyPost((prevItems) => prevItems?.filter((item: any) => item.id !== uuid[2].toString()));
        setErrorMessage("")
        setBlockButtonSave(false)
        setBlockButtonAdd(false)
    }

    const postSchedulesDay = async (controller: AbortController) => {
        const headers = UserService.getHeaders()

        const bodySchedulesPost = bodyPost.map((item) => ({
            start_time: item.schedules.start_time,
            end_time: item.schedules.end_time
        }))

        const bodySchedulesGet = bodyGet.map((item) => ({
            start_time: item.start_time,
            end_time: item.end_time
        }))

        let newArray = [...bodySchedulesGet, ...bodySchedulesPost]

        JsonSend.day = dayOfWeek;
        JsonSend.schedules = newArray;
        JsonSend.is_enabled = getGetStateCheckboxDay(dayOfWeek);


        await AppRequesterConst.Post(
            `/company/day-opening-hour`, JsonSend, { headers },
            () => {},
            (data: any) => {
                getScheduleService(controller)
                resetState();
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved')}))
            },
            (error: any) => {
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const resetState = () => {
        setBodyGet([]);
        setBodyPost([]);
        setItems([]);
        setStartTime("");
        setEndTime("");
        setInputId("")
        setError(false)
        setBlockButtonSave(false);
        setBlockButtonAdd(false)
        JsonSend.day = "";
        JsonSend.schedules = [];
        JsonSend.is_enabled = false;
    }

    const closeAndSave = () => {
        const controller = new AbortController()
        postSchedulesDay(controller);
        onHide();
        resetState();
    }

    const cancelAndClose = () => {
        resetState();
        onHide()
    }

    /** Exibe o dia da semana no modal */
    const daysOfWeek = (dayOfWeek) => {
        let day = 'N/A'

        switch(dayOfWeek) {
            case 'sunday':
                day = `${t("home_departments.schedule_service_tab.days.sun")}`;
                break;
            case 'monday':
                day = `${t("home_departments.schedule_service_tab.days.mon")}`;
                break;
            case 'tuesday':
                day = `${t("home_departments.schedule_service_tab.days.tue")}`;
                break;
            case 'wednesday':
                day = `${t("home_departments.schedule_service_tab.days.wed")}`;
                break;
            case 'thursday':
                day = `${t("home_departments.schedule_service_tab.days.thu")}`;
                break;
            case 'friday':
                day = `${t("home_departments.schedule_service_tab.days.fri")}`;
                break;
            case 'saturday':
                day = `${t("home_departments.schedule_service_tab.days.sat")}`;
                break;
            default:
                break;
        }

        return day
    }
    
    return (
        <ServiceModalNew
            show={show}
            items={items}
            addItems={() => setItems([...items, <InputTimeControllerNew
                            t={t}
                            index={index}
                            setStartTime={setStartTime}
                            setEndTime={setEndTime}
                            deleteItem={deleteItem}
                            idNewItem={uuidv4()}
                            key={uuidv4()}
                            setInputId={setInputId}
            />])}
            dayOfWeek={dayOfWeek}
            daysOfWeek={daysOfWeek}
            cancelAndClose={cancelAndClose}
            t={t}
            blockButtonSave={blockButtonSave}
            blockButtonAdd={blockButtonAdd}
            closeAndSave={closeAndSave}
            error={error}
            errorMessage={errorMessage}
        />
    )
}

export default ServiceModalNewController