import './styles.scss';
import { FC } from 'react';
import { LanguageSelectorInterface } from './indexModel';
import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { CircleFlag } from 'react-circle-flags';
import { IconCheck, IconX } from '@tabler/icons-react';
import Loading from '../../../../components/loading';

const ModalLanguageSelector: FC<LanguageSelectorInterface> = (props) => {
  return (
    <Modal show={props.showModal} onHide={() => props.closeModal(false)} size="lg" className="language-selector-component" centered>
      <Modal.Header>
        <Modal.Title>{props.t("title")}</Modal.Title>
        <div className="language-selector-component-header-actions">
          <IconX onClick={() => props.closeModal(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='language-selector-set-language-form'>
          {props.isLoading && <Loading visibility={true} noBootstrap={true} customStyle={{ position: 'absolute' }} />}
          <div className="language-list">
            {props.getAvailableLanguages().map(item => {
              let itemClass = 'language-item';

              if (props.isSelectedLanguage(props.language, item?.language)) {
                itemClass += ' active';
              }

              return (
                <div key={item?.language} className={itemClass} onClick={() => props.setLanguage(item?.language)}>
                  <CircleFlag countryCode={item?.flag} title=' ' />
                  <span className='language-name'>{item?.title}</span>
                  { props.isSelectedLanguage(props.language, item?.language) && <IconCheck className="icon-check" /> }
                </div>
              );
            })}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-start language-selector-max-width">
          <span className="language-selector-message-alert">{props.t("message_alert")}</span>
        </div>
        <button disabled={props.isLoading} className="language-selector-btn-cancel" onClick={() => props.closeModal(false)} >{props.t("btn_cancel")}</button>
        <button disabled={props.isLoading} type='submit' className="language-selector-btn-save" onClick={() => props.onSubmit()}>{props.t("btn_send")}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalLanguageSelector;
