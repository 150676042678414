import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Modal, Form, Row, Col, InputGroup, FormControl, FormLabel, Button } from "react-bootstrap"
import styles from '../styles'
import SelectComponent from "../../selectComponent"
import { Brokers } from "../../../../../core/enums/brokers"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController"
import UserService from "../../../../../services/user-service"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { getToken } from "../../../../../store/token"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import Loading from "../../../../../components/loading"

const AppRequesteConst = new AppRequesterController()

const ModalEditWhatsapp = ({
    show,
    onHide,
    nameAccount,
    phoneNumber,
    tokenAuth,
    sid,
    botNlp,
    apiUrl,
    urlBot,
    postBackUrl,
    senderSid,
    setNameAccount,
    setPhoneNumber,
    setTokenAuth,
    setSid,
    setBotNlp,
    setApiUrl,
    setUrlBot,
    setPostBackUrl,
    setSenderSid,
    hasBot,
    setShowFeedbackNameAccount,
    setShowFeedbackPhoneNumber,
    setShowFeedbackTokenAuth,
    setShowFeedbackSid,
    setShowFeedbackBotNlp,
    setShowFeedbackUrlBot,
    setShowFeedbackPostBackUrl,
    setShowFeedbackSenderSid,
    values,
    id,
    dispatch,
    navigate,
    getWhatsAppById,
    setIsLoading,
    showFeedbackNameAccount,
    showFeedbackPhoneNumber,
    showFeedbackTokenAuth,
    showFeedbackSid,
    showFeedbackApiUrl,
    showFeedbackBotNlp,
    showFeedbackUrlBot,
    showFeedbackPostBackUrl,
    showFeedbackSenderSid,
    optionsBroker,
    setOptionsBroker,
    selectedBroker,
    setSelectedBrokers,
    selectBrokerId,
    onSelectBroker,
    validated,
    setHasbot,
    isLoading,

}) => {

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'left', font: 'normal normal bold calc(0.2em + 1vw) Montserrat', letterSpacing: '0px', color: 'black', opacity: '1', display: 'flex', alignItems: 'center' }}>Editar número</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        nameAccount: nameAccount,
                        phoneNumber: phoneNumber,
                        tokenAuth: tokenAuth,
                        sid: sid,
                        botNlp: botNlp,
                        apiUrl: apiUrl,
                        urlBot: urlBot,
                        postBackUrl: postBackUrl,
                        senderSid: senderSid
                    }}
                    validate={(values) => {
                        let errors = {};

                        setNameAccount(values.nameAccount)
                        setPhoneNumber(values.phoneNumber)
                        setTokenAuth(values.tokenAuth)
                        setSid(values.sid)
                        setBotNlp(values.botNlp)
                        setApiUrl(values.apiUrl)
                        setUrlBot(values.urlBot)
                        setPostBackUrl(values.postBackUrl)
                        setSenderSid(values.senderSid)

                        if (!values.nameAccount) {
                            errors['nameAccount'] = 'Preencha o display name corretamente'
                            setShowFeedbackNameAccount(true)
                        } else {
                            setShowFeedbackNameAccount(false)
                        }

                        if (!values.phoneNumber) {
                            errors['phoneNumber'] = 'Preencha o número corretamente'
                            setShowFeedbackPhoneNumber(true)
                        } else {
                            setShowFeedbackPhoneNumber(false)
                        }

                        if (!values.tokenAuth) {
                            errors['tokenAuth'] = 'Preencha o token de autorização corretamente'
                            setShowFeedbackTokenAuth(true)
                        } else {
                            setShowFeedbackTokenAuth(false)
                        }

                        if (!values.sid) {
                            errors['sid'] = 'Preencha o sid corretamente'
                            setShowFeedbackSid(true)
                        } else {
                            setShowFeedbackSid(false)
                        }

                        

                        // if (!values.apiUrl) {
                        //     errors['apiUrl'] = 'Preencha o URL da API corretamente'
                        //     setShowFeedbackApiUrl(true)
                        // } else {
                        //     setShowFeedbackApiUrl(false)
                        // }

                        if (hasBot) {

                            if (values.botNlp && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(values.botNlp)) {
                                errors['botNlp'] = 'Preencha com um ID válido'
                                setShowFeedbackBotNlp(true)
                            } else {
                                setShowFeedbackBotNlp(false)
                            }

                            if (!values.urlBot) {
                                errors['urlBot'] = 'Preencha o URL do Bot corretamente'
                                setShowFeedbackUrlBot(true)
                            } else {
                                setShowFeedbackUrlBot(false)
                            }

                            if (!values.postBackUrl) {
                                errors['postBackUrl'] = 'Preencha o PostBackUrl corretamente'
                                setShowFeedbackPostBackUrl(true)
                            } else {
                                setShowFeedbackPostBackUrl(false)
                            }
                        } else {

                        }

                        if (!values.senderSid && selectedBroker[0]?.value === Brokers.Twilio) { /* só adiciona o erro caso o broker seja twilio E o campo esteja vazio*/
                            errors['senderSid'] = 'Preencha o sender sid corretamente'
                            setShowFeedbackSenderSid(true)
                        }
                        else {
                            setShowFeedbackSenderSid(false)
                        }

                        return errors
                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            hasBot: hasBot
                        }

                        if (nameAccount !== '') {
                            JsonSend["subAccountName"] = nameAccount
                        }

                        if (phoneNumber !== '') {
                            JsonSend["phoneNumber"] = phoneNumber.toString()
                        }

                        if (tokenAuth !== '') {
                            JsonSend['authToken'] = tokenAuth
                        }
                        
                        if (sid !== '') {
                            JsonSend['sid'] = sid
                        }

                        if (apiUrl !== '') {
                            JsonSend['apiUrl'] = apiUrl
                        }

                        if (onSelectBroker[0]?.value !== undefined) {
                            JsonSend['brokerId'] = onSelectBroker[0].value
                        } 
                        else if (selectedBroker[0]?.value !== undefined) {
                            JsonSend['brokerId'] = selectedBroker[0].value
                        }

                        if(selectedBroker[0]?.value === Brokers.Twilio) {
                            JsonSend['sender_sid'] = senderSid;
                        }
                        else {
                            JsonSend['sender_sid'] = null;
                        }

                        if (hasBot) {
                            if (botNlp !== '') {
                                JsonSend['nlp_id'] = botNlp ? botNlp : undefined
                            }

                            if (urlBot !== '') {
                                JsonSend['urlBot'] = urlBot ? urlBot : undefined
                            }

                            if (postBackUrl !== '') {
                                JsonSend['postBackUrl'] = postBackUrl ? postBackUrl : undefined
                            }
                        }

                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + values.token.value
                        };

                        await AppRequesteConst.Put(
                            `/channel-whatsapp/update-whatsapp-keys/${id}`, JsonSend, { headers },
                            (response: Object) => {

                            },
                            (data) => {
                                dispatch(setShowAlertFeedback({ message: "Alterações salvas com sucesso.", visibility: true, signalIcon: true }))
                                onHide()
                                getWhatsAppById()
                            },
                            (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
                                if (error.response.status === 400) {
                                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                                }
                                else if (error?.response?.status === 422) {
                                    if (error?.response?.data?.message[0]?.path[0] === 'nlp_id') {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp é inválido.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.message[0]?.path[0] === 'brokerId') {
                                        dispatch(setShowAlertFeedback({ message: 'O Broker não foi informado.', visibility: true, signalIcon: false }))
                                    }
                                    else if (error?.response?.data?.code_cxpress === 216) {
                                        dispatch(setShowAlertFeedback({ message: 'Bot não encontrado', visibility: true, signalIcon: false }))
                                    }
                                    else if (error?.response?.data?.code_cxpress === 217) {
                                        dispatch(setShowAlertFeedback({ message: 'Bot está cadastrado em uma empresa diferente', visibility: true, signalIcon: false }))
                                    }
                                    else if (error?.response?.data?.code_cxpress === 218) {
                                        dispatch(setShowAlertFeedback({ message: 'Canal do bot não é whatsapp', visibility: true, signalIcon: false }))
                                    }
                                    else if (error?.response?.data?.code_cxpress === 219) {
                                        dispatch(setShowAlertFeedback({ message: 'Prompt não foi criado', visibility: true, signalIcon: false }))
                                    }
                                    else if (error?.response?.data?.code_cxpress === 220) {
                                        dispatch(setShowAlertFeedback({ message: 'Bot está associado a outra whatsapp key', visibility: true, signalIcon: false }))
                                    }
                                }
                                else {
                                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde", visibility: true, singnalIcon: false }))
                                }
                            }, navigate, dispatch, setIsLoading
                        )

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Loading visibility={isLoading} customStyle={{ zIndex: '900', top: '0', left: '0' }}></Loading>
                                <Col>
                                    <Form.Label htmlFor="name">
                                        Display name <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="nameAccount"
                                            isInvalid={showFeedbackNameAccount}
                                            className="form-control-Default"
                                            placeholder="Informe o display name"
                                            aria-label="Informe o display name"
                                            aria-describedby="Informe o display name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nameAccount}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackSubAccountName">
                                            {errors.nameAccount}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label htmlFor="number">
                                        Número <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            id="phoneNumber"
                                            isInvalid={showFeedbackPhoneNumber}
                                            className="form-control-Default"
                                            placeholder="Informe o número"
                                            aria-label="Informe o número"
                                            aria-describedby="Informe o número"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackPhoneNumber">
                                            {errors.phoneNumber}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Form.Label htmlFor="token">
                                    Token de autorização <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        id="tokenAuth"
                                        isInvalid={showFeedbackTokenAuth}
                                        className="form-control-Default"
                                        placeholder="Informe o token de autorização"
                                        aria-label="Informe o token de autorização"
                                        aria-describedby="Informe o token de autorização"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.tokenAuth}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackTokenAuth">
                                        {errors.tokenAuth}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel htmlFor="sid">
                                    Sid <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="sid"
                                        isInvalid={showFeedbackSid}
                                        className="form-control-Default"
                                        placeholder="Informe o sid"
                                        aria-label="Informe o sid"
                                        aria-describedby="Informe o sid"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sid}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackSid">
                                        {errors.sid}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <FormLabel>
                                    URL da API
                                </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        id="apiUrl"
                                        isInvalid={showFeedbackApiUrl}
                                        className="form-control-Default"
                                        placeholder="Informe o URL da API"
                                        aria-describedby="Informe o URL da API"
                                        aria-label="Informe o URL da API"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.apiUrl}
                                    >
                                    </FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackApiUrl">
                                        {errors.apiUrl}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Row>
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left">
                                <FormLabel>
                                    Broker <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                </FormLabel>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={optionsBroker}
                                    disableSearch={true}
                                    setOptions={setOptionsBroker}
                                    selecteds={selectedBroker}
                                    setSelecteds={setSelectedBrokers}
                                    singleSelect={true}
                                    title={"Broke"}
                                    overrideStrings={{
                                        "allItemsAreSelected": selectedBroker[0] ? selectedBroker[0]?.label && selectedBroker[0]?.value : "",
                                        "clearSearch": "Limpar pesquisa",
                                        "clearSelected": "Limpar broker selecionado",
                                        "noOptions": "Sem broker",
                                        "search": "Pesquisar broker",
                                        "selectAll": "Selecionar todos os brokers",
                                        "selectAllFiltered": "Selecionar todos os brokers (Filtrado)",
                                        "selectSomeItems": "Selecione o broker",
                                        "create": "Criar"
                                    }}
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    onSelect={(value) => selectBrokerId(value)}
                                    filtering={false}
                                    hasAllOption={false}
                                />
                            </Row>
                            {
                                selectedBroker[0]?.value === Brokers.Twilio ?
                                <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                    <FormLabel htmlFor="sid">
                                        Sender Sid <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                    </FormLabel>
                                    <InputGroup>
                                        <FormControl
                                            id="senderSid"
                                            isInvalid={showFeedbackSenderSid}
                                            className="form-control-Default"
                                            placeholder="Informe o sender sid"
                                            aria-label="Informe o sender sid"
                                            aria-describedby="Informe o sender sid"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={senderSid}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackSenderSid">
                                            {errors.senderSid}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Row>
                                :
                                <></>
                            }
                            <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                <Col>
                                    <Form.Label htmlFor="basic-url">Possui bot? &nbsp; &nbsp;</Form.Label>
                                    <Form.Check name="hasBot" label={'Não'} type={'radio'} checked={!hasBot} onChange={() => {setHasbot(false)}} inline/>
                                    <Form.Check name="hasBot" label={'Sim'} type={'radio'} checked={hasBot} onChange={() => {setHasbot(true)}} inline/>
                                </Col>
                            </Row>
                            {hasBot ?
                                    <>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <FormLabel>
                                                ID do Bot NLP
                                            </FormLabel>
                                            <InputGroup>
                                                <FormControl
                                                    id="botNlp"
                                                    isInvalid={showFeedbackBotNlp}
                                                    className="form-control-Default"
                                                    placeholder="Informe o ID do Bot NLP"
                                                    aria-label="Informe o ID do Bot NLP"
                                                    aria-describedby="Informe o ID do Bot NLP"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.botNlp}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackBotNlp">
                                                    {errors.botNlp}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <FormLabel>
                                                URL Bot <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                            </FormLabel>
                                            <InputGroup>
                                                <FormControl
                                                    id="urlBot"
                                                    isInvalid={showFeedbackUrlBot}
                                                    className="form-control-Default"
                                                    placeholder="URL Bot"
                                                    aria-label="URL Bot"
                                                    aria-describedby="URL Bot"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.urlBot}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackUrlBot">
                                                    {errors.urlBot}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                        <Row style={styles.textFontGlay} className="d-flex justify-content-left mb-2">
                                            <Form.Label>
                                                PostBackUrl <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black'}}>*</span>
                                            </Form.Label>
                                            <InputGroup>
                                                <FormControl
                                                    id="postBackUrl"
                                                    isInvalid={showFeedbackPostBackUrl}
                                                    className="form-control-Default"
                                                    placeholder="PostBackUrl"
                                                    aria-label="PostBackUrl"
                                                    aria-describedby="PostBackUrl"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.postBackUrl}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackPostBackUrl">
                                                    {errors.postBackUrl}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Row>
                                    </> 
                                : 
                                    <></>
                            }
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => onHide()}>Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size="lg" className="buttonBlue" style={{ width: '56%' }} type="submit">Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEditWhatsapp