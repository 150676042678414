import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    setShowConfirmationModal,
    setShowAlertFeedback
} from '../../../../../store/internal'

import { getToken } from '../../../../../store/token'

import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { TokenInterfaceControlPanel } from '../../../../../services/requestsInterfacesModel';
import UserService from '../../../../../services/user-service';
import ListConfigWebchat from '.';

const AppRequesterConst = new AppRequesterController();

const ListConfigWebchatController = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [webchatId, setWebchatId] = useState('');
    const [webchatName, setWebchatName] = useState('');

    const funtionConfirmation = async (id: string) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Delete(
            `/channel-webchat/webchat-keys/${id}`, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: "Webchat excluído com sucesso.", visibility: true, signalIcon: true }))
                props.getWebchatById()
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading,
        )
    }

    const deletePage = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir webchat', "body": `Tem certeza que deseja excluir o webchat #####? Essa operação não poderá ser desfeita.`, "buttonReturnText": "Cancelar", "replaceText": webchatName }, functionConfirmation: () => funtionConfirmation(webchatId) }));
    }

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const getAccountById = async (id) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };

        await AppRequesterConst.Get(
            `/channel-facebook/facebook-key/${id}`, { headers },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setData(data.data.facebook_key)
                    setShowModalEdit(true);
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ visibility: true, message: "Ocorreu um erro inesperado, tente novamente", signalIcon: false }));
                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {}
        )
    }

    const openPopover = (id, webchatName) => {
        setWebchatId(id)
        setWebchatName(webchatName)
    }

    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={() => deletePage()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>Excluir webchat</Button>
            </Popover.Body>
        </Popover>
    );
    return <ListConfigWebchat
        props={props}
        isLoading={isLoading}
        popover={popover}
        getAccountById={getAccountById}
        data={data}
        showModalEdit={showModalEdit}
        handleCloseModalEdit={handleCloseModalEdit}
        openPopover={openPopover}
        infos={props?.datas}
        getFacebookById={props.getFacebookById}
        isEditAllowed={props.isEditAllowed}
    />

}

export default ListConfigWebchatController;