import { FC, useState } from 'react';
import ExpiredTokenOpenAiBar from './index';
import { ExpiredTokenOpenAiBarControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';

const ExpiredTokenOpenAiBarController: FC<ExpiredTokenOpenAiBarControllerInterface> = (props) => {
  const { t } = useTranslation('ExpiredTokenOpenAiBar');
  const [showModalExpired, setShowModalExpired] = useState(false);

  return (
    <ExpiredTokenOpenAiBar
      t={t}
      setShowModalExpired={setShowModalExpired}
      showModalExpired={showModalExpired}
    />
  );
};

export default ExpiredTokenOpenAiBarController;
