import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18next';
import { AppRequesterController } from '../../services/appRequester/appRequesterController';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';
import { ptBR } from 'date-fns/locale/pt-BR';
import HomeValidateEmail from '.';

registerLocale('es', es);
registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('pt-BR', ptBR);
registerLocale('pt-PT', ptBR);

const AppRequesterConst = new AppRequesterController();

const HomeValidateEmailController = () => {
    const [validation, setValidation] = useState(false);
    const [country, setCountry] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const CheckToken = async (code: string) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + code,
        };

        await AppRequesterConst.Get(
            '/authentication/confirm-email/', { headers },
            (response: Object) => { },
            (data: Object) => {
                setValidation(true);
            },
            (error: { response: { status: number; }; }) => {
                setValidation(false);
                if (error.response !== undefined) {
                    // 200 confirmado, 204 não encontrado
                    if (error.response?.status === 400) {

                    }
                }
            }, navigate, dispatch, setIsLoading, {}
        );
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        const country = urlParams.get('country');

        setIsLoading(true);
        setCountry(country);

        if (code !== undefined) {
            CheckToken(code);
        } else {
            setValidation(false);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (country === 'BR') {
            i18n.changeLanguage('pt-BR');
            localStorage.setItem("i18nextLng", 'pt-BR');
        } else if (country === 'PT') {
            i18n.changeLanguage('pt-PT');
            localStorage.setItem("i18nextLng", 'pt-PT');
        } else if (country === 'GB') {
            i18n.changeLanguage('en');
            localStorage.setItem("i18nextLng", 'en');
        }
        setDefaultLocale(
            country === 'BR' ? 'pt-BR' :
                country === 'PT' ? 'pt-PT' :
                    country === 'GB' ? 'en' : country?.toLocaleLowerCase()
        );
    }, [country]);

    return <HomeValidateEmail
        validation={validation}
        isLoading={isLoading}
        t={t}
    />
};

export default HomeValidateEmailController;