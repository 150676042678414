import './styles.scss'
import { IconX } from '@tabler/icons-react'
import { IconPlus } from '@tabler/icons-react'
import { Button } from 'react-bootstrap'

const ServiceModalNew = ({
    show,
    items,
    addItems,
    dayOfWeek,
    daysOfWeek,
    t,
    blockButtonSave,
    blockButtonAdd,
    closeAndSave,
    error,
    cancelAndClose,
    errorMessage
}) => {
    return(
        <div className={show ? 'container-modal' : 'none'}>
            <div className='container-body-modal'>
                <div className='container-header-modal'>
                    <div className='container-header-title'>
                        {t("home_departments.schedule_service_tab.opening_hours")}
                        <div className='container-header-subtitle'>{t("home_departments.schedule_service_tab.select_schedule")}</div>
                    </div>
                    <div className='container-header-close-button' onClick={() => cancelAndClose()}>
                        <IconX stroke={2} />
                    </div>
                </div>
                <div className='container-day-of-week-row'>
                    <div className='container-day'>
                        {daysOfWeek(dayOfWeek)}
                    </div>
                </div>
                <div className='container-button-row'>
                    <div className='container-button-row-title'>
                        {t("home_departments.schedule_service_tab.hours")}
                    </div>
                    <div className='container-button-row-add'>
                        <Button style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='mx-3 nopadding buttonTicketsSec' onClick={() => addItems()} disabled={blockButtonAdd}>
                            <svg id="Grupo_11079" data-name="Grupo 11079"
                                xmlns="http://www.w3.org/2000/svg" width="50%"
                                viewBox="0 0 24 24">
                                <path id="Caminho_9572" data-name="Caminho 9572"
                                    d="M0,0H24V24H0Z" fill="none" />
                                <line id="Linha_147" data-name="Linha 147" y2="14"
                                    transform="translate(12 5)" fill="none"
                                    stroke="#0c2461" strokeLinecap="round"
                                    strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_148" data-name="Linha 148" x2="14"
                                    transform="translate(5 12)" fill="none"
                                    stroke="#0c2461" strokeLinecap="round"
                                    strokeLinejoin="round" strokeWidth="1.5" />
                            </svg>
                        </Button>
                    </div>
                </div>
                <div className='container-list-schedules' id="listOfSchedules">
                    {items}
                </div>
                <div className='container-wanings'>
                    {error ? 
                        <div className='container-warning'>
                            {errorMessage}
                        </div>
                        :
                        <div className='container-warning-none'>

                        </div>
                    }
                </div>
                <div className='container-row-buttons-modal'>
                    <div className='container-cancel'>
                        <Button size='lg' className='buttonWhite' style={{ width: '56%' }} onClick={() => cancelAndClose()}>{t("home_departments.schedule_service_tab.cancel")}</Button>
                    </div>
                    <div className='container-save'>
                        <Button size='lg' className='buttonBlue' disabled={blockButtonSave} style={{ width: '56%' }} onClick={() => closeAndSave()}>{t("home_departments.schedule_service_tab.save")}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceModalNew