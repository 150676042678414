import { createContext, Dispatch, MutableRefObject, SetStateAction, useContext, useState } from 'react';
import { SearchComponentFilterType } from '../../pages/homeApp/components-fixed/searchComponent/indexModel';
import { CustomFieldsType } from '../enums/custom-field-type';
import { CustomSelectOptionType } from '../../components/customSelect/indexModel';
import { SearchPagesType } from '../../pages/homeApp/components-fixed/sidebarAppComponent/indexModel';
import PQueue from 'p-queue';

export const MainAppContext = createContext<MainAppContextType>(null);

export const MainAppProvider = ({ children }) => {

  const eventsQueue = new PQueue({ concurrency: 1 });

  const [currentPage, setCurrentPage] = useState<string>(null);
  const [currentTab, setCurrentTab] = useState<string>(null);
  const [headerRef, setHeaderRef] = useState<MutableRefObject<HTMLDivElement>>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [clearSearchFunction, setClearSearchFunction] = useState<() => any>(null);
  const [clearFilterTermFunction, setClearFilterTermFunction] = useState<() => void>(null);
  const [filterApplied, setFilterApplied] = useState<SearchComponentFilterType>({});
  const [filteredMode, setFilteredMode] = useState<'simple' | 'advanced'>();
  const [isSearched, setIsSearched] = useState(false);
  const [isSearchedDashCounter, setIsSearchedDashCounter] = useState(false);

  const [actionEvent, setActionEvent] = useState<{ event: ActionEventType, data: any }>(null);

  const [header, setHeader] = useState<{
    headerTitle: string;
    headerSubtitle: string;
    hasSearchComponent: boolean;
    hasSearchInput: boolean;
    hasSearchFilter: boolean;
    searchPage: SearchPagesType;
    searchPlaceholder: string;
    searchFunction: (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => void;
    clearSearchFunction: () => any;
    hasCreateButton: boolean;
    createButtonDisabled: boolean;
    createButtonText: string;
    createButtonTooltip: string;
    createButtonAction: () => any;
    customBackgroundColor: string;
  }>();

  const performSearch = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    if (header.searchFunction) {
      header.searchFunction(query, filterApplied);
    } else {
      console.error('MainAppContext: Função de busca não definida');
    }
  }

  const performClearSearch = () => {
    if (header.clearSearchFunction) {
      header.clearSearchFunction();
    } else {
      console.error('MainAppContext: Função de limpeza de busca não definida');
    }
  }

  const setMainHeader = (data: SetMainSettings) => {
    setHeader({
      headerTitle: data.pageTitle,
      headerSubtitle: data.pageSubtitle,
      hasSearchComponent: data.hasSearchComponent,
      hasSearchInput: data.hasSearchInput,
      hasSearchFilter: data.hasSearchFilter,
      searchPage: data.searchPage,
      searchPlaceholder: data.searchPlaceholder,
      searchFunction: data.searchFunction,
      clearSearchFunction: data.clearSearchFunction,
      hasCreateButton: data.hasCreateButton,
      createButtonDisabled: data.createButtonDisabled,
      createButtonText: data.createButtonText,
      createButtonTooltip: data.createButtonTooltip,
      createButtonAction: data.createButtonAction,
      customBackgroundColor: data.customBackgroundColor,
    });
  }

  const resetMainHeader = () => {
    setHeader({
      headerTitle: '',
      headerSubtitle: '',
      hasSearchComponent: false,
      hasSearchInput: false,
      hasSearchFilter: false,
      searchPage: null,
      searchPlaceholder: '',
      searchFunction: () => {},
      clearSearchFunction: () => {},
      hasCreateButton: false,
      createButtonDisabled: false,
      createButtonText: '',
      createButtonTooltip: '',
      createButtonAction: () => {},
      customBackgroundColor: '',
    });
  }

  const formatFilter = (filter: SearchComponentFilterType, termSearch: string, mode?: 'simple' | 'advanced', page?: SearchPagesType): { [key: string]: string } => {
    const queryParams = {};

    if (mode === 'advanced') {
      let search = '@';

      if (Object.keys(filter.selectedCustomFieldListValuesObject || {}).length > 0) {
        const customFieldText = [CustomFieldsType.Text.toString(), CustomFieldsType.Textarea.toString()];

        filter.selectedCustomFieldListFilter?.forEach(item => {
          if (filter.selectedCustomFieldListValuesObject[item.id]) {
            const value = filter.selectedCustomFieldListValuesObject[item.id];
            const formattedValue = customFieldText.includes(item.custom_field_type_id) ? value : (value as CustomSelectOptionType[]).map(item => item.value).join(', ');
            search += `${item.name}: (${formattedValue}) `;
          }
        });
      }

      if (termSearch) {
        search += termSearch.trim();
      }

      if (search.length > 1) {
        queryParams['search'] = search.trim();
      }
      
    } else {
      if (termSearch) {
        queryParams['search'] = termSearch;
      }
      if (filter.selectedChannel?.length > 0) {
        queryParams['channel'] = filter.selectedChannel.map(item => item.id).join(',');
      }
      if (filter.selectedChatbot?.length > 0) {
        queryParams['bot_id'] = filter.selectedChatbot.map(item => item.id).join(',');
      }
      if (filter.selectedAutomation?.length > 0) {
        queryParams['automation'] = filter.selectedAutomation.map(item => item.id).join(',');
      }
      if (filter.selectedAgent?.length > 0) {
        if (page === 'tasks') {
          queryParams['requester_user_id'] = filter.selectedAgent.map(item => item.id).join(',');
        } else {
          queryParams['employee'] = filter.selectedAgent.map(item => item.id).join(',');
        }
      }
      if (filter.selectedAgentTaskAssigned?.length > 0) {
        queryParams['assigned_user_id'] = filter.selectedAgentTaskAssigned.map(item => item.id).join(',');
      }
      if (filter.selectedStatus?.length > 0) {
        if (page === 'tasks') {
          queryParams['status_id'] = filter.selectedStatus.map(item => item.id).join(',');
        } else {
          queryParams['status'] = filter.selectedStatus.map(item => item.id).join(',');
        }
      }
      if (filter.selectedSector?.length > 0) {
        queryParams['sector'] = filter.selectedSector.map(item => item.id).join(',');
      }
      if (filter.selectedTag?.length > 0) {
        if (page === 'tasks') {
          queryParams['tags_id'] = filter.selectedTag.map(item => item.id).join(',');
        } else {
          queryParams['tags'] = filter.selectedTag.map(item => item.id).join(',');
        }
      }
      if (filter.selectedTriggerType?.length > 0) {
        queryParams['type'] = filter.selectedTriggerType.map(item => item.id).join(',');
      }
      if (filter.selectedDate?.start && filter.selectedDate?.end) {
        queryParams['start_date'] = filter.selectedDate.start.toISOString().split('T')[0];
        queryParams['end_date'] = filter.selectedDate.end.toISOString().split('T')[0];
      }
    }

    setIsSearched(Object.keys(queryParams).length > 0);

    return queryParams;
  }

  const emitActionEvent = async (event: ActionEventType, data?: any) => {
    if (event) {
      await eventsQueue.add(async () => {
        setActionEvent({ event, data });
        await new Promise(resolve => setTimeout(resolve));
        setActionEvent(null);
      });
    } else {
      setActionEvent(null);
    }
  }

  const ProviderValue = {
    setMainHeader, resetMainHeader, header,

    currentPage, setCurrentPage,
    currentTab, setCurrentTab,
    headerRef, setHeaderRef,
    headerHeight, setHeaderHeight,

    searchTerm, setSearchTerm,

    clearFilterTermFunction, setClearFilterTermFunction,
    performSearch, performClearSearch,
    filterApplied, setFilterApplied,
    filteredMode, setFilteredMode,
    isSearched, setIsSearched,
    isSearchedDashCounter, setIsSearchedDashCounter,
    formatFilter,

    actionEvent, emitActionEvent,
  };

  return (
    <MainAppContext.Provider value={ProviderValue}>
      {children}
    </MainAppContext.Provider>
  );
};

export const useMainAppContext = () => useContext(MainAppContext);

type MainAppContextType = {
  setMainHeader: (data: SetMainSettings) => void;
  resetMainHeader: () => void;

  header: {
    headerTitle: string;
    headerSubtitle: string;
    hasSearchComponent: boolean;
    hasSearchInput: boolean;
    hasSearchFilter: boolean;
    searchPage: SearchPagesType;
    searchPlaceholder: string;
    searchFunction: (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => void;
    clearSearchFunction: () => any;
    hasCreateButton: boolean;
    createButtonDisabled: boolean;
    createButtonText: string;
    createButtonTooltip: string;
    createButtonAction: () => any;
    customBackgroundColor?: string;
  };

  currentPage: string;
  setCurrentPage: Dispatch<SetStateAction<string>>;

  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;

  headerRef: MutableRefObject<HTMLDivElement>;
  setHeaderRef: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;

  headerHeight: number;
  setHeaderHeight: Dispatch<SetStateAction<number>>;

  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;

  clearFilterTermFunction: () => void;
  setClearFilterTermFunction: Dispatch<SetStateAction<() => void>>;

  performSearch: (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => void;
  performClearSearch: () => void;

  filterApplied: SearchComponentFilterType;
  setFilterApplied: Dispatch<SetStateAction<SearchComponentFilterType>>;

  filteredMode: "simple" | "advanced";
  setFilteredMode: Dispatch<SetStateAction<"simple" | "advanced">>;

  isSearched: boolean;
  setIsSearched: Dispatch<SetStateAction<boolean>>;

  isSearchedDashCounter: boolean;
  setIsSearchedDashCounter: Dispatch<SetStateAction<boolean>>;

  formatFilter: (filter: SearchComponentFilterType, termSearch: string, mode?: "simple" | "advanced",  page?: SearchPagesType) => {
    [key: string]: string;
  };

  actionEvent: { event: ActionEventType, data: any };
  emitActionEvent: (event: ActionEventType, data?: any) => Promise<void>;
}

type SetMainSettings = {
  pageTitle: string; 
  pageSubtitle: string; 
  hasSearchComponent: boolean;
  hasSearchInput: boolean;
  hasSearchFilter: boolean;
  searchPage: SearchPagesType;
  searchPlaceholder?: string; 
  searchFunction?: any;
  clearSearchFunction?: any;
  hasCreateButton: boolean;
  createButtonDisabled?: boolean;
  createButtonText?: string;
  createButtonTooltip?: string;
  createButtonAction?: () => any;
  customBackgroundColor?: string;
}

export type PopupTypes = 'notifications' | 'phone' | 'status';

type ActionEventType = (
  'create-ticket' | 'update-ticket-request' | 'update-ticket-replace' | 'update-ticket-replace-new-message' | 'resolve-ticket' | 'update-ticket-messages' |
  'new-ticket-insert' | 'delete-ticket' | 'tickets-expired' |
  'update-who-is-viewing' |
  'new-notification' | 'new-ticket-notification' | 'new-message-notification' |
  'update-status-agent'
);