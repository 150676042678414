import { FC, useEffect, useRef, useState, MouseEvent } from "react";
import ListOfTickets from ".";
import { DashCounterClickType, FetchListTicketsInterface, ListOfTicketsControllerInterface, ListTicketsInterface, NewConsumerMessageInterface } from "./indexModel";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { ListHeadersTable } from "../components/tableListComponent/indexModel";
import constsRouters from "../../../../routes/constsRouter";
import { useMainAppContext } from "../../../../core/context/main-app-context";
import { getAppOpenSidebar, getAppSidebarPage } from "../../../../store/app_sidebar";
import { useViewTicketContext } from "../../../../core/context/view-ticket-context";
import { AppliedFiltersBarType, SearchComponentFilterType } from "../../components-fixed/searchComponent/indexModel";
import { CustomSelectOptionType } from "../../../../components/customSelect/indexModel";
import { CustomFieldEntity } from "../../../../core/enums/custom-field-type";
import {
  setShowAlertFeedback,
} from "../../../../store/internal";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { OrderByType, SortByType } from "../../../../core/enums/order-sort-by";
import { UserProfile } from "../../../../core/enums/user-profile";
import { useSocketV2Context } from "../../../../core/context/socket-context-v2";
import { TicketInterface } from "../viewTicket/indexModel";
import { Popover } from "react-bootstrap";
import emitter from "../../../../core/shared/emitter";
import { Channels } from "../../../../core/enums/channels";
import { PaginationDetailsType } from "../../../../components/customTableList/indexModel";
import { PagesType } from "../../components-fixed/sidebarAppComponent/indexModel";
import { IconCheckupList, IconEye, IconMailUp, IconMailForward, IconReceiptRefund, IconTrashX } from "@tabler/icons-react";
import {
  getTicketListOrderBy,
  getTicketListSortBy,
  getHistoryGeneralOrderBy,
  getHistoryGeneralSortBy,
  getHistoryChatbotOrderBy,
  // getHistoryChatbotSortBy,
  getHistoryTelephonyOrderBy,
  setTicketListOrderBy,
  setTicketListSortBy,
  setHistoryGeneralOrderBy,
  setHistoryChatbotOrderBy,
  setHistoryTelephonyOrderBy,
  setHistoryGeneralSortBy,
  setHistoryChatbotSortBy,
  setHistoryTelephonySortBy,
  getHistoryChatbotSortBy,
  getHistoryTelephonySortBy,
} from "../../../../store/table_sort_order";
import { getEmployeeSectors } from "../../../../store/employee";
// import { SocketContext } from "../../../../core/context/socket-context";
// import { TicketInterface } from "../viewTicket/indexModel";
// import { useSocketV2Context } from "../../../../core/context/socket-context-v2";

const DATE_SIZE = '185px';
const REQUESTER_SIZE = '160px';
const CHATBOT_SIZE = '160px';
const SUBJECT_SIZE = '170px';
const MAX_SUBJECT_SIZE = '200px';
const SECTOR_SIZE = '150px';
const ASSIGNED_EMPLOYEE_SIZE = '170px';
const CHANNEL_SIZE = '120px';
const STATUS_SIZE = '60px';
const LIMIT_PAGINATION = 25;

const AppRequesterConst = new AppRequesterController();

const ListOfTicketsController: FC<ListOfTicketsControllerInterface> = (props) => {

  const { t } = useTranslation('ListOfTickets');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();
  const { 
    consumerUserId, consumerName,
    agentUserId, agentName,
    taskTicketId, taskTicketValue,
    activeTriggerId, activeTriggerValue,
    sectorId, sectorValue,
    tagId, tagValue,
  } = location.state || {};

  const { 
    registerSocketAppEvent, 
    socketInitialized,
    unregisterSocketAppEvent,
  } = useSocketV2Context();

  // const socket = useContext(SocketContext);
  const sidebarOpened = useSelector(getAppOpenSidebar);
  const sidebarPage = useSelector(getAppSidebarPage);
  const user = {
    id: useSelector(getIdUser),
    name: useSelector(getNameUser),
    profile: useSelector(getProfileId)
  }

  const employee = {
    sectors: useSelector(getEmployeeSectors)
  }

  const ticketListSortBy = useSelector(getTicketListSortBy);
  const ticketListSortByRef = useRef<SortByType>(ticketListSortBy);
  const ticketListOrderBy = useSelector(getTicketListOrderBy);
  const ticketListOrderByRef = useRef<OrderByType>(ticketListOrderBy);
  
  const historyGeneralSortBy = useSelector(getHistoryGeneralSortBy);
  const historyGeneralSortByRef = useRef<SortByType>(historyGeneralSortBy);
  const historyGeneralOrderBy = useSelector(getHistoryGeneralOrderBy);
  const historyGeneralOrderByRef = useRef<OrderByType>(historyGeneralOrderBy);

  const historyChatbotSortBy = useSelector(getHistoryChatbotSortBy);
  const historyChatbotSortByRef = useRef<SortByType>(historyChatbotSortBy);
  const historyChatbotOrderBy = useSelector(getHistoryChatbotOrderBy);
  const historyChatbotOrderByRef = useRef<OrderByType>(historyChatbotOrderBy);

  const historyTelephonySortBy = useSelector(getHistoryTelephonySortBy);
  const historyTelephonySortByRef = useRef<SortByType>(historyTelephonySortBy);
  const historyTelephonyOrderBy = useSelector(getHistoryTelephonyOrderBy);
  const historyTelephonyOrderByRef = useRef<OrderByType>(historyTelephonyOrderBy);

  const { 
    setMainHeader, 
    headerRef, 
    headerHeight, 
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    filteredMode,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
    setIsSearchedDashCounter,
    emitActionEvent,
    actionEvent,
  } = useMainAppContext();

  const {
    currentTicket,
    setCurrentTicket,
    setCurrentConsumer
  } = useViewTicketContext();
  // const { registerSocketEvent, unregisterSocketEvent } = useSocketV2Context();
  
  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());

  const [listHeaders, setListHeaders] = useState<ListHeadersTable[]>([]);
  const [data, setData] = useState<ListTicketsInterface[]>([]);
  const dataObjRef = useRef<{[key: string]: string}>({});
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedItemId, setSelectedItemId] = useState<string>(null);

  const [compactList, setCompactList] = useState(false);
  const [showList, setShowList] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  // Refs for component height
  const pageCountersRef = useRef<HTMLDivElement>(null);

  const [mainHeaderHeight, setMainHeaderHeight] = useState(headerRef?.current?.offsetHeight || 0);
  const [countersHeight, setCountersHeight] = useState(pageCountersRef?.current?.offsetHeight || 0);
  const [offsetHeight, setOffsetHeight] = useState(0);

  const [showModalCreateTicket, setShowModalCreateTicket] = useState(false);
  const [showModalDeleteTicket, setShowModalDeleteTicket] = useState(false);
  const [showModalReopenTicket, setShowModalReopenTicket] = useState(false);
  const [showModalRecoveryDeletedTicket, setShowModalRecoveryDeletedTicket] = useState(false);
  const [showModalSendTicketCopy, setShowModalSendTicketCopy] = useState(false);
  const [showModalListTasks, setShowModalListTasks] = useState(false);
  const [showModalExportAll, setShowModalExportAll] = useState(false);
  const [showModalManager, setShowModalManager] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);
  const [itemView, setItemView] = useState<{ id: string, value: string }>();

  // useEffect(() => {
  //   if (socket.getInstance()) {
  //     socket.registerEvent('ticket-update', socketTicketUpdate);
  //   }
  //   return () => {
  //     if (socket.getInstance()) {
  //       socket.unregisterEvent('ticket-update');
  //     }
  //   };
  // }, [socket.getInstance()]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('current_dash_counter');
      sessionStorage.removeItem('is_deleted_counter');
    }
  }, []);

  useEffect(() => {
    if (consumerUserId && consumerName) {
      setItemView({ id: consumerUserId, value: consumerName });
    }
    if (agentUserId && agentName) {
      setItemView({ id: agentUserId, value: agentName });
    }
    if (taskTicketId && taskTicketValue) {
      setItemView({ id: taskTicketId, value: taskTicketValue });
    }
    if (activeTriggerId && activeTriggerValue) {
      setItemView({ id: activeTriggerId, value: activeTriggerValue });
    }
    if (sectorId && sectorValue) {
      setItemView({ id: sectorId, value: sectorValue });
    }
    if (tagId && tagValue) {
      setItemView({ id: tagId, value: tagValue });
    }
  }, [
    consumerUserId, consumerName, 
    taskTicketId, taskTicketValue, 
    agentUserId, agentName,
    activeTriggerId, activeTriggerValue,
    sectorId, sectorValue,
    tagId, tagValue,
  ]);

  useEffect(() => {
    if (actionEvent?.event && actionEvent?.data) {
      if (actionEvent.event === 'update-who-is-viewing') {
        socketEventWhoIsViewing(actionEvent.data);
      }
      if (actionEvent.event === 'update-ticket-replace') {
        socketEventTicketUpdate(actionEvent.data, false);
      }
      if (actionEvent.event === 'update-ticket-replace-new-message') {
        socketEventTicketUpdate(actionEvent.data, true);
      }
      if (actionEvent.event === 'new-ticket-insert') {
        socketEventNewTicket(actionEvent.data, false);
      }
      if (actionEvent.event === 'delete-ticket') {
        socketEventDeleteTicket(actionEvent.data);
      }
    }
  }, [actionEvent]);

  useEffect(() => {
    function listOfTicketsNewConsumerMessage(data: NewConsumerMessageInterface) {
      emitActionEvent('update-ticket-replace-new-message', { ticket: data });
    }

    function listOfTicketsTicketUpdate(data: TicketInterface) {
      emitActionEvent('update-ticket-replace', data);
    }

    function listOfTicketsWhoIsViewing(data: { ticket_id: string; who_is_viewing: string; who_is_viewing_user_id: string;}) {
      emitActionEvent('update-who-is-viewing', data);
    }

    function listOfTicketsNewTicket(data: { ticket: TicketInterface }) {
      emitActionEvent('new-ticket-insert', data);
    }

    function listOfTicketsDeleteTicket(data: string[]) {
      emitActionEvent('delete-ticket', data);
    }

    function listOfTicketsSectorChanged(data: { ticket: TicketInterface }) {
      if (data.ticket.sector) {
        const employeeSectorsIds = employee.sectors.map(item => item.sector_id);

        if (employeeSectorsIds.includes(data.ticket.sector.id)) {
          if (dataObjRef.current[data.ticket.id]) {
            listOfTicketsTicketUpdate(data.ticket);
          } else {
            listOfTicketsNewTicket({ ticket: data.ticket });
          }
        } else {
          if (dataObjRef.current[data.ticket.id] && user.profile === UserProfile.Employee) {
            listOfTicketsDeleteTicket([data.ticket.id]);
          }
        }
      } else {
        if (dataObjRef.current[data.ticket.id]) { // Atualiza se estiver na listagem
          listOfTicketsTicketUpdate(data.ticket);
        } else { // Adiciona se não tiver na listagem
          listOfTicketsNewTicket({ ticket: data.ticket });
        }
      }
    }

    if (socketInitialized) {
      // O listener do evento deve ser uma função nomeada, pois esse mesmo evento pode ser registrado em outro componente
      // para uma outra finalidade

      registerSocketAppEvent('who-is-viewing', listOfTicketsWhoIsViewing);
      registerSocketAppEvent('ticket-update', listOfTicketsTicketUpdate);
      registerSocketAppEvent('new-ticket', listOfTicketsNewTicket);
      registerSocketAppEvent('new-consumer-message', listOfTicketsNewConsumerMessage);
      registerSocketAppEvent('ticket-deleted', listOfTicketsDeleteTicket);
      registerSocketAppEvent('tickets-expired', listOfTicketsDeleteTicket);
      registerSocketAppEvent('ticket-sector-changed', listOfTicketsSectorChanged);
    }

    return () => {
      if (socketInitialized) {
        unregisterSocketAppEvent('who-is-viewing', listOfTicketsWhoIsViewing);
        unregisterSocketAppEvent('ticket-update', listOfTicketsTicketUpdate);
        unregisterSocketAppEvent('new-ticket', listOfTicketsNewTicket);
        unregisterSocketAppEvent('new-consumer-message', listOfTicketsNewConsumerMessage);
        unregisterSocketAppEvent('ticket-deleted', listOfTicketsDeleteTicket);
        unregisterSocketAppEvent('tickets-expired', listOfTicketsDeleteTicket);
        unregisterSocketAppEvent('ticket-sector-changed', listOfTicketsSectorChanged);
      }
    }
  }, [socketInitialized, data]);

  useEffect(() => {
    if (data) {
      dataObjRef.current = getDataObject(data);
    }
  }, [data]);

  useEffect(() => {
    setMainHeaderHeight(headerRef?.current?.offsetHeight || 0);
  }, [headerRef?.current]);

  useEffect(() => {
    calculateOffsetHeight();
  }, [headerHeight]);

  const socketEventWhoIsViewing = (socket_data: { ticket_id: string, who_is_viewing: string, who_is_viewing_user_id: string }) => {
    if (dataObjRef.current[socket_data.ticket_id]) {
      setData(prevState => {
        prevState.forEach(item => {
          if (item.id === socket_data.ticket_id) {
            item.who_is_viewing = socket_data.who_is_viewing;
            item.who_is_viewing_user_id = socket_data.who_is_viewing_user_id;

            if (socket_data.who_is_viewing && selectedItems.includes(socket_data.ticket_id)) {
              removeSelectedItem(socket_data.ticket_id);
            }
          }
        });
        
        return prevState;
      });
    }
    if (currentTicket && currentTicket.id === socket_data.ticket_id) {
      setCurrentTicket(prevState => {
        prevState.who_is_viewing = socket_data.who_is_viewing;
        prevState.who_is_viewing_user_id = socket_data.who_is_viewing_user_id;

        return prevState;
      });
    }
  }

  const socketEventTicketUpdate = (socket_data: { ticket: TicketInterface }, isNewConsumerMessage: boolean) => {
    // Só faz a atualização se o ticket estiver visível na listagem/página atual
    if (socket_data.ticket && dataObjRef.current[socket_data.ticket?.id]) {
      if (isTicketOnCurrentList(socket_data.ticket, 'ticket-update')) {
        setData(prevState => {
          // Atualiza o item na listagem com os dados recebidos via socket;
          let currentIndex = -1;
  
          for (let i = 0; i < prevState.length; i++) {
            if (prevState[i].id === socket_data.ticket.id) {
              currentIndex = i;
              Object.keys(prevState[i]).forEach(key => {
                if (socket_data.ticket[key]?.toString()?.length > 0) {
                  prevState[i][key] = socket_data.ticket[key];
                }
              });
              break;
            }
          }
  
          if (currentIndex !== -1) {
            const resolvedStatus = [TicketStatus.Abandoned.toString(), TicketStatus.Resolved.toString()];
    
            if (resolvedStatus.includes(socket_data.ticket.status_id) && sidebarPage === 'tickets') {
              prevState.splice(currentIndex, 1);
            }
  
            let fieldSortBy = ticketListSortByRef.current;
            let fieldOrderBy = ticketListOrderByRef.current;
  
            if (props.pageType === 'history-general') {
              fieldSortBy = historyGeneralSortByRef.current;
              fieldOrderBy = historyGeneralOrderByRef.current;
            } else if (props.pageType === 'history-chatbot') {
              fieldSortBy = 'finished_at';
              fieldOrderBy = historyChatbotOrderByRef.current;
            } else if (props.pageType === 'history-telephony') {
              fieldSortBy = 'finished_at';
              fieldOrderBy = historyTelephonyOrderByRef.current;
            }
    
            // Ordena a listagem de acordo com a coluna e tipo de ordenação, e em seguida aplica um slice para exibir somente 
            // A quantidade definida por página
            if (fieldOrderBy === 'asc') {
              prevState.sort((a, b) => new Date(a[fieldSortBy]).getTime() - new Date(b[fieldSortBy]).getTime());
            } else {
              prevState.sort((a, b) => new Date(b[fieldSortBy]).getTime() - new Date(a[fieldSortBy]).getTime());
            }
    
            prevState = prevState.slice(0, LIMIT_PAGINATION);
          }
  
          return prevState;
        });
      } else {
        socketEventDeleteTicket([socket_data.ticket.id]);
      }
    } else if (socket_data.ticket && !dataObjRef.current[socket_data.ticket?.id] && !isNewConsumerMessage) {
      // Lógica para adicionar o item na listagem no ticket-update caso esteja dentro do intervalo de data da página atual

      // Removendo funcionalidade, pois não funciona quando se muda de página. 
      // O ticket atualizado sempre estava sendo inserido na página atual, caso ele não esteja na listagem

      /*
      let firstItemDate: Date = null;
      let lastItemDate: Date = null;
      let currentItemDate: Date = null;

      if (ticketListSortByRef.current === 'home_ticket_update_at') {
        firstItemDate = new Date(data[0]?.home_ticket_update_at);
        lastItemDate = new Date(data[data.length - 1]?.home_ticket_update_at);
        currentItemDate = new Date(socket_data.ticket.home_ticket_update_at);
      } else {
        firstItemDate = new Date(data[0]?.updated_level2_at);
        lastItemDate = new Date(data[data.length - 1]?.updated_level2_at);
        currentItemDate = new Date(socket_data.ticket.updated_level2_at);
      }

      const ascendingOrder = (currentItemDate >= firstItemDate && currentItemDate <= lastItemDate) || currentItemDate < firstItemDate;
      const descendingOrder = (currentItemDate <= firstItemDate && currentItemDate >= lastItemDate) || currentItemDate > firstItemDate;

      if (ascendingOrder || descendingOrder) {
        socketEventNewTicket(socket_data, true);
      }
      */
    }
  }

  const socketEventNewTicket = (socket_data: { ticket: TicketInterface }, isUpdate: boolean) => {
    if (socket_data.ticket && props.pageType === 'ticket-list' && !dataObjRef.current[socket_data.ticket?.id]) {
      if (isTicketOnCurrentList(socket_data.ticket, isUpdate ? 'ticket-update' : 'new-ticket')) {
        setData(prevState => {
          prevState.push((socket_data.ticket));
  
          if (ticketListOrderBy === 'asc') {
            prevState.sort((a, b) => new Date(a[ticketListSortBy]).getTime() - new Date(b[ticketListSortBy]).getTime());
          } else {
            prevState.sort((a, b) => new Date(b[ticketListSortBy]).getTime() - new Date(a[ticketListSortBy]).getTime());
          }
  
          prevState = prevState.slice(0, LIMIT_PAGINATION);
  
          return prevState;
        });
      }
    }
  }

  const socketEventDeleteTicket = (socket_data: string[]) => {
    if (socket_data?.length > 0) {
      const tempData: ListTicketsInterface[] = Object.assign([], data);
      let countDeleted = 0;
      socket_data.forEach(ticketId => {
        if (dataObjRef.current[ticketId]) {
          const indexToDelete = tempData.findIndex(item => item.id === ticketId);

          if (indexToDelete !== -1) {
            tempData.splice(indexToDelete, 1);
            countDeleted += 1;
          }
        }
      });
      setData(tempData);

      setPaginationDetails(prevState => {
        prevState.to -= countDeleted;
        prevState.total -= countDeleted;

        return prevState;
      });
    }
  }

  const isTicketOnCurrentList = (ticket: TicketInterface, event: 'new-ticket' | 'ticket-update') => {
    if (ticket) {
      const tempFilter = {
        employee: false,
        status: false,
        channel: false,
        sector: false,
        automation: false,
        tags: false,
        date: false,
        not_attended: false,
        not_read: false,
      }

      const currentDashCounter = sessionStorage.getItem('current_dash_counter');

      if (filters.length > 0 || currentDashCounter === 'counter-unread') {

        const query = queryFilterRef.current || {};
    
        if (query['employee']) {
          tempFilter['employee'] = query['employee'].includes(ticket.assigned_employee?.user_id);
        }
        if (query['status']) {
          tempFilter.status = query['status'].includes(ticket.status_id);
        }
        if (query['channel']) {
          tempFilter.channel = query['channel'].includes(ticket.channel_id);
        }
        if (query['sector']) {
          const sector_id = ticket.sector_id ? ticket.sector_id : 'NA';
          tempFilter.sector = query['sector'].includes(sector_id);
        }
        if (query['automation']) {
          tempFilter.automation = (
            query['automation'].includes(ticket.facebook_keys_id) || 
            query['automation'].includes(ticket.instagram_keys_id) ||
            query['automation'].includes(ticket.webchat_keys_id) ||
            query['automation'].includes(ticket.whatsapp_keys_id)
          );
        }
        if (query['tags']) {
          let tags = false;

          for (let i = 0; i < (ticket.tags?.length || 0); i++) {
            if (query['tags'].includes(ticket.tags[i])) {
              tags = true;
              break;
            }
          }
          tempFilter.tags = tags;
        }

        if (query['start_date'] && query['end_date'] && props.pageType === 'ticket-list') {
          const start = new Date(query['start_date'].split('-').toString());
          start.setHours(0, 0, 0, 0);

          const end = new Date(query['end_date'].split('-').toString());
          end.setHours(23, 59, 59, 999);

          let dateToCompare = null;

          if (ticketListSortByRef.current === 'home_ticket_update_at') {
            dateToCompare = new Date(ticket.home_ticket_update_at);
          } else {
            dateToCompare = new Date(ticket.updated_level2_at);
          }

          tempFilter.date = dateToCompare >= start && dateToCompare <= end;
        }

        if (query['employee'] === 'NA' && query['status'] === TicketStatus.New) { // Tickets não atendidos
          tempFilter.not_attended = ticket.status_id === TicketStatus.New && !ticket.assigned_employee;
          query['not_attended'] = 'true';
        }

        if (currentDashCounter === 'counter-unread') {
          tempFilter.not_read = ticket.bold;
          query['not_read'] = 'true'
        }
        
        const activeFilters = Object.entries(tempFilter).filter(([key]) => query[key] !== undefined);

        return activeFilters.every(([, value]) => value);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const calculateOffsetHeight = () => {
    const pageCountersHeight = pageCountersRef?.current?.offsetHeight || 0;
    const marginBottomOffsetHeight = 10;

    const total = (mainHeaderHeight || headerHeight) + pageCountersHeight + marginBottomOffsetHeight;

    setOffsetHeight(total);
  }

  const getMainHeaderHeight = () => {
    const bottomOffset = 10;

    if (headerHeight < 80) {
      return bottomOffset;
    } else {
      return headerHeight + bottomOffset;
    }
  }

  const getOffsetHeight = () => {
    const bottomOffset = 10;
    return mainHeaderHeight + countersHeight + bottomOffset;
  }

  useEffect(() => {
    setCompactList(!!id);
    setSelectedItemId(id);

    if (!id) {
      setSelectedItem(null);
      setCurrentTicket(null);
      setCurrentConsumer(null);
    }
  }, [id]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    const availablePages = [
      'ticket-list', 'history-general', 'history-chatbot', 'history-telephony', 'consumer-tickets',
      'agent-tickets', 'task-ticket', 'messages-trigger-tickets', 'sector-tickets', 'tag-tickets'
    ];

    if (availablePages.includes(props.pageType)) {
      configMainHeader(props.pageType);
  
      if (props.pageType === 'ticket-list') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'history-general') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: historyGeneralSortBy === 'finished_at' ? t("list_headers.sort_by_finalized_date_title") : t('list_headers.sort_by_create_date_title'),
            field: historyGeneralSortBy || 'finished_at',
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: historyGeneralOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true // adicionar o hasColumnChange quando o back subir ordenação pra data de finalização e de criação
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"),  field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'history-chatbot') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id',width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: t("list_headers.sort_by_finalized_date_title"), 
            field: 'finished_at', // usar updated_at 
            width: DATE_SIZE ,
            hasOrderBy: true,
            orderByFn: getTicketsOrdered,
            orderBy: historyChatbotOrderBy || 'desc',
            hasChangeColumn: false,
          },
          { title: t("list_headers.chatbot"), field: 'bot_cxpress_name', width: CHATBOT_SIZE },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.actions"),  field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'history-telephony') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id',width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: t("list_headers.sort_by_finalized_date_title"), 
            field: 'finished_at', 
            width: DATE_SIZE,
            hasOrderBy: true,
            orderByFn: getTicketsOrdered,
            orderBy: historyTelephonyOrderBy || 'desc',
            hasChangeColumn: false,
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.actions"),  field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'consumer-tickets') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'agent-tickets') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'task-ticket') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE,
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'messages-trigger-tickets') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'sector-tickets') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      } else if (props.pageType === 'tag-tickets') {
        setListHeaders([
          { title: t("list_headers.id"), field: 'ticket_reference_id', width: '70px', maxWidth: '70px', hasTooltip: true },
          { 
            title: ticketListSortBy === 'home_ticket_update_at' ? t("list_headers.sort_by_update_date_title") : t("list_headers.sort_by_create_date_title"), 
            field: ticketListSortBy || 'updated_level2_at', 
            width: DATE_SIZE, 
            hasOrderBy: true, 
            orderByFn: getTicketsOrdered, 
            orderBy: ticketListOrderBy || 'desc',
            sortByFN: getTicketsSorted,
            hasChangeColumn: true
          },
          { title: t("list_headers.consumer"), field: 'requester_user', width: REQUESTER_SIZE, hasTooltip: true },
          { title: t("list_headers.subject"), field: 'subject', width: SUBJECT_SIZE, maxWidth: MAX_SUBJECT_SIZE, hasTooltip: true },
          { title: t("list_headers.channel"), field: 'channel_id', width: CHANNEL_SIZE },
          { title: t("list_headers.agent"), field: 'assigned_employee', width: ASSIGNED_EMPLOYEE_SIZE, hasTooltip: true },
          { title: t("list_headers.status"), field: 'status_id', width: STATUS_SIZE },
          { title: t("list_headers.sector"), field: 'sector', width: SECTOR_SIZE, hasTooltip: true },
          { title: t("list_headers.actions"), field: 'action', width: 'auto' },
        ]);
      }
    }
  }, [props.pageType]);

  useEffect(() => {
    if (apiHeader && props.pageType) {
      setCurrentPage(1);
      if (props.pageType === 'history-general') {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: historyGeneralSortByRef.current, order_by: historyGeneralOrderBy }, false, true);
      } else if (props.pageType === 'history-chatbot') {
        const channels = [
          Channels.Facebook.toString(),
          Channels.Instagram.toString(),
          Channels.WhatsApp.toString(),
          Channels.Webchat.toString(),
        ];

        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, level: 1, order_by: historyChatbotOrderBy, channel: channels.toString() }, false, true);
      } else if (props.pageType === 'history-telephony') {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, level: 1, order_by: historyChatbotOrderBy, channel: Channels.Telefonia }, false, true);
      } else if (props.pageType === 'consumer-tickets' && itemView?.id) {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy, user_id: itemView?.id }, false, true);
      } else if (props.pageType === 'agent-tickets' && itemView?.id) {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy, user_id: itemView?.id }, false, true);
      } else if (props.pageType === 'task-ticket' && itemView?.id) {
        getTickets({ ticketId: itemView?.id }, false, true);
      } else if (props.pageType === 'ticket-list') {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy }, false, true);
      } else if (props.pageType === 'messages-trigger-tickets' && itemView?.id) {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy, message_trigger_id: itemView?.id }, false, true);
      } else if (props.pageType === 'sector-tickets' && itemView?.id) {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy, sector_id: itemView?.id }, false, true);
      } else if (props.pageType === 'tag-tickets' && itemView?.id) {
        getTickets({ limit: LIMIT_PAGINATION, page: currentPage, sort_by: ticketListSortBy, order_by: ticketListOrderBy, tag_id: itemView?.id }, false, true);
      }
    }
    // return () => {

    // }
  }, [apiHeader, props.pageType, itemView]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = (pageType: PagesType) => {
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    setIsSearchedDashCounter(false);
    sessionStorage.removeItem('is_deleted_counter');

    if (pageType === 'ticket-list') {
      setMainHeader({
        pageTitle: t('header.list_tickets_welcome_page'),
        pageSubtitle: t('header.list_tickets_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'ticket-list',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: user.profile !== UserProfile.Master, // Não exibir criação de ticket para agente master
        createButtonText: t('header.button_create'),
        createButtonTooltip: t('header.button_create_tooltip'),
        createButtonAction: openModalCreateTicket,
      });
    } else if (pageType === 'history-general') {
      setMainHeader({
        pageTitle: t('header.history_general_welcome_page'),
        pageSubtitle: t('header.history_general_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'history-general',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'history-chatbot') {
      setMainHeader({
        pageTitle: t('header.history_chatbot_welcome_page'),
        pageSubtitle: t('header.history_chatbot_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'history-chatbot',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'history-telephony') {
      setMainHeader({
        pageTitle: t('header.history_telephony_welcome_page'),
        pageSubtitle: t('header.history_telephony_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'history-telephony',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'consumer-tickets') {
      setMainHeader({
        pageTitle: t('header.list_tickets_consumer_welcome_page'),
        pageSubtitle: t('header.list_tickets_consumer_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'consumer-tickets',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'agent-tickets') {
      setMainHeader({
        pageTitle: t('header.list_tickets_agent_welcome_page'),
        pageSubtitle: t('header.list_tickets_agent_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'agent-tickets',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'task-ticket') {
      setMainHeader({
        pageTitle: t('header.list_task_ticket_welcome_page'),
        pageSubtitle: t('header.list_task_ticket_sub_info'),
        hasSearchComponent: false,
        hasSearchInput: false,
        hasSearchFilter: false,
        searchPage: 'task-ticket',
        hasCreateButton: false,
      });
    } else if (pageType === 'messages-trigger-tickets') {
      setMainHeader({
        pageTitle: t('header.list_tickets_messages_trigger_welcome_page'),
        pageSubtitle: t('header.list_tickets_messages_trigger_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'messages-trigger-tickets',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'sector-tickets') {
      setMainHeader({
        pageTitle: t('header.list_tickets_sector_welcome_page'),
        pageSubtitle: t('header.list_tickets_sector_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'sector-tickets',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    } else if (pageType === 'tag-tickets') {
      setMainHeader({
        pageTitle: t('header.list_tickets_tag_welcome_page'),
        pageSubtitle: t('header.list_tickets_tag_sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'tag-tickets',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchTickets,
        clearSearchFunction: clearSearchTickets,
        hasCreateButton: false,
      });
    }
  }

  const getTickets = async (params?: any, isScrollPagination?: boolean, isStart?: boolean) => {
    setErrorLoading(false);
    let path = '/ticket';
    let searchedDashCounter = false;

    const currentDashCounter = sessionStorage.getItem('current_dash_counter') as DashCounterClickType;

    if (props.pageType === 'history-general') {
      path = '/ticket/historic';
      searchedDashCounter = currentDashCounter !== 'counter-finished';
    } else if (props.pageType === 'history-chatbot') {
      path = '/ticket/historic';
      params['level'] = 1;

      if (!params.channel) {
        const channels = [Channels.Facebook, Channels.Instagram, Channels.WhatsApp, Channels.Webchat];
        params['channel'] = channels.toString();
      }
      searchedDashCounter = currentDashCounter !== 'counter-finished';
    } else if (props.pageType === 'history-telephony') {
      path = '/ticket/historic';
      params['level'] = 1;
      params['channel'] = Channels.Telefonia;
      searchedDashCounter = currentDashCounter !== 'counter-finished';
    } else if (props.pageType === 'consumer-tickets' && consumerUserId) {
      path = `/consumer/${consumerUserId}/tickets`;
      delete params.user_id;
    } else if (props.pageType === 'agent-tickets' && agentUserId) {
      path = `/agent/${agentUserId}/tickets`;
      delete params.user_id;
    } else if (props.pageType === 'task-ticket') {
      path = `/ticket/${taskTicketId}`;
      params = {};
    } else if (props.pageType === 'messages-trigger-tickets') {
      path = `/message-sending/${activeTriggerId}/tickets`;
      delete params.message_trigger_id;
    } else if (props.pageType === 'sector-tickets') {
      delete params.sector_id;
      params['sector'] = sectorId;
    } else if (props.pageType === 'tag-tickets') {
      path = `/tag/${tagId}/tickets`;
      delete params.tag_id;
    } else {
      searchedDashCounter = currentDashCounter !== 'counter-all';
    }

    if (isStart) {
      if (currentDashCounter) {
        const { params: processedParams, filterClick } = processDashCounterParams(currentDashCounter);
        params = { ...params, ...processedParams };
        setFilterApplied(filterClick);
        queryFilterRef.current = formatFilter(filterClick, '', 'simple', props.pageType);

        if (props.pageType === 'history-general') {
          searchedDashCounter = currentDashCounter !== 'counter-finished';
        } else if (props.pageType === 'history-chatbot' || props.pageType === 'history-telephony') {
          searchedDashCounter = currentDashCounter !== 'counter-finished';
        } else {
          searchedDashCounter = currentDashCounter !== 'counter-all';
        }

        setIsSearchedDashCounter(searchedDashCounter);
      }
    }
    
    const config = { headers: apiHeader, params };

    await AppRequesterConst.Get(
      path, config,
      (response: Object) => {},
      (response: FetchListTicketsInterface) => {
        if (response.status === 200 && response.data.tickets?.length > 0) {
          if (response.data.tickets.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }

          if (isScrollPagination) {
            const new_array = [...data, ...response.data.tickets];
            setData(new_array);
          } else {
            setData(response.data.tickets || []);
            dataObjRef.current = getDataObject(response.data.tickets);
          }

          if (props.pageType === 'task-ticket') {
            processPaginationDetails({
              total: 1,
              lastPage: 1,
              prevPage: null,
              nextPage: null,
              perPage: 25,
              currentPage: 1,
              from: 0,
              to: 1
            });
          } else {
            processPaginationDetails(response.data.pagination);
          }
        } else {
          if (!isScrollPagination) {
            setData([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        console.error(error);
        setErrorLoading(true);
        if (error?.response?.status === 401) {
          if (error?.response?.data?.code_cxpress === 1011) {
            return;
          }
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
        } else if (error?.response?.status === 422) {
          dispatch(setTicketListOrderBy('desc'));
          dispatch(setTicketListSortBy('updated_level2_at'));
          dispatch(setShowAlertFeedback({ message: t('error_loading_tickets'), visibility: true, signalIcon: false }));
        } else if (error?.response?.data) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
        } else {
          dispatch(setShowAlertFeedback({ message: t('error_search'), visibility: true, signalIcon: false }));
        }
      }, navigate, dispatch, setIsLoading, { }
    );
  }

  const getTicketsPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, ...currentFilter };

    if (props.pageType === 'history-general') {
      params['order_by'] = historyGeneralOrderBy;

      const deletedCounter = sessionStorage.getItem('is_deleted_counter');
      if (deletedCounter === 'true') {
        params['deleted'] = true;
        params['sort_by'] = 'deleted_at';
      } else {
        params['sort_by'] = historyGeneralSortByRef.current;
      }
    } else if (props.pageType === 'history-chatbot') {
      params['order_by'] = historyChatbotOrderBy;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      params['order_by'] = historyTelephonyOrderBy;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else {
      params['order_by'] = ticketListOrderBy;
      params['sort_by'] = ticketListSortBy;
    }

    scrollTop();

    if (page) {
      params.page = page;
      setCurrentPage(page);
      await getTickets(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        await getTickets(params, true);
      }
    }
  }

  const getTicketsOrdered = async (field: string, order: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, order_by: order, ...currentFilter };

    if (['ticket-list', 'consumer-tickets', 'agent-tickets', 'messages-trigger-tickets', 'sector-tickets', 'tag-tickets'].includes(props.pageType)) {
      params['sort_by'] = field;
    }

    const deletedCounter = sessionStorage.getItem('is_deleted_counter');
    if (props.pageType === 'history-general') {
      if (deletedCounter === 'true') {
        params['deleted'] = true;
        params['sort_by'] = 'deleted_at';
      } else {
        params['sort_by'] = 'finished_at';
      }
    } else if (props.pageType === 'history-chatbot') {
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    }

    if (props.pageType === 'consumer-tickets' && itemView) {
      params['user_id'] = itemView.id;
    } else if (props.pageType === 'agent-tickets' && itemView) {
      params['user_id'] = itemView.id;
    }

    setHeaderOrderBy(field, order);
    await getTickets(params);
  }

  const getTicketsSorted = async (oldField: SortByType, newField: SortByType, orderBy: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: newField, order_by: orderBy, ...currentFilter };

    if (props.pageType === 'consumer-tickets' && itemView) {
      params['user_id'] = itemView.id;
    } else if (props.pageType === 'agent-tickets' && itemView) {
      params['user_id'] = itemView.id;
    }

    setHeaderSortBy(oldField, newField);
    await getTickets(params);
  }

  const setHeaderOrderBy = (field: string, order: 'asc' | 'desc'): void => {
    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === field) {
          item.orderBy = order;
        }
      });

      if (props.pageType === 'history-general') {
        dispatch(setHistoryGeneralOrderBy(order));
        historyGeneralOrderByRef.current = order;
      } else if (props.pageType === 'history-chatbot') {
        dispatch(setHistoryChatbotOrderBy(order));
        historyChatbotOrderByRef.current = order;
      } else if (props.pageType === 'history-telephony') {
        dispatch(setHistoryTelephonyOrderBy(order));
        historyTelephonyOrderByRef.current = order;
      } else {
        dispatch(setTicketListOrderBy(order));
        ticketListOrderByRef.current = order;
      }
      return prevState;
    });
  }

  const setHeaderSortBy = (oldField: SortByType, newField: SortByType): void => {
    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === oldField) {
          item.field = newField;

          if (newField === 'home_ticket_update_at') {
            item.title = t("list_headers.sort_by_update_date_title");
          } else if (newField === 'updated_level2_at') {
            item.title = t("list_headers.sort_by_create_date_title");
          } else if (newField === 'finished_at') {
            item.title = t("list_headers.sort_by_finalized_date_title");
          }
        }
      });

      if (props.pageType === 'history-general') {
        dispatch(setHistoryGeneralSortBy(newField));
        historyGeneralSortByRef.current = newField;
      } else if (props.pageType === 'history-chatbot') {
        dispatch(setHistoryChatbotSortBy(newField));
      } else if (props.pageType === 'history-telephony') {
        dispatch(setHistoryTelephonySortBy(newField));
      } else {
        dispatch(setTicketListSortBy(newField));
        ticketListSortByRef.current = newField;
      }
      
      return prevState;
    });
  }

  const getListHeaders = (): ListHeadersTable[] => {
    return listHeaders;
  }

  const selectItem = (item: ListTicketsInterface, event: MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    event.stopPropagation();
    if (!getIsItemDisabled(item, true)) {
      if (selectedItems.includes(item.id)) {
        const new_selected = [...selectedItems];
        const index = new_selected.indexOf(item.id);
        if (index > -1) {
          new_selected.splice(index, 1);
          setSelectedItems(new_selected);
        }
      } else {
        const new_selected = [...selectedItems, item.id];
        setSelectedItems(new_selected);
      }
    }
  }

  const removeSelectedItem = (id: string): void => {
    if (selectedItems.includes(id)) {
      const new_selected = [...selectedItems];
      const index = new_selected.indexOf(id);
      if (index > -1) {
        new_selected.splice(index, 1);
        setSelectedItems(new_selected);
      }
    }
  }

  const selectAllItems = (): void => {
    if (data.length > 0) {
      // Verificar lógica de marcar todos, pois os tickets em processamento e com alguém visualizando não pode ser modificado
      const localSelect = (id: string, array: string[]) => {
        if (array.includes(id)) {
          const index = array.indexOf(id);
          array.splice(index, 1);
        } else {
          array = [...array, id];
        }
        return array;
      }
  
      let count = 0;
  
      let selected = Object.assign([], selectedItems);
  
      selected.forEach(id => {
        if (dataObjRef.current[id] && dataObjRef.current[id] !== 'disabled') {
          count += 1;
        }
      });
  
      if (count === data.filter(item => !getIsItemDisabled(item, true)).length) { // Se todos na página tiver selecionados, remove apenas o da página
        Object.keys(dataObjRef.current).forEach(id => {
          if (dataObjRef.current[id] !== 'disabled') {
            const item = data.find(item => item.id === id);
            if (!getIsItemDisabled(item, true)) {
              selected = localSelect(id, selected);
            }
          }
        });
      } else { // Senão, marca apenas os que estão desmarcados
        Object.keys(dataObjRef.current).forEach(id => {
          const item = data.find(item => item.id === id);

          if (!getIsItemDisabled(item, true) && !selectedItems.includes(id)) {
            selected = localSelect(id, selected);
          }
        });
      }
  
      setSelectedItems(selected);
    }
  }

  const isSelectedItem = (id: string): boolean => {
    return selectedItems.includes(id);
  }

  const isAllSelectedItems = (): boolean => {
    if (selectedItems.length > 0) {
      let count = 0;

      selectedItems.forEach(id => {
        if (dataObjRef.current[id] && dataObjRef.current[id] !== 'disabled') {
          count += 1;
        }
      });

      return data.filter(item => !getIsItemDisabled(item, true)).length === count;
    } else {
      return false;
    }
    // return selectedItems.length > 0 && selectedItems.length === data.length;
  }

  const hasSomeSelectedCheck = (): boolean => {
    let hasSome = false;

    const pageIds = Object.keys(dataObjRef.current);

    for (let i = 0; i < pageIds.length; i++) {
      if (selectedItems.includes(pageIds[i])) {
        hasSome = true;
        break;
      }
    }

    return hasSome;
  }

  const hasSomeSelected = (): boolean => {
    return selectedItems.length > 0;
  }

  const clearSelected = () => {
    setSelectedItems([]);
  }

  const clearPageSelected = () => {
    let selected = Object.assign([], selectedItems);

    Object.keys(dataObjRef.current).forEach(id => {
      if (selected.includes(id)) {
        const index = selected.indexOf(id);
        selected.splice(index, 1);
      }
    });
    setSelectedItems(selected);
  }

  const openTicket = (item: ListTicketsInterface, event: MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.stopPropagation();
    if (!item.processing) {
      let routePath = `${constsRouters.routers.tickets.path}`;

      if (props.pageType === 'history-general') {
        routePath = `${constsRouters.routers.history.path}`;
      } else if (props.pageType === 'history-chatbot') {
        routePath = `${constsRouters.routers.historyChatbot.path}`;
      } else if (props.pageType === 'history-telephony') {
        routePath = `${constsRouters.routers.historyTelephony.path}`;
      } else if (props.pageType === 'consumer-tickets') {
        routePath = `${constsRouters.routers.consumerTickets.path}`;
      } else if (props.pageType === 'agent-tickets') {
        routePath = `${constsRouters.routers.agentTickets.path}`;
      } else if (props.pageType === 'task-ticket') {
        routePath = `${constsRouters.routers.taskTicket.path}`;
      } else if (props.pageType === 'messages-trigger-tickets') {
        routePath = `${constsRouters.routers.activeTriggerTickets.path}`;
      } else if (props.pageType === 'sector-tickets') {
        routePath = `${constsRouters.routers.configurationSectorsTickets.path}`;
      } else if (props.pageType === 'tag-tickets') {
        routePath = `${constsRouters.routers.configurationTagsTickets.path}`;
      }

      if (item.who_is_viewing) {
        if (user.profile === UserProfile.Owner || user.profile === UserProfile.Admin) {
          navigate(`${routePath}/${item.id}`);
        } else {
          if (props.pageType.includes('history')) {
            navigate(`${routePath}/${item.id}`);
          }
        }
      } else {
        navigate(`${routePath}/${item.id}`);
      }
    }
  }

  const searchTickets = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1, ...query };
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    setIsSearchedDashCounter(false);

    if (props.pageType === 'history-general') {
      params['order_by'] = historyGeneralOrderByRef.current;

      const deletedCounter = sessionStorage.getItem('is_deleted_counter');
      if (deletedCounter === 'true') { // Tickets excluídos
        params['deleted'] = true;
        params['sort_by'] = 'deleted_at';
      } else { // Tickets finalizados
        params['sort_by'] = historyGeneralSortByRef.current;
      }
    } else if (props.pageType === 'history-chatbot') {
      params['order_by'] = historyChatbotOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      params['order_by'] = historyTelephonyOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else {
      params['sort_by'] = ticketListSortByRef.current;
      params['order_by'] = ticketListOrderByRef.current;
    }

    getTickets(params);
    scrollTop();
  }

  const handleClickDashCounter = (type: DashCounterClickType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1 };
    const filterClick: SearchComponentFilterType = {};

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }

    if (props.pageType === 'history-general') {
      params['order_by'] = historyGeneralOrderByRef.current;
      params['sort_by'] = historyGeneralSortByRef.current;
    } else if (props.pageType === 'history-chatbot') {
      params['order_by'] = historyChatbotOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      params['order_by'] = historyTelephonyOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else {
      params['order_by'] = ticketListOrderByRef.current;
      params['sort_by'] = ticketListSortByRef.current;
    }

    if (type === 'counter-deleted') {
      sessionStorage.setItem('is_deleted_counter', 'true');
    } else {
      sessionStorage.removeItem('is_deleted_counter');
    }

    if (type === 'counter-user-assigned') {
      params['employee'] = user.id;

      filterClick.selectedAgent = [{
        id: user.id,
        value: user.name
      }];
    } else if (type === 'counter-unassigned') {
      params['employee'] = 'NA';
      params['status'] = TicketStatus.New;

      filterClick.selectedAgent = [{ id: 'NA', value: 'N/A' }];
      filterClick.selectedStatus = [{
        id: TicketStatus.New,
        value: t('filter_applied.status_new')
      }]
    } else if (type === 'counter-unread') {
      params['was_read'] = false;
    } else if (type === 'counter-deleted') {
      params['deleted'] = true;
      params['sort_by'] = 'deleted_at';

      setListHeaders(prevState => {
        prevState.forEach(item => {
          if (['finished_at', 'updated_level2_at'].includes(item.field)) {
            item.field = 'deleted_at';
            item.title = t("list_headers.sort_by_deleted_date_title");
            item.hasChangeColumn = false;
          }
        });
          
        return prevState;
      });
    } else if (type === 'counter-finished' && props.pageType === 'history-general') {
      setListHeaders(prevState => {
        prevState.forEach(item => {
          if (item.field === 'deleted_at') {
            item.field = historyGeneralSortByRef.current || 'finished_at';
            item.title = historyGeneralSortByRef.current === 'finished_at' ? t("list_headers.sort_by_finalized_date_title") : t('list_headers.sort_by_create_date_title');
            item.hasOrderBy = true;
            item.hasChangeColumn = true;
          }
        });
          
        return prevState;
      });
    } else if (type === 'counter-finished' && ['history-chatbot', 'history-telephony'].includes(props.pageType)) {
      params['level'] = 1;
    }

    setFilterApplied(filterClick);
    queryFilterRef.current = formatFilter(filterClick, '', 'simple', props.pageType);
    setIsSearchedDashCounter(true);
    getTickets(params);
    scrollTop();
  }

  const processDashCounterParams = (type: DashCounterClickType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1 };
    const filterClick: SearchComponentFilterType = {};

    if (type === 'counter-user-assigned') {
      params['employee'] = user.id;
      filterClick.selectedAgent = [{ id: user.id, value: user.name }];
    } else if (type === 'counter-unassigned') {
      params['employee'] = 'NA';
      params['status'] = TicketStatus.New;

      filterClick.selectedAgent = [{ id: 'NA', value: 'N/A' }];
      filterClick.selectedStatus = [{ id: TicketStatus.New, value: t('filter_applied.status_new') }];
    } else if (type === 'counter-unread') {
      params['was_read'] = false;
    } else if (type === 'counter-deleted') {
      params['deleted'] = true;
    } else if (type === 'counter-finished' && props.pageType === 'history-general') {
    } else if (type === 'counter-finished' && ['history-chatbot', 'history-telephony'].includes(props.pageType)) {
      params["level"] = 1;
    }

    return { params, filterClick };
  };

  const clearSearchTickets = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1 };
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    setIsSearchedDashCounter(false);
    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }

    if (props.pageType === 'history-general') {
      params['order_by'] = historyGeneralOrderByRef.current;
      const deletedCounter = sessionStorage.getItem('is_deleted_counter');
      if (deletedCounter === 'true') { // Tickets excluídos
        params['deleted'] = true;
        params['sort_by'] = 'deleted_at';
      } else { // Tickets finalizados
        params['sort_by'] = 'finished_at'; // deve ser 'finished_at'
      }
    } else if (props.pageType === 'history-chatbot') {
      params['order_by'] = historyChatbotOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      params['order_by'] = historyTelephonyOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else {
      params['sort_by'] = ticketListSortByRef.current;
      params['order_by'] = ticketListOrderByRef.current;
    }

    getTickets(params);
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };

    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (filterMode === 'main') {
        if (key === 'channel') {
          tempFilterApplied.selectedChannel = [];
        } else if (key === 'automation') {
          tempFilterApplied.selectedAutomation = [];
        } else if (key === 'bot_id') {
          tempFilterApplied.selectedChatbot = [];
        } else if (key === 'employee') {
          tempFilterApplied.selectedAgent = [];
        } else if (key === 'status') {
          tempFilterApplied.selectedStatus = [];
        } else if (key === 'sector') {
          tempFilterApplied.selectedSector = [];
        } else if (key === 'tags') {
          tempFilterApplied.selectedTag = [];
        } else if (key === 'date') {
          tempFilterApplied.selectedDate = { start: null, end: null };
          tempFilterApplied.selectedFilterDate = { id: null, value: null };
        } else if (key === 'search') {
          currentSearchTerm = '';
          clearFilterTermFunction();
        }
      } else if (filterMode === 'custom') {
        delete tempFilterApplied.selectedCustomFieldListValuesObject[key];

        const indexToDelete = tempFilterApplied.selectedCustomFieldListFilter.findIndex(item => item.id === key);

        if (indexToDelete >= 0) {
          tempFilterApplied.selectedCustomFieldListFilter.splice(indexToDelete, 1);
        }
      }

      setFilterApplied(tempFilterApplied);
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, props.pageType), tempFilterApplied);
      setIsSearchedDashCounter(false);
      scrollTop();
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters: AppliedFiltersBarType[] = [];

    if (props.pageType === 'consumer-tickets' && itemView?.id) {
      const item = { key: 'consumer', tooltip_key: 'filter_applied.consumer', value: itemView.value, fixed: true };
      filters.push(item);
    }

    if (props.pageType === 'agent-tickets' && itemView?.id) {
      const item = { key: 'agent', tooltip_key: 'filter_applied.employee', value: itemView.value, fixed: true };
      filters.push(item);
    }
    
    if (props.pageType === 'task-ticket' && itemView?.id) {
      const item = { key: 'task', tooltip_key: 'filter_applied.task', value: itemView.value, fixed: true };
      filters.push(item);
    }

    if (props.pageType === 'messages-trigger-tickets' && itemView?.id) {
      const item = { key: 'message-trigger', tooltip_key: 'filter_applied.message_trigger', value: itemView.value, fixed: true };
      filters.push(item);
    }

    if (props.pageType === 'sector-tickets' && itemView?.id) {
      const item = { key: 'sector', tooltip_key: 'filter_applied.sector', value: itemView.value, fixed: true };
      filters.push(item);
    }

    if (props.pageType === 'tag-tickets' && itemView?.id) {
      const item = { key: 'tags', tooltip_key: 'filter_applied.tags', value: itemView.value, fixed: true };
      filters.push(item);
    }

    let hasDate = false;

    if (filteredMode === 'advanced') {
      Object.keys(filterApplied?.selectedCustomFieldListValuesObject || {}).forEach(key => {
        const item = { key, tooltip_key: 'filter_applied.custom_field_ticket', value: null };
        
        const value = filterApplied.selectedCustomFieldListValuesObject[key];

        const customField = filterApplied.selectedCustomFieldListFilter.find(item => item.id === key);

        if (customField?.entity_type_id === CustomFieldEntity.Consumer) {
          item.tooltip_key = 'filter_applied.custom_field_consumer';
        }

        item.value = value.toString().startsWith('[') ? `@${customField.name}: (${(value as CustomSelectOptionType[]).map(item => item.value)?.join(', ')})` : `@${customField.name}: (${value})`;
        filters.push(item);
      });

      setFilterMode('custom');
    } else {
      Object.keys(queryFilterRef.current).forEach(key => {
        const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
        
        if (key === 'search') {
          item.value = queryFilterRef.current[key];
        } 
  
        if (key === 'channel') {
          item.value = filterApplied.selectedChannel?.map(item => item.value).join(', ');
        }

        if (key === 'bot_id') {
          item.value = filterApplied.selectedChatbot?.map(item => item.value).join(', ');
        }
  
        if (key === 'automation') {
          item.value = filterApplied.selectedAutomation?.map(item => item.value).join(', ');
        }
  
        if (key === 'employee') {
          item.value = filterApplied.selectedAgent?.map(item => item.value).join(', ');
        }
  
        if (key === 'status') {
          item.value = filterApplied.selectedStatus?.map(item => item.value).join(', ');
        }
  
        if (key === 'sector') {
          item.value = filterApplied.selectedSector?.map(item => item.value).join(', ');
        }

        if (key === 'tags') {
          item.value = filterApplied.selectedTag?.map(item => item.value).join(', ');
        }
  
        if (!hasDate && key.includes('date')) {
          hasDate = true;
          if (filterApplied.selectedFilterDate.id === 'custom') {
            item.value = `${filterApplied.selectedDate.start.toDateString()} - ${filterApplied.selectedDate.end.toDateString()}`
          } else {
            item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
          }
        }
  
        if (!key.includes('date')) {
          filters.push(item);
        }
      });

      setFilterMode('main');
    }

    if (queryFilterRef.current.start_date && queryFilterRef.current.end_date) {
      const item = { key: 'date', tooltip_key: 'filter_applied.period', value: '' };

      if (filterApplied.selectedFilterDate.id === 'custom') {
        item.value = `${filterApplied.selectedDate.start.toLocaleDateString()} - ${filterApplied.selectedDate.end.toLocaleDateString()}`
      } else {
        item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
      }

      filters.push(item);
    }

    return filters;
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const getDataObject = (data: ListTicketsInterface[]) => {
    const obj = {};
    data.forEach(item => {
      obj[item.id] = getIsItemDisabled(item, false) ? 'disabled' : item.id;
    });
    return obj;
  }

  const scrollTop = () => {
    const listElement = document.getElementById('table-list-component-content');
    const compactListElement = document.getElementById('table-compact-list-items');

    if (listElement) {
      listElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (compactListElement) {
      compactListElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // const socketTicketUpdate = (data: { ticket: TicketInterface }) => {
  //   console.log(`:: socket: ticket-update - ${data.ticket.ticket_reference_id} - ${data.ticket.who_is_viewing} ::`);
  //   console.log(data.ticket);
  //   setData(prevItems => (
  //     prevItems.map(item => (
  //       item.id === data.ticket.id ? { ...item, ...data.ticket } : item
  //     )
  //   )));
  // }

  const openModalCreateTicket = () => {
    setShowModalCreateTicket(true);
  }

  const closeModalCreateTicket = (reload: boolean) => {
    if (reload) {
      reloadTickets();
      emitActionEvent('create-ticket');
    }
    setShowModalCreateTicket(false);
  }

  const openModalDeleteTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalDeleteTicket(true);
    emitter.emit('open-modal-delete-ticket', data);
  }

  const closeModalDeleteTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      reloadTickets(true);
      if (ticket_id) {
        removeSelectedItem(ticket_id);
      }
    }
    setShowModalDeleteTicket(false);
  }

  const openModalReopenTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalReopenTicket(true);
    emitter.emit('open-modal-reopen-ticket', data);
  }

  const closeModalReopenTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      reloadTickets();
      navigate(`${constsRouters.routers.tickets.path}/${ticket_id}`);
    }
    setShowModalReopenTicket(false);
  }

  const openModalRecoveryDeletedTicket = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalRecoveryDeletedTicket(true);
    emitter.emit('open-modal-recovery-deleted-ticket', data);
  }

  const closeModalRecoveryDeletedTicket = (reload: boolean, ticket_id: string) => {
    if (reload) {
      reloadTickets(true);
      navigate(`${constsRouters.routers.tickets.path}/${ticket_id}`);
    }
    setShowModalRecoveryDeletedTicket(false);
  }

  const openModalSendTicketCopy = (data: { ticketId: string, ticketReferenceId: string, subject: string }) => {
    setShowModalSendTicketCopy(true);
    emitter.emit('open-modal-send-ticket-copy', data);
  }

  const closeModalSendTicketCopy = () => {
    setShowModalSendTicketCopy(false);
  }

  const openModalListTasks = (data: { ticketId: string, ticketReferenceId: string }) => {
    setShowModalListTasks(true);
    emitter.emit('open-modal-list-tasks', data);
  }

  const closeModalListTasks = () => {
    setShowModalListTasks(false);
  }

  const openModalExportAll = () => {
    setShowModalExportAll(true);
    setTimeout(() => {
      emitter.emit('open-modal-export-all-tickets', { filter: filterApplied });
    }, 50);
  }

  const closeModalExportAll = () => {
    setShowModalExportAll(false);
  }

  const openModalManager = () => {
    setShowModalManager(true);
    setTimeout(() => {
      emitter.emit('open-modal-manager-tickets', { ticket_ids: selectedItems });
    }, 50);
  }

  const closeModalManager = () => {
    setShowModalManager(false);
  }

  const reloadTickets = (preserveFilter?: boolean) => {
    let params = { limit: LIMIT_PAGINATION, page: 1 };

    if (props.pageType === 'history-general') {
      params['order_by'] = historyGeneralOrderByRef.current;
      const deletedCounter = sessionStorage.getItem('is_deleted_counter');
      if (deletedCounter === 'true') { // Tickets excluídos
        params['deleted'] = true;
        params['sort_by'] = 'deleted_at';
      } else { // Tickets finalizados
        params['sort_by'] = 'finished_at'; // deve ser 'finished_at'
      }
    } else if (props.pageType === 'history-chatbot') {
      params['order_by'] = historyChatbotOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else if (props.pageType === 'history-telephony') {
      params['order_by'] = historyTelephonyOrderByRef.current;
      // params['sort_by'] = 'updated_at'; // ou finished_at? verificar com o back
    } else {
      params['sort_by'] = ticketListSortByRef.current;
      params['order_by'] = ticketListOrderByRef.current;
    }

    if (preserveFilter) {
      const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
      params = { ...params, ...currentFilter };
    }

    getTickets(params, false, true);
  }

  const handlePopoverTicketsListItemAction = (event: any, data: ListTicketsInterface, type: 'open-ticket' | 'reopen-email-ticket' | 'delete-ticket' | 'recovery-deleted-ticket' | 'send-ticket-copy' | 'tasks-ticket') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-tickets');
    setTimeout(() => {
      if (type === 'open-ticket') {
        let routePath = `${constsRouters.routers.tickets.path}`;

        if (props.pageType === 'history-general') {
          routePath = `${constsRouters.routers.history.path}`;
        } else if (props.pageType === 'history-chatbot') {
          routePath = `${constsRouters.routers.historyChatbot.path}`;
        } else if (props.pageType === 'history-telephony') {
          routePath = `${constsRouters.routers.historyTelephony.path}`;
        } else if (props.pageType === 'consumer-tickets') {
          routePath = `${constsRouters.routers.consumerTickets.path}`;
        } else if (props.pageType === 'agent-tickets') {
          routePath = `${constsRouters.routers.agentTickets.path}`;
        } else if (props.pageType === 'task-ticket') {
          routePath = `${constsRouters.routers.taskTicket.path}`;
        } else if (props.pageType === 'messages-trigger-tickets') {
          routePath = `${constsRouters.routers.activeTriggerTickets.path}`;
        } else if (props.pageType === 'sector-tickets') {
          routePath = `${constsRouters.routers.configurationSectorsTickets.path}`;
        } else if (props.pageType === 'tag-tickets') {
          routePath = `${constsRouters.routers.configurationTagsTickets.path}`;
        }

        if (data.who_is_viewing) {
          if (user.profile === UserProfile.Owner || user.profile === UserProfile.Admin) {
            navigate(`${routePath}/${data.id}`);
          } else {
            if (props.pageType.includes('history')) {
              navigate(`${routePath}/${data.id}`);
            }
          }
        } else {
          navigate(`${routePath}/${data.id}`);
        }
      } else if (type === 'delete-ticket') {
        openModalDeleteTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'recovery-deleted-ticket') {
        openModalRecoveryDeletedTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'reopen-email-ticket') {
        openModalReopenTicket({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      } else if (type === 'send-ticket-copy') {
        openModalSendTicketCopy({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id, subject: data.subject });
      } else if (type === 'tasks-ticket') {
        openModalListTasks({ ticketId: data.id, ticketReferenceId: data.ticket_reference_id });
      }
    }, 50);
  }

  const popoverItemAction = (data: any) => {
    const isDeletedFilter = sessionStorage.getItem('is_deleted_counter');
    
    const showOpenTicket = !data.who_is_viewing || user.profile === UserProfile.Owner || user.profile === UserProfile.Admin || props.pageType.includes('history');
    const showReopenEmailTicket = (isDeletedFilter === 'false' || !isDeletedFilter) && props.pageType === 'history-general' && (data as ListTicketsInterface).channel_id === Channels.Email;
    const showDeleteTicket = !data.processing && !data.who_is_viewing && props.pageType === 'ticket-list';
    const showRecoveryDeletedTicket = isDeletedFilter === 'true' && props.pageType === 'history-general' && (data as ListTicketsInterface).channel_id === Channels.Email;
    const showSendTicketCopy = true;
    const showTasksTicket = props.pageType === 'ticket-list';

    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'open-ticket')}>
              <IconEye />{t('list_popover_actions.open_ticket')}
            </span>
          }
          { showTasksTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'tasks-ticket')}>
              <IconCheckupList />{t('list_popover_actions.task_ticket')}
            </span>
          }
          { showReopenEmailTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'reopen-email-ticket')}>
              <IconMailUp />{t('list_popover_actions.reopen_email_ticket')}
            </span>
          }
          { showRecoveryDeletedTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'recovery-deleted-ticket')}>
              <IconReceiptRefund />{t('list_popover_actions.recovery_deleted_email_ticket')}
            </span>
          }
          { showSendTicketCopy &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'send-ticket-copy')}>
              <IconMailForward />{t('list_popover_actions.copy_ticket')}
            </span>
          }
          { showDeleteTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverTicketsListItemAction(e, data, 'delete-ticket')}>
              <IconTrashX />{t('list_popover_actions.delete_ticket')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  const showDashCounters = () => {
    const pagesNotShow: PagesType[] = ['consumer-tickets', 'agent-tickets', 'task-ticket', 'messages-trigger-tickets', 'sector-tickets', 'tag-tickets'];
    return !compactList && !pagesNotShow.includes(props.pageType);
  }

  const getIsItemDisabled = (item: ListTicketsInterface, isCheck: boolean) => {
    if (item) {
      if (item.processing) {
        return true;
      } else {
        if (item.who_is_viewing) {
          if (!isCheck && (user.profile === UserProfile.Owner || user.profile === UserProfile.Admin)) {
            return false;
          } else {
            if (props.pageType.includes('history')) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  return (
    <ListOfTickets 
      t={t}
      pageType={props.pageType}
      listHeaders={getListHeaders}
      currentLoggedUserID={user.id}
      listData={data}
      paginationDetails={paginationDetails}
      getTicketsPagination={getTicketsPagination}
      isLoading={isLoading}
      errorLoading={errorLoading}
      reloadTickets={reloadTickets}
      selectItem={selectItem}
      removeSelectedItem={removeSelectedItem}
      selectAllItems={selectAllItems}
      isSelectedItem={isSelectedItem}
      selectedItemsLength={selectedItems.length}
      hasSomeSelected={hasSomeSelected}
      hasSomeSelectedCheck={hasSomeSelectedCheck}
      isAllSelectedItems={isAllSelectedItems}
      clearSelected={clearSelected}
      clearPageSelected={clearPageSelected}
      openTicket={openTicket}
      handleClickDashCounter={handleClickDashCounter}
      compactList={compactList}
      showList={showList}
      setShowList={setShowList}
      selectedItem={selectedItem}
      selectedItemId={selectedItemId}
      pageCountersRef={pageCountersRef}
      getMainHeaderHeight={getMainHeaderHeight}
      getOffsetHeight={getOffsetHeight}
      offsetHeight={offsetHeight}
      sidebarOpened={sidebarOpened}
      showModalCreateTicket={showModalCreateTicket}
      closeModalCreateTicket={closeModalCreateTicket}
      showModalDeleteTicket={showModalDeleteTicket}
      closeModalDeleteTicket={closeModalDeleteTicket}
      showModalRecoveryDeletedTicket={showModalRecoveryDeletedTicket}
      closeModalRecoveryDeletedTicket={closeModalRecoveryDeletedTicket}
      showModalReopenTicket={showModalReopenTicket}
      closeModalReopenTicket={closeModalReopenTicket}
      showModalSendTicketCopy={showModalSendTicketCopy}
      closeModalSendTicketCopy={closeModalSendTicketCopy}
      showModalListTasks={showModalListTasks}
      closeModalListTasks={closeModalListTasks}
      showModalExportAll={showModalExportAll}
      openModalExportAll={openModalExportAll}
      closeModalExportAll={closeModalExportAll}
      showModalManager={showModalManager}
      openModalManager={openModalManager}
      closeModalManager={closeModalManager}
      filters={filters}
      filterMode={filterMode}
      clearSearchTickets={clearSearchTickets}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}
      itemView={itemView}
      showDashCounters={showDashCounters}
      getIsItemDisabled={getIsItemDisabled}
    />
  );
}

export default ListOfTicketsController;