import './styles.scss';
import { FC } from 'react';
import { ModalExpiredInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';

const ModalExpired: FC<ModalExpiredInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-modal-expired-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-modal-expired-header-actions">
          <IconX onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-modal-expired-section">
          <div className="modal-modal-expired-item">
            <div className="modal-modal-expired-item-header">
              <span className="modal-modal-expired-item-label">{props.t('message')}</span>
              <span className="modal-modal-expired-item-email"> (suporte@gocxpress.com.br).</span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalExpired;
