import { IconArrowsDiagonal2, IconArrowsDiagonalMinimize, IconEdit, IconInfoCircle, IconTicket, IconUser } from '@tabler/icons-react';
import './styles.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FC } from 'react';
import { DetailsComponentInterface } from './indexModel';
import TicketInfoController from './components/ticketInfo/indexController';
import TicketEditController from './components/ticketEdit/indexController';
import ConsumerInfoController from './components/consumerInfo/indexController';
import ModalConsumerController from '../../../../homeConsumers2/components/modalConsumer/indexController';
import ModalChangeSectorConfirmationController from '../modalChangeSectorConfirmation/indexController';

const DetailsComponent: FC<DetailsComponentInterface> = (props) => {

  const detailsHideClass = props.showTicketDetails ? '' : 'ticket-details-component-hide';
  
  return (
    <>
      {props.showModalConsumer && <ModalConsumerController show={props.showModalConsumer} hide={props.closeConsumerModal} fetchDataConsumer={props?.fetchDataConsumer} currentTicket={props?.currentTicket} fetchData={props?.fetchData} />}
      {props?.showModalChangueSector && <ModalChangeSectorConfirmationController
        show={props.showModalChangueSector}
        hide={props?.handleChangeSector}
        json={props?.currentJsonSend}
        setIsLoadingEdit={props?.setIsLoadingEdit}
      />}
    <div className={`ticket-details-component ${detailsHideClass}`}>
      <div className="ticket-details-header">
        { props.showTicketDetails &&
          <span className={props.tab === 'edit' ? 'ticket-details-header-action details-header-action-active' : 'ticket-details-header-action' } 
            onClick={() => props.setTab('edit')}>
            <OverlayTrigger key='top' placement='top' overlay={<Tooltip>{props.t('edit_ticket')}</Tooltip>}>
              <IconEdit />
            </OverlayTrigger>
          </span>
        }
        {
          props.showTicketDetails &&
          <span className={props.tab === 'info' ? 'ticket-details-header-action details-header-action-active' : 'ticket-details-header-action' } onClick={() => props.setTab('info')}>
            <OverlayTrigger key='top' placement='top' overlay={<Tooltip>{props.t('ticket_information')}</Tooltip>}>
              <IconTicket />
            </OverlayTrigger>
          </span>
        }
        {
          props.showTicketDetails &&
          <span className={props.tab === 'consumer' ? 'ticket-details-header-action details-header-action-active' : 'ticket-details-header-action' } onClick={() => props.setTab('consumer')}>
            <OverlayTrigger key='top' placement='top' overlay={<Tooltip>{props.t('consumer_information')}</Tooltip>}>
              <IconUser />
            </OverlayTrigger>
          </span>
        }
        {
          props.showTicketDetails &&
          <span className="ticket-details-header-action ticket-details-show-hide" onClick={() => props.setShowTicketDetails(false)}>
            <OverlayTrigger key='top' placement='auto' overlay={<Tooltip>{props.t('hide_details')}</Tooltip>}>
              <IconArrowsDiagonalMinimize />
            </OverlayTrigger>
          </span>
        }
        {
          !props.showTicketDetails &&
          <OverlayTrigger key='top' placement='auto' overlay={<Tooltip>{props.t('show_details')}</Tooltip>}>
            <span className="ticket-details-header-action ticket-details-show-hide" onClick={() => props.setShowTicketDetails(true)}>
              <IconArrowsDiagonal2 />
            </span>
          </OverlayTrigger>
        }
      </div>
      {
        props.showTicketDetails &&
        <div className="ticket-details-section">
          {
            props.tab === 'edit' &&
            <div className="ticket-details-section-edit">
              <TicketEditController
                pageType={props.pageType} 
                handleSubmit={props.handleSubmit}
                getConsumerSelect={props.getConsumerSelect}
                getSectorSelect={props.getSectorSelect}
                getEmployeeSelect={props.getEmployeeSelect}
                getStatusSelect={props.getStatusSelect}
                getFolowersSelect={props.getFolowersSelect}
                getTagsSelect={props.getTagsSelect}
                getSubjectInput={props.getSubjectInput}
                getCustomFieldsData={props.getCustomFieldsData}
                isLoadingEdit={props.isLoadingEdit}
              />
            </div>
          }
          {
            props.tab === 'info' &&
            <div className="ticket-details-section-info">
              <TicketInfoController />
            </div>
          }
          {
            props.tab === 'consumer' &&
            <div className="ticket-details-section-consumer">
              <ConsumerInfoController
                getConsumerTickets={props.getConsumerTickets}
                pageType={props.pageType}
              />
            </div>
          }
        </div>
      }
    </div>
    </>
  );
}

export default DetailsComponent;