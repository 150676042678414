import { useState } from 'react'
import FormConfigWebchat from '.';

const FormConfigWebchatController = (props) => {

    const [showModal, setShowModal] = useState(false)

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return <FormConfigWebchat
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        data={props.data}
        updateListAfterDelete={props.updateListAfterDelete}
        cnpj={props.cnpj}
        getWebchatById={props.getWebchatById}
        isEditAllowed={props.isEditAllowed}
    />
}

export default FormConfigWebchatController