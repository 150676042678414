import React, { useEffect, useState } from "react"
import ScheduleServiceTabNew from "./indexnew"
import { useTranslation } from "react-i18next"
import UserService from "../../../../../services/user-service"
import { DataCompanyInterface, TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getIdCompany } from "../../../../../store/company"
import { getToken } from "../../../../../store/token"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { DateObject, DatePickerProps } from "react-multi-date-picker"
import { getChatbot } from "../../../../../store/chatbot"

const AppRequesterConst = new AppRequesterController();

const ScheduleServiceTabControllerNew = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken)
        }
    }

    const datePickerRef = React.createRef<DatePickerProps>()

    const [openingHours, setOpeningHours] = useState<boolean>();
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    /**
     * Dias do mês sem atendimento
     */
    const [scheduleDays, setScheduleDays] = useState<DateObject[]>([]);


    /**
     * Pega as informações digitadas 
     * nos inputs do modal de cadastro
     * de horário de atendimento
     */
    const [index, setIndex] = useState(0)
    const [dayOfWeek, setDayOfWeek] = useState('');

    const [updateForm, setUpdatedForm] = useState(false)

    /**
     * armazena os novos horários e envia para o array setSchedules 
     * quando o usuário fechar o modal
     */
    const [sendSchedulesData, setSendSchedulesData] = useState([])

    /**
     * Transferência do horário de serviço
     */
    const [transferService, setTransferService] = useState(false);

    /**
     * Pega as informações dos inputs de 
     * mensagem fora do serviço, ou messagem dentro 
     * do horário de serviço
     */
    const [messageOffService, setMessageOffService] = useState("Olá! Estamos fora do horário de atendimento. Voltaremos {proxHorario}. Quando nosso time iniciar as atividades, um dos nossos atendentes entrará em contato.");
    const [messageOnService, setMessageOnService] = useState("Olá! Nossa equipe está disponível, em breve um dos nossos atendentes entrará em contato. Por favor, aguarde.");
    const [showFeedbackMessageOffService, setShowFeedbackMessageOffService] = useState(false);
    const [showFeedbackMessageOnService, setShowFeedbackMessageOnService] = useState(false);

    /**
     * Pega as informações do checkbox de cada dia 
     * da semana (ativo ou inativo)
     */
    const [mondayCheck, setMondayCheck] = useState(false)
    const [tuesdayCheck, setTuesdayCheck] = useState(false)
    const [wednesdayCheck, setWednesdayCheck] = useState(false)
    const [thursdayCheck, setThursdayCheck] = useState(false)
    const [fridayCheck, setFridayCheck] = useState(false)
    const [saturdayCheck, setSaturdayCheck] = useState(false)
    const [sundayCheck, setSundayCheck] = useState(false)

    /**
     * Pegando as informações de cada input
     * de acordo com o dia da semana (OBS: as informações
     * dos inputs armazenadas aqui após retiradas do hook schedules)
     */
    const [monday, setMonday] = useState([])
    const [tuesday, setTuesday] = useState([])
    const [wednesday, setWednesday] = useState([])
    const [thursday, setThursday] = useState([])
    const [friday, setFriday] = useState([])
    const [saturday, setSaturday] = useState([])
    const [sunday, setSunday] = useState([])

    const hasBot = useSelector(getChatbot);

    /**
     * Abre e fecha o modal de horários
     * @returns 
     */
    const handleClose = () => {
        setShow(false)
        setIndex(0)
        setSendSchedulesData([])

        const getterDaySchedules = getGetStateDay(dayOfWeek)
        const setter = getSetStatesCheck(dayOfWeek)

        if (sendSchedulesData?.length <= 0 && getterDaySchedules?.length <= 0) {
            setter(false)
        }
    };

    /**
     * Abrir modal de horários
     * @returns 
     */
    const handleShow = () => setShow(true);

    /**
     * Realiza a requisição sempre que entrar na aba
     * de Configurações
     */
    useEffect(() => {
        const controller = new AbortController();
        getScheduleService(controller)

        return () => {
            controller.abort()
        }
    }, [])


    /**
     * Função auxiliar para mapear dias aos
     * seus respectivos setters de estado
     * @param day dia da semana
     */
    const getSetState = (day) => {
        const dayStateMap = {
            sunday: setSunday,
            monday: setMonday,
            tuesday: setTuesday,
            wednesday: setWednesday,
            thursday: setThursday,
            friday: setFriday,
            saturday: setSaturday,
        }
        return dayStateMap[day]
    }

    /**
     * Função auxiliar para pegar os dias
     * da semana dos hooks de dias da semana
     */
    const getGetStateDay = (day) => {
        const dayStateMap = {
            sunday: sunday,
            monday: monday,
            tuesday: tuesday,
            wednesday: wednesday,
            thursday: thursday,
            friday: friday,
            saturday: saturday
        }
        return dayStateMap[day]
    }

    /**
     * Função auxiliar para pegar os dias
     * habilitados 
     * @param day 
     * @returns 
     */
    const getGetStateCheckboxDay = (day) => {
        const daysStateMapChackbox = {
            sunday: sundayCheck,
            monday: mondayCheck,
            tuesday: tuesdayCheck,
            wednesday: wednesdayCheck,
            thursday: thursdayCheck,
            friday: fridayCheck,
            saturday: saturdayCheck
        }
        return daysStateMapChackbox[day]
    }

    /**
     * Função auxiliar para mapear os dias
     * que estão habilitados para horário 
     * de atendimento
     * @param day dia da semana
     * @returns 
     */
    const getSetStatesCheck = (day) => {
        const dayStateMap = {
            sunday: setSundayCheck,
            monday: setMondayCheck,
            tuesday: setTuesdayCheck,
            wednesday: setWednesdayCheck,
            thursday: setThursdayCheck,
            friday: setFridayCheck,
            saturday: setSaturdayCheck
        }
        return dayStateMap[day];
    }

    /**
     * Realiza requisição PUT de /company
     * para habilitar os horários de 
     * atendimento
     * @param event 
     */
    const reOpeningHours = (event) => {
        const newValue =  event?.target?.checked;
        setOpeningHours(newValue)
        putSchedulesDay(newValue)
    }

    /**
     * Realiza a requisição e retorna os dados da rota 
     * GET /company
     * @param controller
     */
    const getScheduleService = async (controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller?.signal

        await AppRequesterConst.Get(
            '/company', { headers, signal },
            (response: Object) => {},
            (data: DataCompanyInterface) => {
                if (data.status == 200) {
                    if (data?.data?.companies) {
                        setOpeningHours(data.data.companies[0].use_opening_hours)
                        processOpeningHours(data.data.companies[0].opening_hours != null ? data.data.companies[0].opening_hours : [])
                        processIsEnabled(data.data.companies[0].opening_hours != null ? data.data.companies[0].opening_hours : [])
    
                        if (data.data.companies[0].non_attendance_days !== null) {
                            let non_days = [];
                            data.data.companies[0].non_attendance_days.map((day) => {
                                let dateDay = new Date(day.replaceAll('Z', ""));
                                let formatedDay = new DateObject(dateDay);
                                non_days.push(formatedDay)
                            });
    
                            setScheduleDays(non_days)
                        }
    
                        setMessageOffService(data.data.companies[0].message_opening_hours);
                        setMessageOnService(data.data.companies[0].message_in_attendance)
                    }
                }

                setUpdatedForm(true);
            },
            (error: any) => {
                console.log(error.response)
            }, navigate, dispatch, setIsLoading, { values }
        )
    }

    /**
     * Requisição PUT /company
     * @param newValue 
     */
    const putSchedulesDay = async (newValue) => {
        const headers = UserService.getHeaders();
        const controller = new AbortController()

        const JsonSend = {
            "id": values.company.id,
            "use_opening_hours": newValue,
            "message_opening_hours": messageOffService,
            "message_in_attendance": messageOnService,
            "transfer_outside_opening_hours": transferService
        }

        await AppRequesterConst.Put(
            '/company', JsonSend, { headers },
            () => { },
            (data: DataCompanyInterface) => {
                if (data.status === 200) {
                    getScheduleService(controller)
                    dispatch(setShowAlertFeedback({ visibility: true, signalIcon: true, message: t('home_departments.schedule_service_tab.changes_saved') }))
                }
            },
            (error: any) => {
                getScheduleService(controller)
                dispatch(setShowAlertFeedback({ visibility: true, signalIcon: false, message: t('errors.defaultErrorMessage') }));
            }, navigate, dispatch, props.setIsLoading
        )
    }

    /**
     * Recebe o array de horário da requisição
     * GET /company e distribui o valor de 
     * is_enabled refente a cada dia
     */
    const processIsEnabled = (openingHours) => {
        openingHours.forEach((item) => {
            const setter = getSetStatesCheck(item.day) //Pega o setter correspondente ao dia
            if (setter) {
                setter(item.is_enabled)
            } 
        })
    }

    
    /**
     * Recebe o array de horário da 
     * requisição GET /company e retorna 
     * um objeto com os horários separados por 
     * dia da semana.
     * 
     * @param openingHours array de horários
     */
    const processOpeningHours = (openingHours) => {
        // Cria um objeto para armazenar os horários por dia da semana
        const scheduleByDay = {};
    
        openingHours.forEach((entry) => {
            const { day, schedules } = entry;
    
            // Verifica se o dia está habilitado e possui horários
            if (schedules?.length > 0) {
                scheduleByDay[day] = schedules.map((schedule) => {
                    return {
                        start_time: schedule.start_time,
                        end_time: schedule.end_time,
                    };
                });
            } else {
                scheduleByDay[day] = []; // Define como vazio caso o dia não esteja habilitado
            }
        });
        // setSchedulesByDay(scheduleByDay)
        updateStatesByDay(scheduleByDay)
    };

    /**
     * Vai receber o Objeto scheduleByDay e vai distribuir as informações
     * de acordo com cada dia;
     * @param schedulesByDay vai receber o objeto scheduleByDay
     */
    const updateStatesByDay = (schedulesByDay) => {
        Object.entries(schedulesByDay).forEach(([day, schedules]) => {
            const setState = getSetState(day);
            if (setState) {
                setState(schedules);
            }
        });
    };

    /**
     * Pega as informações do campo de 
     * mensagem fora do horário de atendimento
     * 
     * @param e 
     * @param handleChange 
     */
    const handleMessageOffService = (e, handleChange) => {
        setMessageOffService(e.target.value)
        handleChange(e)
    }

    /**
     * Pega as informações do campo de 
     * mensagem no horário de atendimento
     * @param e 
     * @param handleChange 
     */
    const handleMessageOnService = (e, handleChange) => {
        setMessageOnService(e.target.value);
        handleChange(e);
    }

    /**
     * Habilita a transferência de atendimento
     * humano fora do horário de atendimento
     */
    const enableTransferService = () => {
        setTransferService(true);
    }

    /**
     * Desabilita a transferência de atendimento 
     * humano fora do horário de atendimento
     */
    const disableTransferService = () => {
        setTransferService(false);
    }

    return (
        <ScheduleServiceTabNew
            openingHours={openingHours}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            t={t}
            setIndex={setIndex}
            index={index}
            setDayOfWeek={setDayOfWeek}
            dayOfWeek={dayOfWeek}
            setMondayCheck={setMondayCheck}
            setTuesdayCheck={setTuesdayCheck}
            setWednesdayCheck={setWednesdayCheck}
            setThursdayCheck={setThursdayCheck}
            setFridayCheck={setFridayCheck}
            setSaturdayCheck={setSaturdayCheck}
            setSundayCheck={setSundayCheck}
            setScheduleDays={setScheduleDays}
            scheduleDays={scheduleDays}
            datePickerRef={datePickerRef}
            hasBot={hasBot}
            transferService={transferService}
            disableTransferService={disableTransferService}
            enableTransferService={enableTransferService}
            handleMessageOffService={handleMessageOffService}
            handleMessageOnService={handleMessageOnService}
            monday={monday}
            tuesday={tuesday}
            wednesday={wednesday}
            thursday={thursday}
            friday={friday}
            saturday={saturday}
            sunday={sunday}
            mondayCheck={mondayCheck}
            tuesdayCheck={tuesdayCheck}
            wednesdayCheck={wednesdayCheck}
            thursdayCheck={thursdayCheck}
            fridayCheck={fridayCheck}
            saturdayCheck={saturdayCheck}
            sundayCheck={sundayCheck}
            values={values}
            messageOffService={messageOffService}
            messageOnService={messageOnService}
            AppRequesterConst={AppRequesterConst}
            dispatch={dispatch}
            navigate={navigate}
            props={props}
            setMessageOffService={setMessageOffService}
            setMessageOnService={setMessageOnService}
            setShowFeedbackMessageOffService={setShowFeedbackMessageOffService}
            setShowFeedbackMessageOnService={setShowFeedbackMessageOnService}
            showFeedbackMessageOffService={showFeedbackMessageOffService}
            showFeedbackMessageOnService={showFeedbackMessageOnService}
            reOpeningHours={reOpeningHours}
            updateForm={updateForm}
            getGetStateCheckboxDay={getGetStateCheckboxDay}
            getScheduleService={getScheduleService}
        />
    )
}

export default ScheduleServiceTabControllerNew