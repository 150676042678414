import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import PopupTemplateMessages from './index';
import { FetchQuickMessageInterface, FetchWhatsappMessageInterface, PopupTemplateMessagesControllerInterface, QuickMessageInterface, WhatsappMessageInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';
import { AppRequesterController } from '../../../../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../../../../services/user-service';
import { getIdCompany } from '../../../../../../../../store/company';
import { Channels } from '../../../../../../../../core/enums/channels';

const PopupTemplateMessagesController: FC<PopupTemplateMessagesControllerInterface> = (props) => {
  const { t } = useTranslation('PopupTemplateMessages');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AppRequesterConst = new AppRequesterController();
  
  const { currentTicket } = useViewTicketContext();
  const companyId = useSelector(getIdCompany);

  const popupRef = useRef<HTMLDivElement>(null);
  const popupBodyRef = useRef<HTMLDivElement>(null);

  const [isLoadingQuickMessage, setIsLoadingQuickMessage] = useState(false);
  const [isLoadingWhatsappMessage, setIsLoadingWhatsappMessage] = useState(false);
  const [activeTab, setActiveTab] = useState<'quick'|'whatsapp'>('quick');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [quickMessages, setQuickMessages] = useState([]);
  const [whatsappMessages, setWhatsappMessages] = useState([]);
  const [auxTemplateMessage, setAuxTemplateMessage] = useState('');
  const [generalTypesVariables, setGeneralTypesVariables] = useState([]);
  const [showModalGeneralType, setShowModalGeneralType] = useState(false);
  
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef?.current && !popupRef?.current?.contains(event.target)) {
        props.setShowPopupTemplateMessages(false);
        setIsFiltered(false);
        setSearchTerm('');
      }
    }

    if (props.showPopupTemplateMessages) {
      if (popupRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [props.showPopupTemplateMessages]);

  // useEffect(() => {
  //   if (popupBodyRef?.current) {
  //     console.log('body carregado')
  //     const checkScrollbar = () => {
  //       console.log('scroll')
  //       if (popupRef?.current?.scrollHeight > popupBodyRef?.current?.clientHeight) {
  //         setHasScroll(true);
  //       } else {
  //         setHasScroll(false);
  //       }
  //     }

  //     popupBodyRef.current?.addEventListener('scroll', checkScrollbar);
  //   }
  // }, [popupBodyRef?.current]);

  useEffect(() => {
    if (props.showPopupTemplateMessages) {
      if (props.quickMessagesAllowed) {
        setActiveTab('quick');
        fetchQuickMessages();
      } 
      
      if (props.whatsappMessagesAllowed) {
        setActiveTab('whatsapp');
        fetchWhatsappMessages();
      }
    } else {
      setTimeout(() => {
        setQuickMessages([]);
        setWhatsappMessages([]);
      }, 300);
    }
  }, [props.showPopupTemplateMessages]);

  const handleShowPopup = () => {
    props.setShowPopupTemplateMessages(!props.showPopupTemplateMessages);
  }

  const handleChangeTab = (tab: 'quick' | 'whatsapp') => {
    setSearchTerm('');
    setIsFiltered(false);
    setActiveTab(tab);
  }

  const fetchQuickMessages = async (search?: string) => {
    const headers = UserService.getHeaders();
    const params: { ticketId?: string, search?: string } = { };

    if (currentTicket) {
      params['ticketId'] = currentTicket.id;
    }

    if (search) {
      params['search'] = search || '';
    }

    const config = { headers, params };
    
    await AppRequesterConst.Get(
      '/template-message/quick-message',
      config,
      (response: any) => {},
      (response: FetchQuickMessageInterface) => {
        if (response.status === 200 && response.data.quickMessages.length > 0) {
          setQuickMessages(response.data.quickMessages || []);
        }
        if (response.status === 204) {
          setQuickMessages([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
                return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingQuickMessage, { }
    );
  }

  const fetchWhatsappMessages = async (search?: string) => {
    const headers = UserService.getHeaders();
    const params: { ticketId?: string, search?: string } = { };

    if (currentTicket) {
      params['ticketId'] = currentTicket.id;
    }

    if (currentTicket?.whatsapp_keys_id) {
      params['whatsAppKeysId'] = currentTicket.whatsapp_keys_id;
    }

    if (search) {
      params['search'] = search || '';
    }

    const config = { headers, params };
    
    await AppRequesterConst.Get(
      `/template-message/whatsapp/${companyId}`,
      config,
      (response: any) => {},
      (response: FetchWhatsappMessageInterface) => {
        if (response.status === 200 && response.data.templateMessages.length > 0) {
          setWhatsappMessages(response.data.templateMessages || []);
        }
        if (response.status === 204) {
          setWhatsappMessages([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
                return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingWhatsappMessage, { }
    );
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isLoadingQuickMessage && !isLoadingWhatsappMessage) {
      const newSearchTerm = event.target.value;
  
      setSearchTerm(event.target.value);
  
      if (newSearchTerm) {
        if (searchTimeout) {
          clearTimeout(searchTimeout)
          setSearchTimeout(null);
        }
    
        const newSearchTimeout = setTimeout(() => {
          if (activeTab === 'quick') {
            fetchQuickMessages(newSearchTerm);
          } else {
            fetchWhatsappMessages(newSearchTerm);
          }
          setIsFiltered(true);
        }, 1500);
    
        setSearchTimeout(newSearchTimeout);
      } else {
        setIsFiltered(false);
        if (activeTab === 'quick') {
          fetchQuickMessages();
        } else {
          fetchWhatsappMessages();
        }
      }
    }
  }

  const handleEnterSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!isLoadingQuickMessage && !isLoadingWhatsappMessage) {
      if (event.key === 'Enter') {
        if (searchTerm) {
          clearTimeout(searchTimeout);
          setSearchTimeout(null);
          setSearchTerm(searchTerm);
          setIsFiltered(true);

          if (activeTab === 'quick') {
            fetchQuickMessages(searchTerm);
          } else {
            fetchWhatsappMessages(searchTerm);
          }
        }
      }
    }
  }

  const clearSearch = () => {
    setSearchTerm('');
    if (activeTab === 'quick') {
      fetchQuickMessages();
    } else {
      fetchWhatsappMessages();
    }
    setIsFiltered(false);
  }

  const getFormattedDate = (created_at: string, seconds?: boolean) => {
    const date = new Date(created_at);
    const formatted = date.toLocaleDateString() + ' às ' + date.toLocaleTimeString('pt-BR').slice(0, seconds ? 8 : 5);
    return formatted;
  }

  const handleQuickMessageClick = (item: QuickMessageInterface) => {
    // Executar função que preenche o chatEditor
    if (item) {
      if (item.channel_id === Channels.Email) {
        item.message = item.message.replace(/\\n/g, '\n').replace(/\n/g, '<br>\n')
      }
      item.message = replaceQuickMessagesVariables(item.message);
      props.handleClickTemplateMessage(item, null);
      handleShowPopup();
      setIsFiltered(false);
      setSearchTerm('');
    }
  }

  const handleWhatsappMessageClick = (item: WhatsappMessageInterface) => {
    // Função que verifica se a mensagem possui variáveis
    checkWhatsappMessageVariables(item);
  }

  const handleClearSelected = () => {
    props.handleClearTemplateMessage();
    setIsFiltered(false);
    setSearchTerm('');
  }

  const replaceQuickMessagesVariables = (message: string): string => {
    if (message) {
      const regexConsumer = /\{(consumer|consumidor|consommateur)\}/;
      const regexTicket = /\{ticket\}/;
      let newContent = message;

      if (regexConsumer.test(message) && props.consumer) {
        newContent = newContent.replace(new RegExp(regexConsumer.source, 'gi'), props.consumer.name);
      }

      if (regexTicket.test(message) && currentTicket?.ticket_reference_id && props.type === 'view-ticket') {
        newContent = newContent.replace(new RegExp(regexTicket.source, 'gi'), currentTicket.ticket_reference_id);
      }

      return newContent;
    } else {
      return message;
    }
  }

  const handleOpenCloseModalGeneralType = () => {
    setShowModalGeneralType(!showModalGeneralType);
  }

  const checkGeneralTypeTag = (tags: any) => {
    return tags?.filter(item => {
      if (item.tag === 'general.type') {
        item['tag_value'] = '';
        return item
      }
    }) || [];
  }

  const checkWhatsappMessageVariables = (item: WhatsappMessageInterface) => {
    if(item.message) {
      const variables = checkGeneralTypeTag(item.tags);
      if (variables.length === 0) {
        replaceWhatsappMessageWithoutVariables(item);
      } else {
        replaceWhatsappMessageWithVariables(item, variables);
      }
    }
  }

  const replaceWhatsappMessageWithoutVariables = (item: WhatsappMessageInterface) => {
    // Executar função que preenche o chatEditor
    props.handleClickTemplateMessage(null, item);
    handleShowPopup();
    setIsFiltered(false);
    setSearchTerm('');
  }

  const replaceWhatsappMessageWithVariables = (item: WhatsappMessageInterface, variables: any) => {
    setAuxTemplateMessage(item.message)
    setGeneralTypesVariables(variables);
    handleOpenCloseModalGeneralType();

    const interval = setInterval(() => {
      setShowModalGeneralType((show) => {
      if (show === false) {
        clearInterval(interval);
        setAuxTemplateMessage(aux => {
          item.message = aux;
          return aux
        })
        replaceWhatsappMessageWithoutVariables(item);
      }
      return show;
      });
    }, 1000);
  }

  return (
    <PopupTemplateMessages 
      t={t}
      popupRef={popupRef}
      popupBodyRef={popupBodyRef}
      showPopup={props.showPopupTemplateMessages}
      handleShowPopup={handleShowPopup}
      isLoading={isLoadingQuickMessage || isLoadingWhatsappMessage}
      activeTab={activeTab}
      quickMessages={quickMessages}
      whatsappMessages={whatsappMessages}
      handleChangeTab={handleChangeTab}
      quickMessagesAllowed={props.quickMessagesAllowed}
      whatsappMessagesAllowed={props.whatsappMessagesAllowed}
      handleInputChange={handleInputChange}
      handleEnterSearch={handleEnterSearch}
      searchTerm={searchTerm}
      getFormattedDate={getFormattedDate}
      isFiltered={isFiltered}
      clearSearch={clearSearch}
      handleQuickMessageClick={handleQuickMessageClick}
      handleWhatsappMessageClick={handleWhatsappMessageClick}
      selectedTemplateMessage={props.selectedTemplateMessage}
      handleClearSelected={handleClearSelected}
      showModalGeneralType={showModalGeneralType}
      handleOpenCloseModalGeneralType={handleOpenCloseModalGeneralType}
      generalTypesVariables={generalTypesVariables}
      setAuxTemplateMessage={setAuxTemplateMessage}
      auxTemplateMessage={auxTemplateMessage}
    />
  );
};

export default PopupTemplateMessagesController;
