import './styles.scss';
import { FC } from 'react';
import { Button, Col, Dropdown, Form, FormControl, InputGroup, ProgressBar, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import SelectComponentController from '../../homeApp/components/selectComponent/indexController';
import PhoneInput from 'react-phone-input-2';
import InputMask from 'react-input-mask';
import pt from 'react-phone-input-2/lang/pt.json'
import MaskController from '../../../core/shared/mask-controller';
import { RegisterFormInterface } from './indexModel';
import { IconCheck, IconX } from '@tabler/icons-react';

const RegisterForm: FC<RegisterFormInterface> = (props) => {
  return (
    <Col className="register-form-component">
      <Row style={{ height: '5.5vh' }} className={"d-flex justify-content-center " + (props.isMobile ? 'mb-3' : 'mb-4')} >
        {props.stage !== 4 ?
          <>
            <Row className="d-flex justify-content-center ps-0" style={{ height: '2vh', paddingTop: '1vh', }}>
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={"register-form-component-textBlack" + " ps-0"}
                style={props.isMobile ? { font: "var(--px20_24_600-font)" } : {}}
              >
                <p className={props.isMobile ? "fontStepMobile" : ""}>{props.t("step.label_step")} {props.stage} {props.t("step.label_of_3")}</p>
              </Col>
            </Row>
            <Row className={"d-flex justify-content-center ps-0 " + (props.isMobile ? "pt-1" : "")} style={{ marginBottom: "-1.5vh" }}>
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className="ps-0">
                <ProgressBar now={props.progress} style={{ height: '1vh', borderRadius: '0px' }} />
              </Col>
            </Row>
          </> :
          <>
            <Row className="d-flex justify-content-center" style={{ height: '4vh', paddingTop: '1vh' }}>
            </Row>
          </>
        }
      </Row>
      <Formik
        initialValues={{ email: ''}}
        validate={values => {    
          props.setUser_email(values.email);
          return props.ValidateEmailForm(values)
        }}      

        onSubmit={(values, errors) => {          
          props.clickNextStage();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={props.validated} id="stage1"
          //style={{display: 'none'}}
          >
            <Row className="d-flex justify-content-center align-self-start">
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '33%' */ }} >
                <Row className={"register-form-component-textFontBlue" + " mt-3 " + (props.isMobile ? "mx-0 mb-0" : 'mx-2 pt-3 mb-3')}>
                  <Col className={props.isMobile ? "px-0 " + "register-form-component-textHeaderMobile" : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{props.t("register_label")}</p></Col>
                </Row>
                <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")}>

                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{props.t("country_selected")}</Form.Label>
                    <SelectComponentController
                      className={"multiselectForm mb-4"}
                      options={props.options_country}
                      setOptions={props.setOptions_country}
                      selecteds={props.selected_country}
                      setSelecteds={props.setSelected_country}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={props.t("country_selected")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.selected_country[0] ? props.selected_country[0].label : "",
                          "clearSearch": props.t("overrideStrings.clearSearch"),
                          "clearSelected": props.t("overrideStrings.clearSelected"),
                          "noOptions": props.t("overrideStrings.noOptions"),
                          "search": props.t("overrideStrings.search"),
                          "selectAll": props.t("overrideStrings.selectAll"),
                          "selectAllFiltered": props.t("overrideStrings.selectAllFiltered"),
                          "selectSomeItems": props.t("overrideStrings.selectSomeItems"),
                          "create": props.t("overrideStrings.create"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                  </Col>

                  <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="email">{props.t("label_comercial_email")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                  <InputGroup className={props.isMobile ? "px-0" : ""} style={{ marginBottom: (props.isMobile ? '0' : "0"), }}>
                    <FormControl
                      style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.showfeedbackEmail ? "0rem" : '1rem') }}
                      isInvalid={props.showfeedbackEmail}
                      className="form-control-Default"
                      placeholder={props.t("placeholder_email")}
                      aria-label={props.t("placeholder_email")}
                      aria-describedby={props.t("placeholder_email")}
                      size='lg'
                      id="user_email"
                      name="email"
                      autoComplete='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <Form.Control.Feedback type="invalid" id="feedbackTextEmail" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Row>
                <Row className={"mb-4 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '0.6vh' }}>
                  <div style={{ width: '100%', textDecoration: 'none' }} className={"justify-content-center " + (props.isMobile ? "px-0" : '')}>
                    <Button type='submit' className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"} style={{ height: '5vh' }} disabled={props.validateCountry() === false && values.email !== '' ? false : true}>{props.t("button_next_register")}</Button>
                  </div>
                </Row>
              </Col>
            </Row>
            <div /* style={(!props.isMobile)? {display: 'none'}: {}} */>
              <Row className="d-flex justify-content-center ps-0">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={"register-form-component-textFontGlayMobile" + " ps-0 " + (props.isMobile ? "register-form-component-paddingLarger" : "")}>
                  <p
                    style={{
                      textAlign: 'left',
                      font: props.isMobile ? "" : 'normal normal normal 12px/20px Roboto',
                      letterSpacing: '0px',
                      color: '#1E1E1E',
                      marginTop: '0.8vh'
                    }}
                  >
                    {props.t("label_warning_register")}
                  </p>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{ name: '', lastname: '', phone: ''}}
        validate={values => {
          props.setUser_name(values.name);
          props.setUser_lastname(values.lastname);
          //Setuser_whatsapp(values.whatsapp);
          return props.ValidateRegisterForm(values);
        }}


        onSubmit={async (values, errors) => {
          props.clickNextStage();
        }}
      >
        {({
          values,
          setValues,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={props.validated} id="stage2"
            style={{ display: 'none' }}
          >
            <div >
              <Row className="d-flex justify-content-center">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? "register-form-component-paddingLarger" : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '20.8%' */ }} >
                  <Row className={"register-form-component-textFontBlue" + " mt-3 " + (props.isMobile ? "mx-0 mb-1" : 'mx-2 pt-3 mb-3')}>
                    <Col className={props.isMobile ? "px-0 " + "register-form-component-textHeaderMobile" : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{props.t("register_label")}</p></Col>
                  </Row>
                  <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")}>
                    <Col className={props.isMobile ? "px-0" : ""} style={{ paddingRight: "0px" }}>
                      <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="name">{props.t("label_name_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup className={props.isMobile ? "px-0" : ""} style={{ marginBottom: "0vh" }}>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.showfeedbackName ? "0rem" : '1rem') }}
                          isInvalid={props.showfeedbackName}
                          className="form-control-Default"
                          placeholder={props.t("placeholder_name_register")}
                          aria-label={props.t("placeholder_name_register")}
                          aria-describedby={props.t("placeholder_name_register")}
                          id="user_name"
                          name="name"
                          autoComplete='given-name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextName" style={{ marginBottom: '2vh',font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col className={props.isMobile ? "pe-0" : ""}>
                      <Form.Label htmlFor="lastname">{props.t("label_surname_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup style={{ marginBottom: "0vh", font: (props.isMobile ? 'var(--px15_19-font)' : "") }}>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.showfeedbackLastname ? "0rem" : '1rem') }}
                          className="form-control-Default"
                          placeholder={props.t("placeholder_surname_register")}
                          aria-label={props.t("placeholder_surname_register")}
                          aria-describedby={props.t("placeholder_surname_register")}
                          id="user_lastname"
                          name="lastname"
                          autoComplete='family-name'
                          isInvalid={props.showfeedbackLastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastname}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextLastName" style={{ marginBottom: '2vh',font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.lastname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")}>
                    <Col className={props.isMobile ? "px-0" : "pt-1"}>
                      <Form.Label htmlFor="basic-url">{props.t("label_whatsapp_number_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      {/* <PhoneInputDropdown type={PhoneType.WhatsApp} phoneNumber={values.whatsapp} values={values} setValues={setValues} tag={whatsappTag} setPhoneError={setWhatsappError} required={true} isMobile={props.isMobile} /> */}
                      <PhoneInput
                      placeholder={"Informe o telefone"}
                      value={values.phone}
                      onChange={(e) => props.setAgent_phone(e)}
                      inputProps={{
                        name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: {
                          width: '100%',
                          font: 'var(--px15_19-font-Roboto)',
                          marginBottom: '1rem',
                          height: '3.4em'
                        }
                      }}
                      country={props.switchLanguage()[0]}
                      onlyCountries={['br','pt', 'es', 'fr', 'gb']}
                      disableDropdown={true}
                      localization={pt}
                      masks={{ pt: '... ... ...' }}
                      countryCodeEditable={false}
                    />
                    </Col>
                  </Row>
                  <Row className={"mb-4 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '1vh' }} >
                    <Col className={"justify-content-center " + (props.isMobile ? "ps-0" : '')} style={{ paddingRight: "0px" }}>
                      <Button className={props.isMobile ? "buttonWhiteRegisterMobile" : "buttonWhite"} onClick={() => {props.clickPreviousStage(); props.setDocument_number("");props.setAgent_phone("");}}>{props.t("button_back_register")}</Button>
                    </Col>
                    <Col className={"justify-content-center " + (props.isMobile ? "pe-0" : '')}>
                      <Button type='submit' disabled={props.validatePhone()} className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"}>{props.t("button_next_register")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{ company_name: '', password: '', document_number: '' }}
        validate={values => {
          props.setCompany_name(values.company_name);
          props.setUser_password(values.password);
          props.setDocument_number(values.document_number);
          return props.ValidateCompanyForm(values)

        }}

        onSubmit={props.submit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={props.validated} id="stage3"
            style={{ display: 'none' }}
          >
            <div >
              <Row className=" d-flex justify-content-center ">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? "register-form-component-paddingLarger" : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '8.5%' */ }} >
                  <Row className={"register-form-component-textFontBlue" + " mt-3 " + (props.isMobile ? "mx-0" : 'mx-2 pt-3 mb-3')}>
                    <Col className={props.isMobile ? "px-0 " + "register-form-component-textHeaderMobile" : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}>
                      <p>{props.t("register_label")}</p>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""} style={{ paddingRight: "0px" }}>
                      <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="company_name">{props.t("label_name_company")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup className={props.isMobile ? "px-0" : ""}  /* style={{ marginBottom: "2vh", height: '7vh' }} */>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.showfeedbackCompany_name ? "0rem" : '0.75rem'), paddingRight: '2px' }}
                          className="form-control-Default"
                          placeholder={props.t("label_name_company")}
                          aria-label="Nome da empresa"
                          aria-describedby="Nome da empresa"
                          id="company_name"
                          name="company_name"
                          autoComplete="organization"
                          isInvalid={props.showfeedbackCompany_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextCompanyName" style={{ marginBottom: '2vh', font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '1.5rem' }}>
                          {errors.company_name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col className={props.isMobile ? "pe-0" : ""}>
                      <Form.Label htmlFor="basic-url">{props.t("label_number_employees")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup>
                        <Dropdown onSelect={(value) => props.OnSelectDropdownCompanyEmployeeNumber(value)} onBlur={handleBlur}>
                          <Dropdown.Toggle id="company_employee_number" /* style={props.isMobile? {font: "var(--px10_13-font)"}: {}} */ className={(props.isMobile ? "dropdownButtonRegisterMobile " + "register-form-component-textFontGlayMobile" : "dropdownButton")}>
                            {props.t("select_dropdown")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu >
                            <Dropdown.Item eventKey={props.t("employees_numbers.1_10")}>{props.t("employees_numbers.1_10")}</Dropdown.Item>
                            <Dropdown.Item eventKey={props.t("employees_numbers.11_50")}>{props.t("employees_numbers.11_50")}</Dropdown.Item>
                            <Dropdown.Item eventKey={props.t("employees_numbers.51_100")} >{props.t("employees_numbers.51_100")}</Dropdown.Item>
                            <Dropdown.Item eventKey={props.t("employees_numbers.101_250")} >{props.t("employees_numbers.101_250")}</Dropdown.Item>
                            <Dropdown.Item eventKey={props.t("employees_numbers.more_than_250")} >{props.t("employees_numbers.more_than_250_employees")}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control.Feedback type="invalid" id="feedbackCompany_employee_number" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {props.t("errors.select_number_employees")}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""}>
                      <Form.Label htmlFor="basic-url">
                        <div>
                          {props.t(props.switchDocument())} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span>
                        </div>
                      </Form.Label>
                      <InputMask
                        mask={MaskController.maskCpfCnpj(values.document_number, props.selected_country[0]?.value)}
                        maskChar="_"
                        value={values.document_number}
                        onChange={handleChange}
                      >
                        {(inputProps: any) => <InputGroup style={{ /* marginBottom: "2vh" *//* , height: '7vh' */ }}>
                          <FormControl
                            style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.ShowfeedbackDocument_number ? "0rem" : '1rem') }}
                            className="form-control-Default"
                            // placeholder={`Informe o ${t('switchDocument()')} da empresa`}
                            // aria-label={`Informe o ${t('switchDocument()')} da empresa`}
                            // aria-describedby={`Informe o ${t('switchDocument()')} da empresa`}
                            size='lg'
                            id="document_number"
                            name="document_number"
                            isInvalid={props.ShowfeedbackDocument_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid" id="feedbackCNPJ" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                            {errors.document_number}
                          </Form.Control.Feedback>
                        </InputGroup>
                        }
                      </InputMask>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""}>
                      <Form.Label htmlFor="basic-url">{props.t("label_password")}</Form.Label>
                      <InputGroup /* style={{ marginBottom: "1.5vh", height: '7vh' }} */>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (props.showfeedbackPassword ? "0rem" : '1rem') }}
                          className="form-control-Default"
                          placeholder={props.t("placeholder_password")}
                          aria-label="Informe sua senha"
                          aria-describedby="Informe sua senha"
                          size='lg'
                          type='password'
                          id="user_password"
                          name="password"
                          isInvalid={props.showfeedbackPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <div className="d-flex justify-content-end align-self-start">
                          <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', zIndex: "5" }} onClick={() => props.ClickToViewPassword()}>
                            {props.showPassword ?
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="2" />
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                              </svg> :
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="3" y1="3" x2="21" y2="21" />
                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                              </svg>
                            }
                          </Button>
                        </div>
                        {/* <Form.Control.Feedback type="invalid" id="feedbackTextPassword" style={{ marginBottom: '2vh', font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.password}
                        </Form.Control.Feedback> */}
                      </InputGroup>
                    </Col>
                    {Array.isArray(props.validatePassword.password) && props.validatePassword.password.map((error: any, index) => {
                      if (error?.valid) {
                        return <div className="register-form-component-valid-property" key={index}><IconCheck /> {error.label}</div>
                      } else {
                        return <div className="register-form-component-invalid-property" key={index}><IconX /> {error.label}</div>
                      }
                    })}
                  </Row>
                  <Row className={"mb-3 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '0.8vh', position: 'relative', zIndex: '1000' }} >
                    <Col className={"justify-content-center " + (props.isMobile ? "ps-0" : '')} style={{ paddingRight: "0px" }}>
                      <Button className={props.isMobile ? "buttonWhiteRegisterMobile" : "buttonWhite"} onClick={() => props.clickPreviousStage()}>{props.t("button_back_register")}</Button>
                    </Col>
                    <Col className={"justify-content-center " + (props.isMobile ? "pe-0" : '')}>
                      <Button type="submit" className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"} disabled={values.password && values.document_number && values.company_name ? false : true}>{props.t("button_finish_register")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <div id="stage4"
        style={{ display: 'none' }}
      >
        <Row /* style={{ height: '36%' }} */ className=" d-flex justify-content-center">
          <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? "register-form-component-paddingLarger" : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '30.5%' */ }} >
            <Row className={"register-form-component-textFontBlue " + " mt-3 mb-3 " + (props.isMobile ? "mx-0" : 'mx-2 pt-3')}>
              <Col className={props.isMobile ? ("px-0 " + "register-form-component-textHeaderMobile") : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{props.t("last_step.verify_email")}</p></Col>
            </Row>
            <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")}>
              <Col className={props.isMobile ? "px-0" : ""}>
                <p style={{ color: '#1E1E1E', wordBreak: 'break-word', msWordBreak: 'break-word' }}>{props.t("last_step.after_verify")}
                  <a className={props.isMobile ? "register-form-component-textBlackMobile" : "register-form-component-textBlack"} href={("http://" + props.subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")}> {("http://" + props.subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")} </a>
                  {props.t("last_step.start_using_platform")}</p>
              </Col>
            </Row>
            <Row className={props.isMobile ? ("register-form-component-textFontGlayMobile" + " mx-0") : ("register-form-component-textFontGlay" + " mx-2")}>
              <Col className={props.isMobile ? "px-0" : ""}>
                <p className="my-3" style={{ color: '#1E1E1E' }}>{props.t("last_step.didnt_receive_email")} &nbsp;
                  <span className={props.isMobile ? "register-form-component-textBlackBoldMobile" : "register-form-component-textBlackBold "} style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={props.resendEmailConfirmation}>{props.t("last_step.resend_email")}</span>
                </p>
              </Col>
            </Row>
            <Row className={"mb-3 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} >
              <Col className={"justify-content-center " + (props.isMobile ? "px-0" : '')}>
                <div className="d-flex justify-content-center">
                  <a style={{ width: '100%', height: '100%', textDecoration: 'none' }} href={("http://" + props.subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")}>
                    <Button className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"}>{props.t("last_step.signin_cx")} </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default RegisterForm;
