
import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Loading from '../../../../../components/loading'

import allStyles from "../styles"
import { IconMessages } from '@tabler/icons-react';

const ListConfigWebchat = ({
    props,
    isLoading,
    popover,
    getAccountById,
    data,
    showModalEdit,
    handleCloseModalEdit,
    openPopover,
    infos,
    getFacebookById,
    isEditAllowed,
}) => {
    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item style={allStyles.TextRowCollums}>
                        <Row name="ListOfAgentsHead">
                            <Col md={3} className="nopadding">
                                <p>Nome do Bot</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                Data da criação
                            </Col>
                            <Col className='justify-content-end d-flex'>
                                {isEditAllowed && <p>Ações</p>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    infos?.length !== 0 ?
                        <div id="ListOfAgents" style={{ overflowY: 'auto', height: '56vh' }}>
                            {
                                <ListGroup variant="flush" className="TicketsRow">
                                    {infos.map((item, index) => {
                                            return (
                                                <ListGroup.Item key={index} style={{ color: '#707070' }}>
                                                    <Row className='TicketsRowHeight'>
                                                        <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.bot_cxpress?.bot_name != null ?
                                                                                <>{item?.bot_cxpress?.bot_name}</>
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.bot_cxpress?.bot_name}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }}>
                                                            <div>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {new Date(item?.created_at).toLocaleDateString()} {/* Formata a data no formato de data normal */}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {new Date(item?.created_at).toLocaleDateString()} {/* Formata a data no formato de data normal */}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col className='d-flex justify-content-end' style={{ marginLeft: '2.3rem' }} >
                                                            {
                                                                isEditAllowed &&
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                        <Button onClick={() => openPopover(item?.id, item?.bot_cxpress?.bot_name)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        })}
                                </ListGroup>
                            }
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <IconMessages size={80} color="#4a69bd" />
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '12%', backgroundColor: '#F9F9F9' }}>
                                <Col md={6}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>Sem resultados, adicione um Webchat</p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </>
    );

}

export default ListConfigWebchat;