import './styles.scss';
import { FC } from 'react';
import { ExpiredTokenOpenAiBarInterface } from './indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalExpiredController from './modalExpired/indexController';

const ExpiredTokenOpenAiBar: FC<ExpiredTokenOpenAiBarInterface> = (props) => {
  return (
    <>
      <ModalExpiredController
        show={props?.showModalExpired}
        hide={props?.setShowModalExpired}
      />
      <div className="expired-token-open-ai-bar-component" onClick={() => props.setShowModalExpired(true)}>
        <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('tooltip')}</Tooltip>}>
          <span className="expired-token-open-ai-bar-message">
            {props.t('limit')} <span> {props.t('more')}</span>
          </span>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default ExpiredTokenOpenAiBar;
