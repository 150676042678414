import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent } from 'react';
import NotificationsComponent from '.';
import { useMainAppContext } from '../../../../core/context/main-app-context'; 
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import UserService from '../../../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MessageNotificationAttributes, NotificationFetchInterface, NotificationInterface } from './indexModel';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationConfigType, StatusNotification, TriggerType } from '../../../../core/enums/status-notification';
import constsRouters from '../../../../routes/constsRouter';
import { getSoundNotification, getVisualNotification, setShowAlertFeedback, setSoundNotification, setVisualNotification } from '../../../../store/internal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSocketV2Context } from '../../../../core/context/socket-context-v2';
import { getIdUser } from '../../../../store/user';
import i18n from '../../../../i18next';
import { SocketDataNewConsumerMessageInterface, TicketInterface } from '../../homeTickets2/viewTicket/indexModel';
import Logo from './assets/NotificationLogo.png';
import emitter from '../../../../core/shared/emitter';
import { getIdEmployee } from '../../../../store/employee';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import { useViewTicketContext } from '../../../../core/context/view-ticket-context';

const AppRequesterConst = new AppRequesterController();

const LIMIT_ITEMS_PAGINATION = 20;

const NotificationsComponentController = () => {
  const { t } = useTranslation('NotificationComponent');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { actionEvent, emitActionEvent } = useMainAppContext();
  const { socketInitialized, registerSocketAppEvent, unregisterSocketAppEvent } = useSocketV2Context();
  const { currentTicket } = useViewTicketContext();

  const user_id = useSelector(getIdUser);
  const notificationSoundOrigin = useSelector(getSoundNotification);
  const notificationVisualOrigin = useSelector(getVisualNotification);
  const employeeId = useSelector(getIdEmployee);

  const popupRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  
  const [notificationsList, setNotificationsList] = useState<NotificationInterface[]>([]);
  const [notificationsListPage, setNotificationsListPage] = useState(1);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(false);
  const [isLoadingNotificationsList, setIsLoadingNotificationsList] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [notificationFileSound, setNotificationFileSound] = useState<HTMLAudioElement>(new Audio());
  const [itemDeleteConfirmation, setItemDeleteConfirmation] = useState<string>();
  const [showMarkAllConfirm, setShowMarkAllConfirm] = useState(false);
  
  // Configuration states
  const [expireDays, setExpireDays] = useState(60);
  const [hasSoundGeneralNotify, setHasSoundGeneralNotify] = useState(true);
  const [soundGeneralNotifyTemp, setSoundGeneralNotifyTemp] = useState(true);

  const [hasSoundTicketNotification, setHasSoundTicketNotification] = useState(true);
  const [soundTicketNotificationTemp, setSoundTicketNotificationTemp] = useState(true);
  const [soundTicketNotificationType, setSoundTicketNotificationType] = useState<'new-ticket' | 'new-message'>('new-ticket');

  const [hasVisualTicketNotification, setHasVisualTicketNotification] = useState(true);
  const [visualTicketNotificationTemp, setVisualTicketNotificationTemp] = useState(true);
  const [visualTicketNotificationType, setVisualTicketNotificationType] = useState<'new-ticket' | 'new-message'>('new-ticket');

  const [notificationPermission, setNotificationPermission] = useState<'granted' | 'default' | 'denied'>('granted');
  const [hasNotificationPermission, setHasNotificationPermission] = useState(true);
  const [checkPermission, setCheckPermission] = useState(false);
  const [checkInterval, setCheckInterval] = useState<NodeJS.Timeout>(null);

  const [showModalTask, setShowModalTask] = useState(false);

  useEffect(() => {
    if ('Notification' in window) {
      setHasNotificationPermission(true);
    } else {
      setHasNotificationPermission(false);
    }
    setNotificationPermission(Notification.permission);
  }, [Notification?.permission]);

  useEffect(() => {
    if (checkPermission) {
      let count = 0;
      const interval = setInterval(() => {
        count += 1;
        if (Notification.permission !== notificationPermission || count === 10) {
          setNotificationPermission(Notification.permission);
          clearInterval(interval);
          clearInterval(checkInterval);
          setCheckInterval(null);
          setCheckPermission(false);
        }
      }, 1000);
      setCheckInterval(interval);
    }
  }, [checkPermission]);

  useEffect(() => {
    if (showPopup) {
      const handleClickOutside = (event: any) => {
        if (popupRef?.current && !popupRef?.current?.contains(event.target)) {
          setShowPopup(false);
        }
      }
  
      if (popupRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showPopup]);

  useEffect(() => {
    const notificationSound = new Audio(process.env.PUBLIC_URL + '/audios/notification_3.mp3');
    notificationSound.load();

    setNotificationFileSound(notificationSound);
    setItemDeleteConfirmation(null);
    setShowMarkAllConfirm(false);

    const storageHasSoundGeneralNotify = localStorage.getItem('cx_sound_general_notify');
    const storageSoundTicketNotify = localStorage.getItem('cx_sound_ticket_notify');
    const storageVisualTicketNotify = localStorage.getItem('cx_visual_ticket_notify');

    // Som de notificações gerais (Tarefas, seguidor, etc)
    if (!storageHasSoundGeneralNotify) {
      localStorage.setItem('cx_sound_general_notify', 'true');
      setHasSoundGeneralNotify(true);
      setSoundGeneralNotifyTemp(true);
    } else {
      setHasSoundGeneralNotify(storageHasSoundGeneralNotify === 'true');
      setSoundGeneralNotifyTemp(storageHasSoundGeneralNotify === 'true');
    }

    // Som de notificações de tickets
    if (!storageSoundTicketNotify) {
      localStorage.setItem('cx_sound_ticket_notify', 'true');
      setHasSoundTicketNotification(true);
      setSoundTicketNotificationTemp(true);
    } else {
      setHasSoundTicketNotification(storageSoundTicketNotify === 'true');
      setSoundTicketNotificationTemp(storageSoundTicketNotify === 'true');
    }

    // Notificações visuais
    if (!storageVisualTicketNotify) {
      localStorage.setItem('cx_visual_ticket_notify', 'true');
      setHasVisualTicketNotification(true);
      setVisualTicketNotificationTemp(true);
    } else {
      setHasVisualTicketNotification(storageVisualTicketNotify === 'true');
      setVisualTicketNotificationTemp(storageVisualTicketNotify === 'true');
    }

    setSoundTicketNotificationType(notificationSoundOrigin === NotificationConfigType.EveryTicket ? 'new-ticket' : 'new-message');
    setVisualTicketNotificationType(notificationVisualOrigin === NotificationConfigType.EveryTicket ? 'new-ticket' : 'new-message');

    return () => {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    if (showPopup) {
      setItemDeleteConfirmation(null);
      setSearchTerm('');
      setShowSettings(false);
      fetchNotifications(false, 1);
      getExpireDays();
      setCheckPermission(false);

      if (checkInterval) {
        clearInterval(checkInterval)
        setCheckInterval(null);
      }

      if (notificationSoundOrigin) {
        setSoundTicketNotificationType(notificationSoundOrigin === NotificationConfigType.EveryTicket ? 'new-ticket' : 'new-message');
      }

      if (notificationVisualOrigin) {
        setVisualTicketNotificationType(notificationVisualOrigin === NotificationConfigType.EveryTicket ? 'new-ticket' : 'new-message');
      }
    }
  }, [showPopup]);

  // useEffect(() => {
  //   if (showPopup) {
  //     if (hasSoundGeneralNotify) {
  //       localStorage.setItem('cx_sound_general_notify', 'true');
  //     } else {
  //       localStorage.setItem('cx_sound_general_notify', 'false');
  //     }

  //     if (hasSoundTicketNotification) {
  //       localStorage.setItem('cx_sound_ticket_notify', 'true');
  //     } else {
  //       localStorage.setItem('cx_sound_ticket_notify', 'false');
  //     }

  //     if (hasVisualTicketNotification) {
  //       localStorage.setItem('cx_visual_ticket_notify', 'true');
  //     } else {
  //       localStorage.setItem('cx_visual_ticket_notify', 'false');
  //     }
  //   }
  // }, [showPopup, hasSoundGeneralNotify, hasSoundTicketNotification, hasVisualTicketNotification]);

  useEffect(() => {
    if (actionEvent?.event === 'new-notification') {
      socketEventNewNotification();
    }
    if (actionEvent?.event && actionEvent?.data) {
      if (actionEvent.event === 'new-ticket-notification') {
        const socket_data = actionEvent.data as { ticket: TicketInterface };

        if (!socket_data.ticket.ticket_created_by_employee) {
          if (hasSoundTicketNotification) {
            playSound();
          }
          if (hasVisualTicketNotification) {
            if (socket_data) {
              socketEventTicketVisualNotification(visualTicketNotificationType, socket_data);
            }
          }
        }
      }

      if (actionEvent.event === 'new-message-notification') {
        const socket_data = { ticket: null as TicketInterface, consumerMessageInfo: actionEvent.data as SocketDataNewConsumerMessageInterface };
        const assignedEmployee = socket_data.consumerMessageInfo?.assigned_employee;

        const NON_NOTIFIABLE_STATUS = [TicketStatus.Resolved.toString(), TicketStatus.Abandoned.toString()];
        const AVOID_NOTIFICATION = NON_NOTIFIABLE_STATUS.includes(socket_data.consumerMessageInfo.status_ticket.id);
        
        if ((!currentTicket?.id || currentTicket?.id !== socket_data.consumerMessageInfo.id) && !AVOID_NOTIFICATION) {
          if (hasSoundTicketNotification && soundTicketNotificationType === 'new-message' && socket_data) {
            if (!assignedEmployee || assignedEmployee.id === employeeId) {
              playSound();
            }
          }
          if (hasVisualTicketNotification && visualTicketNotificationType === 'new-message' && socket_data) {
            if (!assignedEmployee || assignedEmployee.id === employeeId) {
              socketEventTicketVisualNotification('new-message', socket_data);
            }
          }
        }
      }
    }
  }, [actionEvent]);

  useEffect(() => {
    function notificationTicketNewConsumerMessage(socket_data: SocketDataNewConsumerMessageInterface) {
      emitActionEvent('new-message-notification', socket_data);
    }

    function notificationTicketNewTicket(socket_data: { ticket: TicketInterface }) {
      emitActionEvent('new-ticket-notification', socket_data);
    }

    if (socketInitialized) {
      registerSocketAppEvent('ticket-notification', () => {
        emitActionEvent('new-notification');
      });

      registerSocketAppEvent('task-notification', () => {
        emitActionEvent('new-notification');
      });

      // O listener desse evento deve ser uma função nomeada, pois existe esse mesmo evento registrado em outro componente
      // para uma outra finalidade (pages/homeApp/homeTickets2/listOfTickets/indexController.tsx)
      registerSocketAppEvent('new-consumer-message', notificationTicketNewConsumerMessage);

      registerSocketAppEvent('new-ticket', notificationTicketNewTicket);
    }

    return () => {
      if (socketInitialized) {
        unregisterSocketAppEvent('ticket-notification');
        unregisterSocketAppEvent('task-notification');
        unregisterSocketAppEvent('new-consumer-message', notificationTicketNewConsumerMessage);
        unregisterSocketAppEvent('new-consumer-message', notificationTicketNewTicket);
        unregisterSocketAppEvent('new-ticket', notificationTicketNewTicket);
      }
    }
  }, [socketInitialized]);

  const socketEventNewNotification = () => {
    if (!showPopup) {
      setHasNewNotification(true);
    } else {
      fetchNotifications(false, 1);
    }

    if (hasSoundGeneralNotify) {
      playSound();
    }
  }

  const socketEventTicketVisualNotification = (notificationType: 'new-ticket' | 'new-message', socket_data: { ticket?: TicketInterface, consumerMessageInfo?: SocketDataNewConsumerMessageInterface }) => {
    if (notificationType === 'new-ticket') {
      handleNotify('new-ticket', socket_data);
    } else {
      handleNotify('new-message', socket_data);
    }
  }

  const handleNotify = (notificationType: 'new-ticket' | 'new-message', data: { ticket?: TicketInterface, consumerMessageInfo?: SocketDataNewConsumerMessageInterface }) => {
    if (Notification.permission === "granted") {
      let notificationTitle = t('visual_notification.title_default');

      const options = {
        body: t('visual_notification.body_default'),
        icon: Logo,
        vibrate: [200, 100, 200],
        tag: "cxpress-notifications",
      };

      if (notificationType === 'new-ticket') {
        options.body = t('visual_notification.body_new_ticket', { reference_id: data.ticket?.ticket_reference_id || data.consumerMessageInfo?.ticket_reference_id });
        notificationTitle = t('visual_notification.title_new_ticket');
      } else {
        options.body = t('visual_notification.body_new_message', { reference_id: data.consumerMessageInfo?.ticket_reference_id || data.ticket?.ticket_reference_id });
        notificationTitle = t('visual_notification.title_new_message');
      }

      const notification = new Notification(notificationTitle, options);

      notification.onclick = () => {
        window.focus();
        navigate(`${constsRouters.routers.tickets.path}/${data.ticket?.id || data.consumerMessageInfo?.id}`);
      }
    } else {
      console.log(t('configuration.permission_notification_denied'));
      if (Notification.permission === 'default') {
        requestNotificationPermission();
      }
    }
  };

  const show = () => {
    setShowPopup(!showPopup);
    setHasNewNotification(false);
    const storageHasSoundGeneralNotify = localStorage.getItem('cx_sound_general_notify');
    const storageHasSoundTicketNotify = localStorage.getItem('cx_sound_ticket_notify');
    const storageHasVisualTicketNotify = localStorage.getItem('cx_visual_ticket_notify');

    if (!storageHasSoundGeneralNotify) {
      localStorage.setItem('cx_sound_general_notify', 'true');
      setHasSoundGeneralNotify(true);
      setSoundGeneralNotifyTemp(true);
    } else {
      setHasSoundGeneralNotify(storageHasSoundGeneralNotify === 'true');
      setSoundGeneralNotifyTemp(storageHasSoundGeneralNotify === 'true');
    }
    if (!storageHasSoundTicketNotify) {
      localStorage.setItem('cx_sound_ticket_notify', 'true');
      setHasSoundTicketNotification(true);
      setSoundTicketNotificationTemp(true);
    } else {
      setHasSoundTicketNotification(storageHasSoundTicketNotify === 'true');
      setSoundTicketNotificationTemp(storageHasSoundTicketNotify === 'true');
    }
    if (!storageHasVisualTicketNotify) {
      localStorage.setItem('cx_visual_ticket_notify', 'true');
      setHasVisualTicketNotification(true);
      setVisualTicketNotificationTemp(true);
    } else {
      setHasVisualTicketNotification(storageHasVisualTicketNotify === 'true');
      setVisualTicketNotificationTemp(storageHasVisualTicketNotify === 'true');
    }
  }

  const handleShowConfig = () => {
    setShowSettings(!showSettings);
    setShowMarkAllConfirm(false);
  }

  const playSound = () => {
    try {
      notificationFileSound?.play().catch(error => {
        console.error(t('alert.notification_no_sound'));
        dispatch(setShowAlertFeedback({ message: `${t('alert.notification_no_sound')}`, visibility: true, signalIcon: true }));
      });
    } catch (error) {
      console.error('Notification file sound not loaded');
    }
  }

  const requestNotificationPermission = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
        setCheckPermission(true);
      } else if (Notification.permission === 'granted') {
        setNotificationPermission(Notification.permission);
        setCheckPermission(false);
        if (checkInterval) {
          clearInterval(checkInterval);
          setCheckInterval(null);
        }
      }
    } else {
      console.error(t('configuration.permission_notification_unsupported'));
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isLoading) {
      const newSearchTerm = event.target.value;
      setSearchTerm(event.target.value);
  
      if (newSearchTerm) {
        if (searchTimeout) {
          clearTimeout(searchTimeout)
          setSearchTimeout(null);
        }
    
        const newSearchTimeout = setTimeout(() => {
          fetchNotifications(false, 1, true, newSearchTerm);
          setIsFiltered(true);
        }, 1500);
    
        setSearchTimeout(newSearchTimeout);
      } else {
        setIsFiltered(false);
        fetchNotifications(true, 1);
        setSearchTimeout(null);
      }
    }
  }

  const handleEnterSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!isLoading) {
      if (event.key === 'Enter') {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
        fetchNotifications(false, 1, true, searchTerm);
        setIsFiltered(true);
      }
    }
  }

  const fetchNotifications = (isInfiniteScroll: boolean, page: number, isFilter?: boolean, current_query?: string) => {
    // Adicionar paginação com scroll infinito
    const params = { search: current_query, limit: LIMIT_ITEMS_PAGINATION, page: page || notificationsListPage };
    const headers = UserService.getHeaders();
    const config = { headers, params };
    
    if (!params.search || !isFilter) {
      delete params.search;
    }

    AppRequesterConst.Get('/notification', config, 
    (response: any) => {}, 
    (response: NotificationFetchInterface) => {
      if (response.status === 200 && response.data.notifications.length > 0) {
        if (response.data.notifications.length === LIMIT_ITEMS_PAGINATION) {
          setHasMoreNotifications(true);
        } else {
          setHasMoreNotifications(false);
        }

        if (isInfiniteScroll) {
          const new_array = [...notificationsList, ...response.data.notifications];
          setNotificationsList(new_array);
        } else {
          setNotificationsList(response.data.notifications || []);
        }
      } else {
        if (!isInfiniteScroll) {
          setNotificationsList([]);
        }
      }
    },
    (error: any) => {
      console.log(error);
    },
    navigate, dispatch, setIsLoading, {});
  }

  const fetchNotificationsPagination = () => {
    if (hasMoreNotifications) {
      const newPage = notificationsListPage + 1;
      setNotificationsListPage(newPage);

      fetchNotifications(true, newPage, isFiltered, searchTerm);
    }
  }

  const markNotificationAsRead = (notificationId: string, isAction?: boolean, isFiltered?: boolean) => {
    const headers = UserService.getHeaders();
    
    const body = { id: notificationId };

    AppRequesterConst.Put('/notification/read', body, { headers },
    (response: any) => {}, 
    (response: NotificationFetchInterface) => {
      if (isAction) {
        dispatch(setShowAlertFeedback({ message: t("alert.marked_as_read"), visibility: true, signalIcon: true }));
      }
      fetchNotifications(false, 1, isFiltered, searchTerm);
    },
    (error: any) => {
      dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
    },
    navigate, dispatch, setIsLoading);
  }

  const showDeleteConfirmation = (notificationId: string, value: boolean) => {
    if (value) {
      setItemDeleteConfirmation(notificationId);
    } else {
      setItemDeleteConfirmation(null);
    }
  }

  const deleteNotification = () => {
    if (itemDeleteConfirmation) {
      const headers  = UserService.getHeaders();

      AppRequesterConst.Delete(
        `/notification/delete/${itemDeleteConfirmation}`, { headers },
        (response: any) => {},
        (response: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully'), visibility: true, signalIcon: true }));
          fetchNotifications(false, 1);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setIsLoading
      );
    }
  }

  const handleConfirmDelete = () => {
    deleteNotification();
  }

  const markAllAsRead = () => {
    const headers = UserService.getHeaders();
    
    AppRequesterConst.Put(
      '/notification/read', {}, { headers },
      (response: any) => {},
      (response: any) => {
        dispatch(setShowAlertFeedback({ message: t('alert.marked_all_as_read'), visibility: true, signalIcon: true }));
        setShowMarkAllConfirm(false);
        fetchNotifications(false, 1);
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading
    );
  }

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  }

  const getExpireDays = () => {
    if (user_id) {
      const headers = UserService.getHeaders();
  
      AppRequesterConst.Get(
        `/agent/${user_id}/days-for-notification-expiration`, { headers },
        (response: any) => {},
        (response: { status: number, data: { days: number, message: string } }) => {
          if (response.status === 200) {
            setExpireDays(response.data.days);
          } else {
            setExpireDays(60);
          }
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setIsLoading, {}
      );
    }
  }

  const updateDaysExpiration = async () => {
    if (user_id) {
      const headers = UserService.getHeaders();
      const data = { user_id, days: expireDays };

      return new Promise((resolve, reject) => {
        AppRequesterConst.Patch(
          '/agent/update/days-for-notification-expiration', data, { headers },
          (response: any) => { },
          (response: any) => {
            resolve(expireDays);
          },
          (error: any) => {
            reject(error);
          }, 
          navigate, dispatch, setIsLoading,
        );
      });
    }
  }

  const updateNotificationType = async () => {
    const headers = UserService.getHeaders();
    const data: { user_id: string, notification_sound_origin?: string, notification_visual_origin?: string } = { 
      user_id
    };

    if (hasSoundTicketNotification) {
      data['notification_sound_origin'] = soundTicketNotificationType === 'new-ticket' ? NotificationConfigType.EveryTicket : NotificationConfigType.EveryMessage;
      localStorage.setItem('cx_sound_ticket_notify', 'true');
    } else {
      localStorage.setItem('cx_sound_ticket_notify', 'false');
    }

    if (hasVisualTicketNotification) {
      data['notification_visual_origin'] = visualTicketNotificationType === 'new-ticket' ? NotificationConfigType.EveryTicket : NotificationConfigType.EveryMessage;
      localStorage.setItem('cx_visual_ticket_notify', 'true');
    } else {
      localStorage.setItem('cx_visual_ticket_notify', 'false');
    }

    return new Promise((resolve, reject) => {
      AppRequesterConst.Put(
        '/agent/type-notification-origin', data, { headers },
        (response: any) => {},
        (response: any) => {
          dispatch(setSoundNotification(data.notification_sound_origin));
          dispatch(setVisualNotification(data.notification_visual_origin));
          resolve(response.data);
        },
        (error: any) => {
          reject(error);
        },
        navigate, dispatch, setIsLoading
      );
    });
  }

  const updateGeneralSoundNotification = () => {
    if (soundGeneralNotifyTemp) {
      setHasSoundGeneralNotify(true);
      localStorage.setItem('cx_sound_general_notify', 'true');
    } else {
      setHasSoundGeneralNotify(false);
      localStorage.setItem('cx_sound_general_notify', 'false');
    }

    if (soundTicketNotificationTemp) {
      setHasSoundTicketNotification(true);
      localStorage.setItem('cx_sound_ticket_notify', 'true');
    } else {
      setHasSoundTicketNotification(false);
      localStorage.setItem('cx_sound_ticket_notify', 'false');
    }

    if (visualTicketNotificationTemp) {
      setHasVisualTicketNotification(true);
      localStorage.setItem('cx_visual_ticket_notify', 'true');
    } else {
      setHasVisualTicketNotification(false);
      localStorage.setItem('cx_visual_ticket_notify', 'false');
    }
  }

  const handleSubmitConfigurations = () => {
    Promise.all([
      updateDaysExpiration(),
      updateNotificationType(),
      updateGeneralSoundNotification()
    ]).then(() => {
      dispatch(setShowAlertFeedback({ message: `${t('alert.update_config_successfully')}`, visibility: true, signalIcon: true }));
      setShowPopup(false);
    }).catch(err => {
      dispatch(setShowAlertFeedback({ message: `${t('alert.unexpected_error')}`, visibility: true, signalIcon: false }));
    });
  }

  const getFormattedDate = (created_at: string) => {
    const date = new Date(created_at);
    const formatted = date.toLocaleDateString(i18n.language) + ` ${t('date_at_label')} ` + date.toLocaleTimeString(i18n.language).slice(0,5);
    return formatted;
  }

  const handleClickNotificationRead = (notificationId: string) => {
    const isFiltered = searchTerm?.length > 0;

    markNotificationAsRead(notificationId, true, isFiltered);
  }

  const handleClickNotification = (notificationId: string, ticket_id: string, readAt: string) => {
    const isFiltered = searchTerm?.length > 0;

    if (!readAt) {
      markNotificationAsRead(notificationId, true, isFiltered);
    }

    navigate(`${constsRouters.routers.tickets.path}/${ticket_id}`);
    setShowPopup(false);
  }

  const handleClickClearSearch = () => {
    setSearchTerm('');
    setIsFiltered(false);
    fetchNotifications(false, 1);
  }

  const handleExpireDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setExpireDays(newValue);
  };

  const getDaysExpirePositionStyle = () => {
    const percentage = (expireDays - 1) / (60 - 1); // Calcula a posição percentual (0% a 100%)
    const position = percentage * 100; // Converte para pixels
    return {
      left: `calc(${position}% - ${(position / 6.667) + 3}px)` // Ajusta a posição com base na largura do círculo
    };
  };

  const changeCheckConfig = (type: 'sound-notification' | 'visual-notification', value: boolean) => {
    if (type === 'sound-notification') {
      setSoundTicketNotificationTemp(value);
    } else if (type === 'visual-notification') {
      setVisualTicketNotificationTemp(value);
    }
  }

  const changeCheckConfigType = (type: 'sound-notification' | 'visual-notification', value: 'new-ticket' | 'new-message') => {
    if (type === 'sound-notification') {
      setSoundTicketNotificationType(value);
    } else if (type === 'visual-notification') {
      setVisualTicketNotificationType(value);
    }
  }

  const SpanTicket = ({ notification_id, ticket_id, read_at, ticket_reference }) => (
    <OverlayTrigger placement='bottom' overlay={<Tooltip>{t('NotificationComponent:notification_task.open_ticket')}</Tooltip>}>
      <span className='notifications-component-list-item-message-ticket' onClick={() => handleClickNotification(notification_id, ticket_id, read_at)}>
        #{ticket_reference}
      </span>
    </OverlayTrigger>
  );

  const SpanTask = ({ task_id }) => (
    <OverlayTrigger placement='bottom' overlay={<Tooltip>{t('NotificationComponent:notification_task.open_task')}</Tooltip>}>
      <span className='notifications-component-list-item-message-ticket' onClick={(e) => handleClickTaskNotification(task_id, e)}>
        {t('NotificationComponent:notification_task.label_task')}
      </span>
    </OverlayTrigger>
  );

  const messageNotification = ({ 
    notification_id, 
    field_ticket_id,
    ticket_reference,
    trigger_type,
    ticket_id,
    task_id,
    read_at,
    has_expired
  }: MessageNotificationAttributes): { element: JSX.Element } => {
    let i18nKey = 'NotificationComponent:';

    if (!task_id) { // ======== notificações de tickets ========
      i18nKey = 'NotificationComponent:notification_ticket.';
      if (trigger_type === TriggerType.Add) {
        if (field_ticket_id === StatusNotification.Atribuido) {
          i18nKey = 'NotificationComponent:notification_ticket.add_assigned_ticket'; // Agente atribuído ao ticket
        } else if (field_ticket_id === StatusNotification.Seguidores) {
          i18nKey = 'NotificationComponent:notification_ticket.add_follower_ticket'; // Agente adicionado como seguidor do ticket
        }
      } else if (trigger_type === TriggerType.Replace) {
        if (field_ticket_id === StatusNotification.Status) {
          i18nKey = 'NotificationComponent:notification_ticket.notification_resolved'; // Ticket resolvido por abandono pelo sistema
        }
      } else if (trigger_type === TriggerType.Remove) {
        if (field_ticket_id === StatusNotification.Atribuido) {
          i18nKey = 'NotificationComponent:notification_ticket.remove_assigned_ticket'; // Agente desatribuído do ticket
        } else if (field_ticket_id === StatusNotification.Seguidores) {
          i18nKey = 'NotificationComponent:notification_ticket.remove_follower_ticket'; // Agente removido como seguidor do ticket
        }
      }
    } else { // ======== notificações de tarefas ========
      i18nKey = 'NotificationComponent:notification_task.';
      if (trigger_type === TriggerType.Add) {
        if (field_ticket_id === StatusNotification.Atribuido) { // Tarefa atribuída ao agente
          i18nKey = 'NotificationComponent:notification_task.add_assigned_task';
        } else if (field_ticket_id === StatusNotification.Seguidores) { // Tarefa com seguidores adicionados
          i18nKey = 'NotificationComponent:notification_task.add_follower_task';
        } else if (field_ticket_id === StatusNotification.Observacao) { // Alteração na observação da tarefa
          i18nKey = 'NotificationComponent:notification_task.add_observation';
        }
      } else if (trigger_type === TriggerType.Replace) {
        if (field_ticket_id === StatusNotification.Atribuido) { // Tarefa atribuída ao agente
          i18nKey = 'NotificationComponent:notification_task.add_assigned_task';
        } else if (field_ticket_id === StatusNotification.Status && !has_expired) { // Alteração no status da tarefa pelo agente
          i18nKey = 'NotificationComponent:notification_task.replace_status';
        } else if (field_ticket_id === StatusNotification.Status && has_expired) { // Alteração no status da tarefa pelo sistema
          i18nKey = 'NotificationComponent:notification_task.notification_expired';
        } else if (field_ticket_id === StatusNotification.DescricaoTask) { // Alteração na descrição da tarefa
          i18nKey = 'NotificationComponent:notification_task.replace_description';
        } else if (field_ticket_id === StatusNotification.dataLimite) { // Alteração na data limite da tarefa
          i18nKey = 'NotificationComponent:notification_task.replace_date_limit';
        } else if (field_ticket_id === StatusNotification.HoraLimite) { // Alteração no horário limite da tarefa
          i18nKey = 'NotificationComponent:notification_task.replace_hour_limit';
        } else if (field_ticket_id === StatusNotification.Observacao) { // Alteração na observação da tarefa
          i18nKey = 'NotificationComponent:notification_task.replace_observation';
        }
      } else if (trigger_type === TriggerType.Notification) {
        if (field_ticket_id === StatusNotification.Status) {
          i18nKey = 'NotificationComponent:notification_task.notification_deadline'; // Tarefa com prazo
        }
      } else if (trigger_type === TriggerType.Remove) {
        if (field_ticket_id === StatusNotification.Atribuido) { // Tarefa desatribuída do agente
          i18nKey = 'NotificationComponent:notification_task.remove_assigned_task';
        } else if (field_ticket_id === StatusNotification.Seguidores) { // Tarefa com seguidores removidos
          i18nKey = 'NotificationComponent:notification_task.remove_follower_task';
        } else if (field_ticket_id === StatusNotification.Observacao) { // Alteração na observação da tarefa
          i18nKey = 'NotificationComponent:notification_task.remove_observation';
        }
      }
    }

    return {
      element: (
        <div className="notifications-component-list-item-message">
          <Trans 
            i18nKey={i18nKey}
            components={
              i18nKey.includes('notification_ticket') ? 
              [
                <SpanTicket notification_id={notification_id} ticket_reference={ticket_reference} ticket_id={ticket_id} read_at={read_at} />
              ]
              :
              [
                <SpanTask task_id={task_id} />,
                <SpanTicket notification_id={notification_id} ticket_reference={ticket_reference} ticket_id={ticket_id} read_at={read_at} />
              ]
            }
          />
        </div>
      )
    };
  }

  const handleClickTaskNotification = (taskId: string, event: any) => {
    event.stopPropagation();
    setShowModalTask(true);
    setTimeout(() => {
      emitter.emit('open-modal-task', { taskId });
    }, 50);
  }

  const closeModalTask = () => {
    setShowModalTask(false);
  }

  return (
    <NotificationsComponent 
      t={t}
      popupRef={popupRef}
      hasNewNotification={hasNewNotification}
      showPopup={showPopup}
      show={show}
      notificationsList={notificationsList}
      fetchNotificationsPagination={fetchNotificationsPagination}
      hasMoreNotifications={hasMoreNotifications}
      isLoadingNotificationsPagination={isLoadingNotificationsList}
      showSettings={showSettings}
      notificationPermission={notificationPermission}
      hasNotificationPermission={hasNotificationPermission}
      requestNotificationPermission={requestNotificationPermission}
      checkPermission={checkPermission}
      handleShowConfig={handleShowConfig}
      hasSoundGeneralNotify={hasSoundGeneralNotify}
      setHasSoundGeneralNotify={setHasSoundGeneralNotify}
      soundGeneralNotifyTemp={soundGeneralNotifyTemp}
      setSoundGeneralNotifyTemp={setSoundGeneralNotifyTemp}
      isLoading={isLoading}
      isFiltered={isFiltered}
      searchTerm={searchTerm}
      handleInputChange={handleInputChange}
      handleEnterSearch={handleEnterSearch}
      getFormattedDate={getFormattedDate}
      messageNotification={messageNotification}
      handleClickNotificationRead={handleClickNotificationRead}
      handleClickClearSearch={handleClickClearSearch}
      showDeleteConfirmation={showDeleteConfirmation}
      itemDeleteConfirmation={itemDeleteConfirmation}
      handleConfirmDelete={handleConfirmDelete}
      showMarkAllConfirm={showMarkAllConfirm}
      setShowMarkAllConfirm={setShowMarkAllConfirm}
      handleMarkAllAsRead={handleMarkAllAsRead}
      expireDays={expireDays}
      handleExpireDaysChange={handleExpireDaysChange}
      getDaysExpirePositionStyle={getDaysExpirePositionStyle}
      changeCheckConfig={changeCheckConfig}
      changeCheckConfigType={changeCheckConfigType}
      hasSoundTicketNotification={hasSoundTicketNotification}
      soundTicketNotificationType={soundTicketNotificationType}
      soundTicketNotificationTemp={soundTicketNotificationTemp}
      setSoundTicketNotificationTemp={setSoundTicketNotificationTemp}
      hasVisualTicketNotification={hasVisualTicketNotification}
      visualTicketNotificationType={visualTicketNotificationType}
      visualTicketNotificationTemp={visualTicketNotificationTemp}
      setVisualTicketNotificationTemp={setVisualTicketNotificationTemp}
      handleSubmitConfigurations={handleSubmitConfigurations}
      showModalTask={showModalTask}
      closeModalTask={closeModalTask}
    />
  );
}

export default NotificationsComponentController;