import './styles.scss';
import { FC } from 'react';
import { ModalChangeSectorConfirmationInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';

const ModalChangeSectorConfirmation: FC<ModalChangeSectorConfirmationInterface> = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-change-sector-confirmation-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-change-sector-confirmation-header-actions">
          <IconX onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-change-sector-confirmation-section">
          <div className="modal-change-sector-confirmation-item">
            <div className="modal-change-sector-confirmation-item-header">
              <span className="modal-change-sector-confirmation-item-label">{props.t('alert_text')}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-change-sector-confirmation-btn-cancel' onClick={() => props.hide()}>{props.t('cancel')}</button>
        <button className='modal-change-sector-confirmation-btn-save' onClick={() => props.handleSubmit()}>
          {props.t('confirm')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeSectorConfirmation;
