import { FC } from 'react';
import ModalExpired from './index';
import { ModalExpiredControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';

const ModalExpiredController: FC<ModalExpiredControllerInterface> = (props) => {
  const { t } = useTranslation('ModalExpired');

  return (
    <ModalExpired
      t={t}
      show={props?.show}
      hide={props?.hide}
    />
  );
};

export default ModalExpiredController;
