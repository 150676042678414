import { createSlice } from '@reduxjs/toolkit'
import { CallcenterInstance, CallcenterState } from '../pages/homeApp/components-fixed/phoneCallcenter/indexModel';

export const callcenterSlice = createSlice({
  name: 'callcenter',
  initialState: {
    infra_server: null as string,
    extension: null as string,
    phone_number: null as string,
    password: null as string,
    owner_id: null as string,
    callcenter_instance: null as CallcenterInstance,
    callcenter_state: {
      session: {
        register_status: 'disconnected',
        has_extension: false,
        callcenter_id: undefined as string,
        credentials: {
          username: undefined as string,
          display_name: undefined as string,
          password: undefined as string
        },
        transport_error: false
      },
      events: {
        type: undefined as string,
        description: undefined as string
      },
      call_status: {
        direction: undefined as string,
        incoming_number: undefined as string,
        incoming_display_name: undefined as string,
        is_muted: false,
        is_hold: false,
        duration: undefined as number,
        is_active: false,
        is_incoming: false,
        is_ringing: false,
        start_call_date: undefined as Date
      },
      call_info: {
        display: '',
        to_call: '',
        to_transfer: '',
        id_response: null as string,
        is_assisted: false
      }
    },
  },
  reducers: {
    setInfraServer: (state, action) => {
      state.infra_server = action.payload
    },
    setExtension: (state, action) => {
      state.extension = action.payload
    },
    setPhoneNumber: (state, action) => {
      state.phone_number = action.payload
    },
    setPassword: (state, action) => {
      state.password = action.payload
    },
    setOwner_id: (state, action) => {
      state.owner_id = action.payload
    },
    setCallcenterState: (state, action) => {
      state.callcenter_state = action.payload;
    },
    setCallcenterInstance: (state, action) => {
      state.callcenter_instance = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { 
  setInfraServer,
  setExtension, 
  setPassword, 
  setOwner_id, 
  setPhoneNumber, 
  setCallcenterState, 
  setCallcenterInstance,
} = callcenterSlice.actions;

export const getInfraServer = (state: { callcenter: { infra_server: string } }) => (state?.callcenter?.infra_server);
export const getExtension = (state: { callcenter: { extension: string } }) => (state?.callcenter?.extension);
export const getPhoneNumber = (state: { callcenter: { phone_number: string } }) => (state?.callcenter?.phone_number);
export const getPassword = (state: { callcenter: { password: string } }) => (state?.callcenter?.password);
export const getOwner_id = (state: { callcenter: { owner_id: string } }) => (state?.callcenter?.owner_id);
export const getCallcenterState = (state: { callcenter: { callcenter_state: CallcenterState } }) => (state?.callcenter?.callcenter_state);
export const getCallcenterInstance = (state: { callcenter: { callcenter_instance: CallcenterInstance } }) => (state?.callcenter?.callcenter_instance);

export default callcenterSlice.reducer;
