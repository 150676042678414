import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';

import { Popover, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { getToken } from '../../../../../store/token'
import { getIdCompany } from '../../../../../store/company'
import { getIdEmployee } from '../../../../../store/employee'
import { getIdUser, getNameUser, getProfileId } from '../../../../../store/user'
import Utils from '../../../../../core/shared/utils';

import ConstRouters from '../../../../../routes/constsRouter'
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../services/user-service';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';

import { getPromptUpdatedNlp, setPromptUpdatedNlp, setShowAlertFeedback, setShowConfirmationModal } from '../../../../../store/internal'

import ListBots from './index';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../../../../core/context/socket-context';
import { verifyCode } from '../../../../../services/codeCxpressInterface';
import useBotCxpressStarted from '../../../../../hooks/useBotCxpressStartedSocket';

const AppRequesterConst = new AppRequesterController();

const ListBotsController = (props) => {
  const dataBot = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentBotId, setCurrentBotId] = useState('');
  const [currentUpdated, setCurrentUpdatedBot] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(true)
  const [statusId, setStatusId] = useState()
  const [dataTicketsN1, setDataTicketsN1] = useState()
  const [data, setData] = useState([])


  const { botStarted } = useBotCxpressStarted()

  // const [promptUpdatedNlp, setPromptUpdatedNlp] = useState(dataBot.state && dataBot.state.promptUpdate !== undefined ? dataBot.state.promptUpdate : true);

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    promptUpdatedNlp: useSelector(getPromptUpdatedNlp)
  };
  useEffect(() => {
    Utils.changeActiveScrollBar("ListOfAgents");
  }, [props.datas]);

  useEffect(() => {
    if (botStarted != undefined) {
      dispatch(setPromptUpdatedNlp(false))
    }
  }, [botStarted])

  useEffect(() => {
    if (data !== undefined && data['whatsapp_keys_id'] !== null && data['whatsapp_keys_id'] !== undefined && props?.channel === '33be81d8-88ac-4e08-bb4d-cf69fd23267f') {
      getTotalTicketsN1(props.channel, data['whatsapp_keys_id']);
    }
  }, [data])


  const getBotCxpressID = async (id: string, defaultActiveKey: string, popover?: boolean) => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/bot-cxpress/" + id,
      { headers: headers, params: {} },
      (response: Object) => { },
      (data) => {
        setData(data.data.botCxpress)
        if (defaultActiveKey.length > 0 && (popover !== true)) {
          navigate(ConstRouters.routers.configurationChatbotView.path, { state: { data: data.data.botCxpress, channelKeysId: props.channelKey, channel: props?.channel, defaultActiveKey: defaultActiveKey, voice: props?.voice, dataCompany: props?.dataCompany } });
        }
      },
      (error: Object) => { },
      navigate, dispatch, setIsLoading, { values: values }
    )
  }

  //AJUSTAR ESSE TRECHO PARA SER CHAMADO AO CLICKAR NOS 3 BUTTONS
  const getTotalTicketsN1 = async (channel_id: string, key_id: string) => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/bot-cxpress/count/tickets/n1",
      { headers: headers, params: { channel_id: channel_id, key_id: key_id} },
      (response: Object) => { },
      (data) => {
        setDataTicketsN1(data.data.qntTickets)
      },
      (error: Object) => { },
      navigate, dispatch, setIsLoading, { values: values }
    )
  }

  const viewBotPage = (e: { currentTarget: { id: string; }; }, defaultActiveKey: string, data?: object, updated?: boolean) => {
    e.currentTarget.id === dataBot.state?.idPost ?
      (values.promptUpdatedNlp === false ?
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.chat_is_updating"), visibility: true, signalIcon: false }))
        :
        getBotCxpressID(e.currentTarget.id, defaultActiveKey)
        // navigate(ConstRouters.routers.configurationChatbotView.path, { state: { data: data, channelKeysId: props.channelKey, channel: props?.channel, defaultActiveKey: defaultActiveKey } })

      )
      :
      (updated === false ?
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.chat_is_updating"), visibility: true, signalIcon: false }))
        :
        getBotCxpressID(e.currentTarget.id, defaultActiveKey)
        // navigate(ConstRouters.routers.configurationChatbotView.path, { state: { data: data, channelKeysId: props.channelKey, channel: props?.channel, defaultActiveKey: defaultActiveKey } })

      )
  }

  const viewBotPage2 = (e, defaultActiveKey) => {
    getBotCxpressID(e.currentTarget.parentElement.parentElement.id, defaultActiveKey)
    // navigate(ConstRouters.routers.configurationChatbotView.path, { state: { data: data, channelKeysId: props.channelKey, channel: props?.channel, defaultActiveKey: defaultActiveKey } });
  }

  const setCurrentBotInfo = (e: { currentTarget: { id: string }; }, updated: boolean) => {
    setCurrentUpdatedBot(updated)
    setCurrentBotId(e.currentTarget.id);
    getBotCxpressID(e.currentTarget.id, '', true)
  }

  const disableChatbot = async (id: string, enable: boolean) => {
    const headers = UserService.getHeaders()

    let JsonSend ={
      "id": id,
      "enable": enable
    }

    await AppRequesterConst.Patch(
      '/bot-cxpress/enable/disable', JsonSend, { headers: headers },
      (response: Object) => {
          return response;
      },
      (data: Object) => {
        enable ?
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.bot_enable_succes"), visibility: true, signalIcon: true })) :
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.bot_disable_succes"), visibility: true, signalIcon: true }))
        props.updateList();
      },
      (error: { response: { status: number, data: { message: string, code_cxpress: number } } }) => {
        if (error.response.data.code_cxpress === 1625) {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.view.bot_config.1623_error"), visibility: true, signalIcon: false }))
        }else {
          enable ?
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.error_enable_bot"), visibility: true, signalIcon: false })):
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.error_disable_bot"), visibility: true, signalIcon: false }))
        }
      }, navigate, dispatch, setIsLoading,
    )
  }

  const deleteChatbot = async (id: string) => {
    const headers = UserService.getHeaders()

    await AppRequesterConst.Delete(
      '/bot-cxpress/' + id, { headers: headers },
      (response: Object) => { },
      (data: Object) => {
        props.updateList();
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.chatbot_deleted"), visibility: true, signalIcon: true }))
      },
      (error: Object) => {
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.lists.defaultMessage"), visibility: true, signalIcon: false }));
      },
      navigate,
      dispatch,
      setIsLoading,
    );
  }

  const deleteBot = (e) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": t("configurations.chatbots.lists.title_delete"), "body": t("configurations.chatbots.lists.body_delete"), "buttonReturnText": t("configurations.chatbots.lists.cancel_delete") },
      functionConfirmation: () => {
        deleteChatbot(e.target.parentElement.parentElement.id)
      }
    }));
  }

  const activationBot = (id, enable) => {
    enable ?
      dispatch(setShowConfirmationModal({
        visibility: true, 
        text: {
          "title": t("configurations.chatbots.lists.title_inactivation"),
          "body": dataTicketsN1 && dataTicketsN1 > 0 ?  
          dataTicketsN1 > 1 ? t("configurations.chatbots.lists.multi_body_inactivation", { total_tickets_reference: dataTicketsN1 }) : t("configurations.chatbots.lists.unique_body_inactivation", { total_tickets_reference: dataTicketsN1 }) 
            :t("configurations.chatbots.lists.body_inactivation"),
          "buttonReturnText": t("configurations.chatbots.lists.cancel_delete"),
          "buttonConfirmationText": t("configurations.chatbots.lists.inactive") },
        functionConfirmation: () => {
          disableChatbot(id, false)
        }
      }))
      :
      dispatch(setShowConfirmationModal({
        visibility: true, text: { "title": t("configurations.chatbots.lists.title_activation"), "body": t("configurations.chatbots.lists.body_activation"), "buttonReturnText": t("configurations.chatbots.lists.cancel_delete"), "buttonConfirmationText": t("configurations.chatbots.lists.active") },
        functionConfirmation: () => {
          disableChatbot(id, true)
        }
      }));
  }

  useEffect(() => {
  }, [values.promptUpdatedNlp])

  const formatedDate = (date: Date) => {
    const day = Utils.addLeftZeros(date.getDate());
    const month = Utils.addLeftZeros(date.getMonth() + 1);
    const year = date.getFullYear();

    return day + "/" + month + "/" + year;
  }

  const popover = (item) => {
    return (
    <Popover id={currentBotId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        {currentBotId === dataBot.state?.idPost ?
          (values.promptUpdatedNlp === false ?
            <></>
            :
            <>
              <Button onClick={(e) => { viewBotPage2(e, 'info') }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{ t("configurations.chatbots.lists.edit") }</Button>
              <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
              <Button onClick={(e) => { viewBotPage2(e, 'chat') }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{ t("configurations.chatbots.lists.open") }</Button>
              <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
            </>)
          :
          (currentUpdated === false ?
            <></>
            :
            <>
                <Button onClick={(e) => { viewBotPage2(e, 'info') }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{ t("configurations.chatbots.lists.edit") }</Button>
              <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={(e) => { viewBotPage2(e, 'chat') }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("configurations.chatbots.lists.open")}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                {item.channel_id === '33be81d8-88ac-4e08-bb4d-cf69fd23267f' ?
                  <>
                    <Button onClick={(e) => { activationBot(item.id, item.bot_enable) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>
                      {item.bot_enable ? t("configurations.chatbots.lists.inactive_chatbot") : t("configurations.chatbots.lists.active_chatbot")}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                  </>
                  :
                  <></>
                }
            </>)
        }
        <Button onClick={deleteBot} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{ t("configurations.chatbots.lists.delete") }</Button>
      </Popover.Body>
    </Popover>
    )
  };

  return (
    <ListBots
      isLoading={isLoading}
      props={props}
      viewBotPage={viewBotPage}
      formatedDate={formatedDate}
      dataBot={dataBot}
      values={values}
      popover={popover}
      setCurrentBotInfo={setCurrentBotInfo}
      t={t}
      dataCompany={props?.dataCompany}
      setActiveTab={props?.setActiveTab}
      channel={props?.channel}
      status={status}
      statusId={statusId}
    />
  );

}

export default ListBotsController;
