import { FC, useEffect, useState } from 'react';
import ModalChangeSectorConfirmation from './index';
import { ModalChangeSectorConfirmationControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import UserService from '../../../../../../services/user-service';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { verifyCode } from '../../../../../../services/codeCxpressInterface';
import { useViewTicketContext } from '../../../../../../core/context/view-ticket-context';
import { useMainAppContext } from '../../../../../../core/context/main-app-context';
import { useNavigate } from 'react-router-dom';

const ModalChangeSectorConfirmationController: FC<ModalChangeSectorConfirmationControllerInterface> = (props) => {
  const { t } = useTranslation('ModalChangeSectorConfirmation');
  const { t: t_codeCxpress } = useTranslation('CodeCxpressErrors');

  const AppRequesterConst = new AppRequesterController();
  const { actionEvent, emitActionEvent } = useMainAppContext();

  const {
    currentTicket,
    setIsLoadingTicketEdit,
  } = useViewTicketContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setIsLoadingTicketEdit(true);
    const headers = UserService.getHeaders();
    const jsonSend = { ...props?.json, confirmation_update: true };
    AppRequesterConst.Put('/ticket', jsonSend, { headers },
      (response: any) => { },
      (response: { data: { code_cxpress: number, message: string } }) => {
        setIsLoadingTicketEdit(false);
        dispatch(setShowAlertFeedback({
          message: t("ticket_edit.success", { ticket_reference_id: currentTicket.ticket_reference_id }),
          signalIcon: true,
          visibility: true
        }));
        props?.hide();

        setTimeout(() => {
        // Verificar se o tipo de alteração inclui resolver o ticket
        // Se sim, atualizar a listagem e alterar o ticket atual para ser o próximo da lista
        // const resolveStatus = [TicketStatus.Abandoned.toString(), TicketStatus.Resolved.toString()];

          emitActionEvent('update-ticket-request');

          // if (resolveStatus.includes(ticketForm.status_id)) {
          //   emitActionEvent('resolve-ticket');
          //   // Mecher aqui
          // } else {
          //   emitActionEvent('update-ticket-request');
          // }
        }, 10);
      },
      (error: any) => {
        setIsLoadingTicketEdit(false);
        if (error?.response?.data?.code_cxpress) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t_codeCxpress), visibility: true, signalIcon: false }));
        } else {
          console.log(error?.response?.data || error);
        }
      },
      navigate, dispatch, props?.setIsLoadingEdit, () => { },
    );
  }

  return (
    <ModalChangeSectorConfirmation
      t={t}
      show={props?.show}
      hide={props?.hide}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalChangeSectorConfirmationController;
