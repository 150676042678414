import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { MetricInterface, TicketsPeriodMetric, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowEditTicketsModal, setShowAlertFeedback } from "../../../../store/internal";
import UserService from "../../../../services/user-service";
import { ErrorRequestController } from "../../../../services/errorsInterfaceModel";
import { Button, Popover } from "react-bootstrap";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useDispatch, useSelector } from "react-redux";
import HomeDashboard from ".";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as TooltipChart,
    Legend,
} from 'chart.js';
import { useMainAppContext } from "../../../../core/context/main-app-context";
import { getAppOpenSidebar } from "../../../../store/app_sidebar";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    TooltipChart,
    Legend
);

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      name?: string;
    }
  }

const AppRequesterConst = new AppRequesterController()

const HomeDashboardController = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setMainHeader } = useMainAppContext();

    const [/*aux*/, setAux] = useState([]);
    const [currentState, setCurrentState] = useState("finalized");
    const [resetingFilters, setResetingFilters] = useState(false);
    const [yoursTickets, setYoursTickets] = useState(false);
    const [currentParams, setCurrentParams] = useState({});
    const [currentParamsNames, setCurrentParamsNames] = useState({});
    const [currentFilterDatas, setcurrentFilterDatas] = useState(undefined);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    // const [firstDayDate, setFirstDayDate] = useState(new Date());
    const [currentDayDate, /*setCurrentDayDate*/] = useState(new Date());

    const [currentFilterDates, setCurrentFilterDates] = useState([null, null]);
    const [channel, setChannel] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [tag, setTag] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [consumer, setConsumer] = useState(null);

    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, /*setPhoneNumber*/] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const MetricModal = {} as MetricInterface;
    const [dataChart, setDataChart] = useState(MetricModal?.data?.value);

    const [currentPage, setCurrentPage] = useState(1);

    const componentRef = useRef();
    const [printPDF, setPrintPDF] = useState(false);

    const [dataEmployeeChart, setDataEmployeeChart] = useState(null);
    const [dataTagPieChart, setDataTagPieChart] = useState(null);
    const [dataNpsPieChart, setDataNpsPieChart] = useState(null);
    const [dataActivePieReceptive, setDataActivePieReceptive] = useState(null)
    const [dataChannelPieChart, setDataChannelPieChart] = useState(null)
    const [dataSectorPieChart, setDataSectorPieChart] = useState(null)

    const sidebarOpened = useSelector(getAppOpenSidebar);

    const [currentHasMoreInformation, /*setCurrentHasMoreInformation*/] = useState(true)

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
        }
    };

    const optionsPieChart = {
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        plugins: {
            legendDistance: {
                margin: '0px 20px 0px 0px'
            },
            legend: {
                display: true,
                position: "right",
                align: "center",
                labels: {
                    font: {
                        size: '11px',
                    }
                },
                onClick: () => {},
                onHover: (evt, item, legend) => {
                    const chart = legend.chart;
                    const tooltip = chart.tooltip;
                    const chartArea = chart.chartArea;

                    tooltip.setActiveElements([{
                      datasetIndex: 0,
                      index: item.index,
                    }], {
                      x: (chartArea.left + chartArea.right) / 2,
                      y: (chartArea.top + chartArea.bottom) / 2,
                    });
          
                    chart.update();
                  }
            },
            datalabels: {
                /* formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr: number[]; 
                    let data = ctx.chart.data.datasets[0].data as unknown as number[];

                    dataArr = data;

                    dataArr.map((data) => {
                        sum += data;
                    });
                    let percentage = ((value * 100) / sum).toFixed(2) + "%";
                    return percentage;
                }, */
                color: '#fff',
                labels: {
                    title: {
                      font: {
                        size: '15px',
                      }
                    },
                },
                anchor: 'end',
                align: 'start',
                offset: '30',
            },
            tooltip: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        let sum = 0;
                        let dataArr: number[]; 
                        dataArr = tooltipItem.dataset.data as unknown as number[];

                        dataArr.map((data) => {
                            sum += data;
                        });
                        let percentage = ((Number(tooltipItem.formattedValue) * 100) / sum).toFixed(2) + "%";
                        return percentage;
                    },
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    }

    const options = {
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 20,
                },
                max: Math.ceil(Math.max(...(dataChart?.result['open-tickets-per-hour'] || [0])) * 1.1), // aumenta o eixo Y para evitar que o número suma em cima das colunas
            },
        },
    };

    const optionsBarCharTag = {
        indexAxis: 'y',
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    stepSize: 1,
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                max: dataTagPieChart ? dataTagPieChart?.datasets[0]?.data[0] * 1.1 : ''
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const optionsBarCharEmployee = {
        indexAxis: 'y',
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    stepSize: 1,
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                max: dataEmployeeChart ? Number(dataEmployeeChart?.datasets[0]?.data[0]) * 1.1 : ''
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const optionsBarCharChannel = {
        indexAxis: 'y',
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    stepSize: 1,
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                max: dataChannelPieChart ? Number(dataChannelPieChart?.datasets[0]?.data[0]) * 1.1 : ''
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const optionsBarCharSector = {
        indexAxis: 'y',
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    stepSize: 1,
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                max: dataSectorPieChart ? Number(dataSectorPieChart?.datasets[0]?.data[0]) * 1.1 : ''
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const maxDataValue = dataNpsPieChart ? Math.max(...dataNpsPieChart.datasets[0].data) : 0;

    const optionsBarCharNps = {
        indexAxis: 'y',
        type: 'bar',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end' as const,
                offset: -20,
                align: "start" as const,
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'transparent',
                },
                ticks: {
                    stepSize: 1,
                    callback: function(value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    }
                },
                max: maxDataValue * 1.1 
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const labels = ["00h", "01h", "02h", "03h", "04h", "05h", "06h", "07h", "08h", "09h", "10h", "11h", "12h", "13h", "14h", "15h", "16h", "17h", "18h", "19h", "20h", "21h", "22h", "23h"];

    const data = {
        labels,
        datasets: [
            {
                label: "",
                data: dataChart !== null && dataChart !== undefined? dataChart.result['open-tickets-per-hour']: 0,
                backgroundColor: 'rgb(79,105,189)',
            },
        ]
    };

    useEffect(() => {
        setMainHeader({
            pageTitle: t('dashboard.bem_vindo_dashboard'),
            pageSubtitle: t('dashboard.descricao'),
            hasSearchComponent: false,
            hasSearchInput: false,
            hasSearchFilter: false,
            searchPage: null,
            searchPlaceholder: null,
            searchFunction: null,
            clearSearchFunction: null,
            hasCreateButton: false,
            createButtonText: null,
            createButtonTooltip: null,
            createButtonAction: null,
            customBackgroundColor: '#f9fbff'
        });
    }, []);

    useEffect(() => {
        if (currentFilterDatas !== undefined) {
            setCurrentParams((state2) => {
                getAllTicketsToPaginationFinalized_Deleted(Object.assign(state2));
                return state2;
            });
        }
    }, [printPDF, currentState, currentFilterDatas]);

    useEffect(() => {
        if (dataChart) {
            getTicketActiveReceptive()
            gettingTicketsPerTag();
            gettingTicketsPerChannel();
            gettingTicketsPerSector();
            getResultNpsScore();
            gettingTicketsPerEmployee();
        }
    }, [dataChart]);

    const getTicketActiveReceptive = () => {
        setDataChart((datas) => {
            setDataActivePieReceptive((active_receptive) => {
                let data = []

                data.push(datas.result["tickets-active"])
                data.push(datas.result["tickets-receptive"])

                active_receptive = {
                    labels: [`${t('dashboard.active')}`, `${t('dashboard.receptive')}`],
                    datasets: [{
                        data: data,
                        borderWidth: 1,
                        backgroundColor: [
                            "#2C3789",
                            "#1D60AE",
                        ],
                        borderColor: "#fff"
                    }]
                }

                return active_receptive
            })

            return datas;
        })
    }

    const gettingTicketsPerTag = () => {
        setDataChart((datas) => {
            if (datas?.result?.tags?.length > 0) {
                let borderWidth = 1;
                // remove largura da borda se houver apenas uma tag
                if (datas.result.tags.length === 1) {
                    borderWidth = 0;
                }

                setDataTagPieChart((chart) => {
                    // chart = [["Tags", "Tickets per Tags"]];
                    let labels = [];
                    let data = [];
    
                    datas.result.tags.forEach((perTag) => {
                        // chart.push([ perTag.nome, perTag.quantidade ]);
                        labels.push(perTag.nome);
                        data.push(perTag.quantidade);
                    });
    
                    const labelDataMap = {};
                    
                    labels.forEach((label, index) => {
                        const quantidade = data[index];
                        if (!labelDataMap[quantidade]) {
                            labelDataMap[quantidade] = [];
                        }
                        labelDataMap[quantidade].push(label);
                    });
    
                    const sortedQuantities = data.slice().sort((a, b) => b - a);
    
                    for (const key in labelDataMap) {
                        labelDataMap[key].sort();
                    }
    
                    const sortedLabels = sortedQuantities.map((value) => {
                        return labelDataMap[value].shift();
                    });
    
                    const newData = {
                        labels: sortedLabels,
                        datasets: [{ 
                            data: sortedQuantities,
                            borderWidth: 1,
                            backgroundColor: "rgb(79,105,189)",
                            borderColor: "#fff"
                        }]
                    }
                    return newData;
                });
            } else {
                setDataTagPieChart(null);
            }
            return datas;
        });
    }

    const gettingTicketsPerChannel = () => {
        setDataChart((datas) => {
            if (datas?.result?.["tickets-total-by-channel"].length > 0) {
                let borderWidth = 1;
                if (datas.result?.["tickets-total-by-channel"].length === 1) {
                    borderWidth = 0;
                }

                setDataChannelPieChart((chart) => {
                    let labels = [];
                    let data = [];
    
                    datas.result?.["tickets-total-by-channel"].forEach((perChannel) => {
                        let label;
                        switch (perChannel.channel) {
                            case "WhatsApp":
                                label = `${t('dashboard.whatsapp')}`;
                                break;
                            case "Email":
                                label = `${t('dashboard.email')}`;
                                break;
                            case "webchat":
                                label = `${t('dashboard.webchat')}`;
                                break;
                            case "Telefonia":
                                label = `${t('dashboard.telephony')}`;
                                break;
                            case "Facebook":
                                label = `${t('dashboard.facebook')}`;
                                break;
                            case "Instagram":
                                label = `${t('dashboard.instagram')}`;
                                break;
                            default:
                                label = perChannel.channel;
                        }
                        labels.push(label);
                        data.push(perChannel.total);
                    });
                    
                    const labelDataMap = {};
                    
                    labels.forEach((label, index) => {
                        const quantidade = data[index];
                        if (!labelDataMap[quantidade]) {
                            labelDataMap[quantidade] = [];
                        }
                        labelDataMap[quantidade].push(label);
                    });
    
                    const sortedQuantities = data.slice().sort((a, b) => b - a);
    
                    for (const key in labelDataMap) {
                        labelDataMap[key].sort();
                    }
    
                    const sortedLabels = sortedQuantities.map((value) => {
                        return labelDataMap[value].shift();
                    });
    
                    const newData = {
                        labels: sortedLabels,
                        datasets: [{  
                            data: sortedQuantities,
                            borderWidth: 1,
                            backgroundColor: "rgb(79,105,189)",
                            borderColor: "#fff"
                        }]
                    }
                    return newData;
                });
            } else {
                setDataChannelPieChart(null);
            }
            return datas;
        });
    }

    const gettingTicketsPerSector = () => {
        setDataChart((datas) => {
            if (datas?.result?.["total-tickets-by-sector"].length > 0) {
                let borderWidth = 1;
                if (datas.result?.["total-tickets-by-sector"].length === 1) {
                    borderWidth = 0;
                }

                setDataSectorPieChart((chart) => {
                    let labels = [];
                    let data = [];
    
                    datas.result?.["total-tickets-by-sector"].forEach((perSector) => {
                        labels.push(perSector.sector ? perSector.sector : `${t('dashboard.no_sector')}`);
                        data.push(perSector.total);
                    });
    
                    const labelDataMap = {};
                    
                    labels.forEach((label, index) => {
                        const quantidade = data[index];
                        if (!labelDataMap[quantidade]) {
                            labelDataMap[quantidade] = [];
                        }
                        labelDataMap[quantidade].push(label);
                    });
    
                    const sortedQuantities = data.slice().sort((a, b) => b - a);
    
                    for (const key in labelDataMap) {
                        labelDataMap[key].sort();
                    }
    
                    const sortedLabels = sortedQuantities.map((value) => {
                        return labelDataMap[value].shift();
                    });
    
                    const newData = {
                        labels: sortedLabels,
                        datasets: [{  
                            data: sortedQuantities,
                            borderWidth: 1,
                            backgroundColor: "rgb(79,105,189)",
                            borderColor: "#fff"
                        }]
                    }
                    return newData;
                });
            } else {
                setDataSectorPieChart(null);
            }
            return datas;
        });
    }

    const getResultNpsScore = () => {
        setDataChart((datas) => {
            setDataNpsPieChart((chart) => {
                const npsScores = {
                    [t('dashboard.nps_response_5')]: datas?.result['nps-scored']?.npsScores5,
                    [t('dashboard.nps_response_4')]: datas?.result['nps-scored']?.npsScores4,
                    [t('dashboard.nps_response_3')]: datas?.result['nps-scored']?.npsScores3,
                    [t('dashboard.nps_response_2')]: datas?.result['nps-scored']?.npsScores2,
                    [t('dashboard.nps_response_1')]: datas?.result['nps-scored']?.npsScores1,
                    [t('dashboard.nps_response_0')]: datas?.result['nps-scored']?.totalNotResponded,
                };
                
                const filteredNpsScores = Object.values(npsScores).filter(score => score > 0);
                const filteredLabels = Object.keys(npsScores).filter(label => npsScores[label] > 0);
        
                chart = {
                    labels: filteredLabels,
                    datasets: [{
                        label: `${t('dashboard.nps_socres_points')}`,
                        data: filteredNpsScores,
                        backgroundColor: "rgb(79,105,189)",
                        borderColor: '#fff'
                    }]
                };
                return chart;
            });
            return datas;
        });
    };

    const gettingTicketsPerEmployee = () => {
        setDataChart((datas) => {
            if (datas?.result?.["tickets-total-by-employee"].length > 0) {
                let borderWidth = 1;
                if (datas.result?.["tickets-total-by-employee"].length === 1) {
                    borderWidth = 0;
                }
                setDataEmployeeChart((chart) => {
                    let labels = [];
                    let data = [];
    
                    datas.result?.["tickets-total-by-employee"].forEach((perEmployee) => {
                        labels.push(perEmployee.nome);
                        data.push(perEmployee.quantidade);
                    });
    
                    const labelDataMap = {};
                    
                    labels.forEach((label, index) => {
                        const quantidade = data[index];
                        if (!labelDataMap[quantidade]) {
                            labelDataMap[quantidade] = [];
                        }
                        labelDataMap[quantidade].push(label);
                    });
    
                    const sortedQuantities = data.slice().sort((a, b) => b - a);
    
                    for (const key in labelDataMap) {
                        labelDataMap[key].sort();
                    }
    
                    const sortedLabels = sortedQuantities.map((value) => {
                        return labelDataMap[value].shift();
                    });

                    const newData = {
                        labels: sortedLabels,  
                        datasets: [{  
                            data: sortedQuantities,
                            borderWidth: 1,
                            backgroundColor: "rgb(79,105,189)",
                            borderColor: "#fff"
                        }]
                    }
                    return newData;
                });
            } else {
                setDataEmployeeChart(null);
            }
            return datas;
        });
    }

    const reportAudit = async (params: any) => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/metrics/dashboard/export-pdf', { headers, params: params },

            (response: Object) => { },
            (data: any) => {
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }
    
    const getAllMetrics = async (params: any) => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/metrics', { headers, params: params },

            (response: Object) => { },
            (data: MetricInterface) => {
                if (data.status === 200) {
                    setDataChart(data.data.value)
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const exportTickets = async () => {
        const headers = UserService.getHeaders();

        let params = {};
        if (currentFilterDates[0] !== null) {
            params["start_date"] = new Date(currentFilterDates[0]).toISOString().split("T")[0];
        }

        if (currentFilterDates[1] !== null) {
            params["end_date"] = new Date(currentFilterDates[1]).toISOString().split("T")[0];
        }

        if (tag !== null) {
            // console.log(tag.toString(), "Tags")
            params["tag"] = tag.toString();
        }

        if (channel === "NA") {
            params["channel"] = null;
        } else if (channel !== null) {
            // console.log(channel.toString(), "Filter")
            params["channel"] = channel.toString();
        }

        if (departmentId !== null) {
            // console.log(departmentId.toString(), "DepartmentId")
            params["sector_id"] = departmentId.toString();
        }

        if (employee === "NA") {
            params["employee"] = null;
        } else if (employee !== null) {
            // console.log(employee.toString(), "Employee")
            params["employee"] = employee.toString();
        }

        if (consumer !== null) {
            // console.log(consumer.toString(), "Consumer")
            params["consumer"] = consumer.toString();
        }

        await AppRequesterConst.Get(
            '/ticket/period/tickets', { headers, params: params },
            (response: Object) => { },
            (data: TicketsPeriodMetric) => {
                if (data.status === 200 && data.data) {
                    var decoder = new TextDecoder();
                    var uintData = Uint8Array.from(data.data.content.data);
                    var arrayBufferData = uintData.buffer;
                    var decoded = decoder.decode(arrayBufferData);

                    const blob = new Blob([decoded], { type: "text/csv;charset=utf-8;" });

                    var hiddenElement = document.createElement('a');

                    if (hiddenElement.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        hiddenElement.setAttribute("href", url);
                        hiddenElement.setAttribute("download", data.data.fileName);
                        hiddenElement.style.visibility = "hidden";
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        document.body.removeChild(hiddenElement);
                    }
                }
            },
            (error: ErrorRequestController) => {
                console.log("ERROR", error.response);
            }, navigate, dispatch, setIsLoading,
            {
                errorsFunctions: {
                    error400: () => {
                        dispatch(setShowAlertFeedback({ message: `${t('dashboard.error400')}`, visibility: true, signalIcon: false }));
                    },
                    error422: () => {
                        dispatch(setShowAlertFeedback({ message: `${t('dashboard.error422')}`, visibility: true, signalIcon: false }));
                    }
                },
                values: values
            },
        );
    }

    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className='nopadding'>
                <ReactToPrint
                    content={() => componentRef.current}
                    onBeforeGetContent={() => printIsValid()}
                    onAfterPrint={() => printIsInvalid()}
                >
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <Button onClick={() => handlePrint()} className='m-1' style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{`${t('dashboard.exportar_pdf_metricas')}`}</Button>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => exportTickets()} className='m-1' style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{`${t('dashboard.exportar_tickets')}`}</Button>
            </Popover.Body>
        </Popover>
    )

    const listRankingAgents = async () => {

        const headers = UserService.getHeaders();

        let params = {};
        if (currentFilterDates[0] !== null) {
            params["start_date"] = new Date(currentFilterDates[0]).toISOString().split("T")[0];
        }

        if (currentFilterDates[1] !== null) {
            params["end_date"] = new Date(currentFilterDates[1]).toISOString().split("T")[0];
        }

        if (tag !== null) {
            // console.log(tag.toString(), "Tags")
            params["tag"] = tag.toString();
        }

        if (channel === "NA") {
            params["channel"] = null;
        } else if (channel !== null) {
            // console.log(channel.toString(), "Filter")
            params["channel"] = channel.toString();
        }

        if (departmentId !== null) {
            // console.log(departmentId.toString(), "DepartmentId")
            params["sector_id"] = departmentId.toString();
        }

        if (employee === "NA") {
            params["employee"] = null;
        } else if (employee !== null) {
            // console.log(employee.toString(), "Employee")
            params["employee"] = employee.toString();
        }

        if (consumer !== null) {
            // console.log(consumer.toString(), "Consumer")
            params["consumer"] = consumer.toString();
        }

        await AppRequesterConst.Get(
            `/ticket/period/ranking/agents`, { headers, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200 && data.data) {

                    let decoder = new TextDecoder();
                    let uintData = Uint8Array.from(data.data.content.data);
                    let arrayBufferData = uintData.buffer;
                    let decoded = decoder.decode(arrayBufferData);

                    let hiddenElement = document.createElement('a');
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(decoded);
                    hiddenElement.target = '_blank'

                    hiddenElement.download = data.data.fileName;
                    hiddenElement.click()
                }
                else if (data.status === 204) {
                    dispatch(setShowAlertFeedback({ message: t('dashboard.error204'), visibility: true, signalIcon: false }));
                }
            },
            (error: ErrorRequestController) => {
                console.log("ERROR", error.response)
            }, navigate, dispatch, setIsLoading, {
            errorsFunctions: {
                error400: () => {
                    dispatch(setShowAlertFeedback({ message: `${t('dashboard.error400')}`, visibility: true, signalIcon: false }));
                },
                error422: () => {
                    dispatch(setShowAlertFeedback({ message: `${t('dashboard.error422')}`, visibility: true, signalIcon: false }))
                }
            },
            values: values
        }
        )
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1)
    }

    const getAllTicketsToPaginationFinalized_Deleted = (params: any,) => {
        return getAllMetrics(params);
    }

    const filteringTickets = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: Object, dataObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        setCurrentParams(auxObj);
        setCurrentParamsNames(dataObj);
        setcurrentFilterDatas("filtered");
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);
        setcurrentFilterDatas(undefined);
        setResetingFilters(true);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const getDate = () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return (firstDay);
    }

    const printIsValid = () => {
        const params = Object.assign(currentParams)
        reportAudit(params)
        setPrintPDF(true)
        return Promise.resolve()
    }

    const printIsInvalid = () => {
        setPrintPDF(false)
    }

    return (
        <HomeDashboard
            isLoading={isLoading}
            updateListfunction={updateListfunction}
            printPDF={printPDF}
            // phoneNumber={phoneNumber}
            // openModalPhone={openModalPhone}
            componentRef={componentRef}
            // setOpenModalPhone={setOpenModalPhone}
            currentParamsNames={currentParamsNames}
            currentState={currentState}
            setCurrentState={setCurrentState}
            user={values.user}
            filteringTickets={filteringTickets}
            cleanList={cleanList}
            token={values.token.value}
            company={values.company.id}
            resetingFilters={resetingFilters}
            setResetingFilters={setResetingFilters}
            yoursTickets={yoursTickets}
            setYoursTickets={setYoursTickets}
            getDate={getDate}
            currentDayDate={currentDayDate}
            setCurrentFilterDates={setCurrentFilterDates}
            setTag={setTag}
            setChannel={setChannel}
            setDepartmentId={setDepartmentId}
            setEmployee={setEmployee}
            setConsumer={setConsumer}
            dataChart={dataChart}
            options={options}
            popover={popover}
            data={data}
            listRankingAgents={listRankingAgents}
            currentFilterDates={currentFilterDates}
            tag={tag}
            channel={channel}
            departmentId={departmentId}
            employee={employee}
            consumer={consumer}
            currentHasMoreInformation={currentHasMoreInformation}
            currentParams={currentParams}
            setCurrentParams={setCurrentParams}
            dataEmployeeChart={dataEmployeeChart}
            dataTagPieChart={dataTagPieChart}
            dataNpsPieChart={dataNpsPieChart}
            optionsPieChart={optionsPieChart}
            dataActivePieReceptive={dataActivePieReceptive}
            dataChannelPieChart={dataChannelPieChart}
            dataSectorPieChart={dataSectorPieChart} 
            optionsBarCharEmployee={optionsBarCharEmployee}
            optionsBarCharTag={optionsBarCharTag}
            optionsBarCharChannel={optionsBarCharChannel}
            optionsBarCharSector={optionsBarCharSector}
            optionsBarCharNps={optionsBarCharNps}
            sidebarOpened={sidebarOpened}
        />
    )
}

export default HomeDashboardController