import './styles.scss';
import { FC } from 'react';
import { ModalListTasksInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconCheckupList, IconInfoCircle, IconPlus, IconSquare, IconSquareCheckFilled, IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomTableListController from '../../../../../components/customTableList/indexController';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import CustomInputController from '../../../../../components/customInput/indexController';

const ModalListTasks: FC<ModalListTasksInterface> = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      size="lg"
      centered={true}
      dialogClassName="modal-list-tasks-component"
    >
      <Modal.Header>
        <Modal.Title>
          { props.scene === 'list' && <>{props.t('title_list')} {props.ticketInfo ? <span>#{props.ticketInfo.referenceId}</span> : ''}</> }
          { props.scene === 'create' && <>{props.t('title_create')} {props.ticketInfo ? <span>#{props.ticketInfo.referenceId}</span> : ''}</> }
          { props.scene === 'edit' && <>{props.t('title_edit')} {props.ticketInfo ? <span>#{props.ticketInfo.referenceId}</span> : ''}</> }
          { props.scene === 'only-edit' && <>{props.t('title_edit_only')}</> }
        </Modal.Title>
        <div className="modal-list-tasks-header-actions">
          { props.scene === 'list' &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('tooltip_btn_create')}</Tooltip>}> 
              <span className="modal-list-tasks-header-actions-create-btn" onClick={() => props.setScene('create')}>
                <IconPlus className="modal-list-tasks-header-actions-create" />
                {props.t('btn_scene_create')}
              </span>
            </OverlayTrigger>
          }
          <IconX className="modal-list-tasks-header-actions-close" onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* { (!props.ticketInfo || !props.taskInfo || !props.data || props.isLoading || (['edit', 'only-edit'].includes(props.scene) && !props.taskData)) && <Loading blur={true}/>} */}
        { props.showLoading() && <Loading blur={true}/>}
        { (props.ticketInfo || props.taskInfo) && (props.data || props.taskData) &&
          <div className="modal-list-tasks-content">
            { props.scene === 'list' &&
              <CustomTableListController
                component='modal-tasks'
                text_empty_list={props.t('no_tasks')}
                text_loading_list={props.t('loading_tasks')}
                text_error_list={props.t('error_loading_tasks')}
                icon_entity_list={<IconCheckupList className='tabler-icon-entity' />}
                headers={props.listHeaders} 
                data={props.data}
                paginationDetails={props.paginationDetails}
                fetchMore={props.getTasksPagination}
                isLoading={props.isLoading}
                errorLoading={props.errorLoading}
                popoverItemAction={props.popoverItemAction}
                reloadData={props.reloadTasks}
                handleItemClick={props.selectItem}
              />
            }
            {
              props.scene === 'list' && props.itemDeleteConfirmation &&
              <div className="modal-list-tasks-delete-confirmation">
                <span className="modal-list-tasks-delete-title">{props.t('delete_view.title')}</span>
                <div className="modal-list-tasks-delete-info">
                  <span className="modal-list-tasks-delete-info-message">
                    {props.t('delete_view.message')}
                  </span>
                  <div className="modal-list-tasks-delete-info-fields">
                    <div className="modal-list-tasks-delete-info-fields-row">
                      <div className="modal-list-tasks-delete-info-fields-item">
                        <span className="modal-list-tasks-delete-info-fields-item-label">
                          {props.t('form.date_limit_label')}
                        </span>
                        <span className="modal-list-tasks-delete-info-fields-item-value">
                          {props.formatDate(new Date(props.itemDeleteConfirmation.date_limit))}
                        </span>
                      </div>
                      <div className="modal-list-tasks-delete-info-fields-item">
                        <span className="modal-list-tasks-delete-info-fields-item-label">
                          {props.t('form.status_label')}
                        </span>
                        <span className="modal-list-tasks-delete-info-fields-item-value">
                          {props.getStatusName(props.itemDeleteConfirmation.status_task_id)}
                        </span>
                      </div>
                    </div>
                    <div className="modal-list-tasks-delete-info-fields-item">
                      <span className="modal-list-tasks-delete-info-fields-item-label">
                        {props.t('form.description_label')}
                      </span>
                      <span className="modal-list-tasks-delete-info-fields-item-value info-fields-item-overflow">
                        {props.itemDeleteConfirmation.description}
                      </span>
                    </div>
                    <div className="modal-list-tasks-delete-info-fields-row">
                      <div className="modal-list-tasks-delete-info-fields-item">
                        <span className="modal-list-tasks-delete-info-fields-item-label">
                          {props.t('form.requester_agent_label')}
                        </span>
                        <OverlayTrigger overlay={<Tooltip>{props.itemDeleteConfirmation.requester_employee_name}</Tooltip>}>
                          <span className="modal-list-tasks-delete-info-fields-item-value">
                            {props.itemDeleteConfirmation.requester_employee_name}
                          </span>
                        </OverlayTrigger>
                      </div>
                      <div className="modal-list-tasks-delete-info-fields-item">
                        <span className="modal-list-tasks-delete-info-fields-item-label">
                          {props.t('form.assigned_agent_label')}
                        </span>
                        <OverlayTrigger overlay={<Tooltip>{props.itemDeleteConfirmation.assigned_employee_name}</Tooltip>}>
                          <span className="modal-list-tasks-delete-info-fields-item-value">
                            {props.itemDeleteConfirmation.assigned_employee_name}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-list-task-delete-footer">
                  <span className="modal-list-tasks-delete-action tasks-delete-action-cancel" onClick={() => props.showDeleteConfirmation(null, false)}>
                    {props.t('delete_view.btn_cancel')}
                  </span>
                  <span className="modal-list-tasks-delete-action tasks-delete-action-confirm" onClick={() => props.handleDeleteTask()}>
                    {props.t('delete_view.btn_delete')}
                  </span>
                </div>
              </div>
            }
            { ['create', 'edit', 'only-edit'].includes(props.scene) &&
              <div className="modal-list-tasks-form">
                <div className="modal-list-tasks-form-items-row">
                  { ['edit', 'only-edit'].includes(props.scene) && props.taskData &&
                    <div className="modal-list-tasks-form-item tasks-form-item-responsive">
                      <span className="modal-list-tasks-form-item-label">{props.t('form.requester_agent_label')}</span>
                      <span className="modal-list-tasks-form-item-fake">
                        {props.taskData.requester_employee_name}
                      </span>
                    </div>
                  }
                  <div className="modal-list-tasks-form-item tasks-form-item-responsive">
                    <span className="modal-list-tasks-form-item-label">{props.t('form.assigned_agent_label')}<span>*</span></span>
                    <CustomSelectController 
                      type='pagination'
                      dropdownPosition='body'
                      hasFilter={true}
                      showTooltipOptions={true}
                      tooltipOptionsPosition='right'
                      showIconSelected={true}
                      placeholder={props.t('form.assigned_placeholder')}
                      placeholderFilter={props.t('form.assigned_search')}
                      options={props.getAgentsSelect().agentsList || []}
                      selected={props.getAgentsSelect().selectedAgent}
                      setSelected={props.getAgentsSelect().setSelectedAgent}
                      fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                      hasMore={props.getAgentsSelect().hasMoreAgents}
                      loading={props.getAgentsSelect().isLoadingAgentsSelect}
                      search={props.getAgentsSelect().fetchSearchAgents}
                      required={true}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                  { ['edit', 'only-edit'].includes(props.scene) && props.taskData &&
                    <div className="modal-list-tasks-form-item tasks-form-item-responsive">
                      <span className="modal-list-tasks-form-item-label">
                        {props.t('form.status_label')}<span>*</span>
                      </span>
                      <CustomSelectController 
                        type='simple'
                        dropdownPosition='body'
                        placeholder={props.t('form.status_placeholder')}
                        options={props.getStatusSelect().statusList || []}
                        selected={props.getStatusSelect().selectedStatus}
                        setSelected={props.getStatusSelect().setSelectedStatus}
                        required={true}
                        disabled={props.mode === 'deleted'}
                      />
                    </div>
                  }
                </div>
                <div className="modal-list-tasks-form-items-row">
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">{props.t('form.follower_label')}</span>
                    <CustomSelectController 
                      type='pagination'
                      dropdownPosition='body'
                      hasFilter={true}
                      isMultiselect={true}
                      showTooltipOptions={true}
                      tooltipOptionsPosition='right'
                      showIconSelected={true}
                      placeholder={props.t('form.follower_placeholder')}
                      placeholderFilter={props.t('form.follower_search')}
                      options={props.getFollowersSelect().followersList || []}
                      selectedMulti={props.getFollowersSelect().selectedFollower}
                      setSelectedMulti={props.getFollowersSelect().setSelectedFollower}
                      fetchMore={props.getFollowersSelect().fetchFollowersPagination}
                      hasMore={props.getFollowersSelect().hasMoreFollowers}
                      loading={props.getFollowersSelect().isLoadingFollowersSelect}
                      search={props.getFollowersSelect().fetchSearchFollowers}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">{props.t('form.tag_label')}</span>
                    <CustomSelectController 
                      type='pagination'
                      dropdownPosition='body'
                      hasFilter={true}
                      isMultiselect={true}
                      showTooltipOptions={true}
                      tooltipOptionsPosition='right'
                      showIconSelected={true}
                      placeholder={props.t('form.tag_placeholder')}
                      placeholderFilter={props.t('form.tag_search')}
                      options={props.getTagsSelect().tagsList || []}
                      selectedMulti={props.getTagsSelect().selectedTag}
                      setSelectedMulti={props.getTagsSelect().setSelectedTag}
                      fetchMore={props.getTagsSelect().fetchTagsPagination}
                      hasMore={props.getTagsSelect().hasMoreTags}
                      loading={props.getTagsSelect().isLoadingTagsSelect}
                      search={props.getTagsSelect().fetchSearchTags}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                </div>
                <div className="modal-list-tasks-form-items-row">
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">
                      {props.t('form.description_label')}<span>*</span>
                    </span>
                    <CustomInputController 
                      type='textarea'
                      value={props.taskDescription}
                      setValue={props.setTaskDescription}
                      placeholder={props.t('form.description_placeholder')}
                      required={true}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">{props.t('form.note_label')}</span>
                    <CustomInputController 
                      type='textarea'
                      value={props.taskNote}
                      setValue={props.setTaskNote}
                      placeholder={props.t('form.note_placeholder')}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                </div>
                <div className="modal-list-tasks-form-items-row">
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">
                      {props.t('form.date_limit_label')}<span>*</span>
                    </span>
                    <CustomInputController 
                      type='datepicker'
                      placeholder={props.t('form.date_limit_placeholder')}
                      datePickerSettings={{ 
                        minDate: new Date(),
                        onChange: props.getDateInput().onSelectDate,
                        selectsRange: false,
                        startDate: props.getDateInput().dateLimit,
                        valid: props.isValidDate()
                      }}
                      required={true}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-label">
                      {props.t('form.hour_limit_label')}<span>*</span>
                    </span>
                    <CustomInputController 
                      type='timepicker'
                      placeholder={props.t('form.hour_limit_placeholder')}
                      datePickerSettings={{ 
                        minDate: new Date(), 
                        onChange: props.getDateInput().onSelectHour,
                        selectsRange: false,
                        startDate: props.getDateInput().hourLimit,
                        timeIntervals: 1,
                        timeContainerWidth: 'large',
                        valid: props.isValidDate()
                      }}
                      required={true}
                      disabled={props.mode === 'deleted'}
                    />
                  </div>
                </div>
                <div className="modal-list-tasks-form-items-row">
                  <div className="modal-list-tasks-form-item">
                    <span className="modal-list-tasks-form-item-check">
                      <span onClick={() => props.mode === 'edit' && props.setShouldNotificate(!props.shouldNotificate)}>
                        { props.shouldNotificate && <IconSquareCheckFilled /> }
                        { !props.shouldNotificate && <IconSquare /> }
                        {props.t('form.notified_check_label')}
                      </span>
                      <OverlayTrigger overlay={<Tooltip>{props.t('form.notified_check_tooltip')}</Tooltip>}>
                        <IconInfoCircle className='modal-list-tasks-form-item-check-info' />
                      </OverlayTrigger>
                    </span>
                  </div>
                  { props.notificationDate &&
                    <div className="modal-list-tasks-form-item">
                      <span className="modal-list-tasks-form-item-info">
                        {props.t('form.notification_date_label')} {props.formatDate(props.notificationDate)}
                      </span>
                    </div>
                  }
                </div>
                { props.shouldNotificate &&
                  <div className="modal-list-tasks-form-items-row">
                    <div className="modal-list-tasks-form-item">
                      <span className="modal-list-tasks-form-item-label">
                        {props.t('form.notification_period_label')}<span>*</span>
                      </span>
                      <CustomInputController 
                        type='input-number'
                        valueNumber={props.notificationPeriod}
                        valueNumberMin={1}
                        valueNumberMax={props.getMaxPeriod()}
                        setValueNumber={(value) => {
                          if (value.toString().length <= 4) {
                            props.setNotificationPeriod(value);
                          }
                        }}
                        placeholder={props.t('form.notification_period_placeholder')}
                        required={true}
                        disabled={props.mode === 'deleted'}
                      />
                    </div>
                    <div className="modal-list-tasks-form-item">
                      <span className="modal-list-tasks-form-item-label">
                        {props.t('form.notification_unit_label')}<span>*</span>
                      </span>
                      <CustomSelectController 
                        type='simple'
                        dropdownPosition='body'
                        placeholder={props.t('form.notification_unit_placeholder')}
                        options={props.getUnitPeriodSelect().timeUnitList || []}
                        selected={props.getUnitPeriodSelect().notificationUnit}
                        setSelected={props.getUnitPeriodSelect().setNotificationUnit}
                        required={true}
                        disabled={props.mode === 'deleted'}
                      />
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </Modal.Body>
      { ['create', 'edit', 'only-edit'].includes(props.scene) &&
        <Modal.Footer>
          { props.scene !== 'only-edit' &&
            <button className='modal-list-tasks-btn-cancel' onClick={() => props.handleCancel()}>{props.t('btn_back')}</button>
          }
          { props.scene === 'only-edit' &&
            <button className='modal-list-tasks-btn-cancel' onClick={() => props.hide()}>{props.t('btn_cancel')}</button>
          }
          { !props.isValid() && props.mode !== 'deleted' &&
            <span className="modal-list-tasks-error-msg">
              { !props.isValidDate() && props.t('form.invalid_date') }
              { props.isValidDate() && !props.isValidNotificationDate() && props.t('form.invalid_notification_date') }
              { props.isValidDate() && props.isValidNotificationDate() && props.t('form.invalid_field') }
            </span>
          }
          { props.mode === 'deleted' &&
            <span className="modal-list-tasks-error-msg">
              { props.t('form.task_deleted') }
            </span>
          }
          { props.scene === 'create' && 
            <button className='modal-list-tasks-btn-save' disabled={props.isLoadingSave || !props.isValid()} onClick={() => props.handleCreate()}>{props.t('btn_create')}</button> 
          }
          { (props.scene === 'edit' || props.scene === 'only-edit') &&
            <button className='modal-list-tasks-btn-save' disabled={props.isLoadingSave || !props.isValid() || props.mode === 'deleted'} onClick={() => props.handleEdit()}>{props.t('btn_edit')}</button>
          }
        </Modal.Footer>
      }
    </Modal>
  );
};

export default ModalListTasks;
