import { FC, useEffect, useState, MouseEvent, useCallback, useRef } from 'react';
import ListOfAgents from './index';
import { EmployeeInterface, FetchEmployeesInterface, ListOfAgentsControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMainAppContext } from '../../../../core/context/main-app-context';
import UserService from '../../../../services/user-service';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../components-fixed/searchComponent/indexModel';
import {
  getAgentListNameOrderBy, 
  getAgentListProfileOrderBy, 
  getAgentListSortBy, 
  setAgentListNameOrderBy, 
  setAgentListProfileOrderBy, 
  setAgentListSortBy 
} from '../../../../store/table_sort_order';
import { OrderByType, SortByType } from '../../../../core/enums/order-sort-by';
import { UserProfile } from '../../../../core/enums/user-profile';
import emitter from '../../../../core/shared/emitter';
import constsRouters from '../../../../routes/constsRouter';
import { Popover } from 'react-bootstrap';
import { IconEdit, IconTicket, IconTrashX, IconUsers } from '@tabler/icons-react';
import { getAppOpenSidebar } from '../../../../store/app_sidebar';
import { getProfileId } from '../../../../store/user';
import { useSocketV2Context } from '../../../../core/context/socket-context-v2';
import { setShowAlertFeedback } from '../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const STATUS_SIZE = '60px';
const NAME_SIZE = '200px';
const NAME_STATUS_SIZE = '260px';
const EMAIL_SIZE = '230px';
const DOCUMENT_SIZE = '170px';
const PHONE_SIZE = '170px';
const TYPE_SIZE = '120px'; // 210

const ListOfAgentsController: FC<ListOfAgentsControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfAgents');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sidebarOpened = useSelector(getAppOpenSidebar);
  const userProfile = useSelector(getProfileId);

  const agentListSortBy = useSelector(getAgentListSortBy);
  const agentListSortByRef = useRef<SortByType>(agentListSortBy);
  const agentListNameOrderBy = useSelector(getAgentListNameOrderBy);
  const agentListNameOrderByRef = useRef<OrderByType>(agentListNameOrderBy);
  const agentListProfileOrderBy = useSelector(getAgentListProfileOrderBy);
  const agentListProfileOrderByRef = useRef<OrderByType>(agentListProfileOrderBy);

  const {
    setMainHeader, 
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    filteredMode,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
    actionEvent,
    emitActionEvent,
  } = useMainAppContext();

  const { socketInitialized, registerSocketAppEvent, unregisterSocketAppEvent } = useSocketV2Context();

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<EmployeeInterface[]>([]);
  const [dataObj, setDataObj] = useState({});
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalAgent, setShowModalAgent] = useState(false);
  const [showModalDeleteAgent, setShowModalDeleteAgent] = useState(false);
  const [showModalAgentConsumers, setShowModalAgentConsumers] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);

  // ======== ATUALIZAÇÃO DA LISTAGEM VIA SOCKET ======== //
  useEffect(() => {
    if (socketInitialized) {
      registerSocketAppEvent('status-employee', (data: EmployeeInterface) => {
        emitActionEvent('update-status-agent', data);
      });
    }

    return () => {
      unregisterSocketAppEvent('status-employee');
    }
  }, [socketInitialized]);

  useEffect(() => {
    if (actionEvent?.event === 'update-status-agent' && actionEvent?.data) {
      socketEventUpdateStatusEmployee(actionEvent.data);
    }
  }, [actionEvent]);
  // =================================================== //

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (data) {
      setDataObj(getDataObject(data));
    }
  }, [data]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    const tempListHeaders: CustomTableListHeaders[] = [
      { 
        title: t("list_headers.status"), 
        field: 'status_employee_id', 
        width: STATUS_SIZE,
        hasTooltip: true,
        hidden: userProfile === UserProfile.Employee
      },
      { 
        title: t("list_headers.name"), 
        field: 'name', 
        width: userProfile === UserProfile.Employee ? NAME_STATUS_SIZE : NAME_SIZE, 
        hasTooltip: true,
        hasOrderBy: true,
        orderByFn: getAgentsOrdered,
        orderBy: agentListNameOrderBy || 'asc',
        orderType: 'text'
      },
      { title: t("list_headers.email"), field: 'email', width: EMAIL_SIZE, hasTooltip: true },
      { title: t("list_headers.document_number"), field: 'document_number', width: DOCUMENT_SIZE, hasTooltip: true },
      { title: t("list_headers.phone"), field: 'phone', width: PHONE_SIZE, hasTooltip: true },
      { 
        title: t("list_headers.type"), 
        field: 'profile_id', 
        width: TYPE_SIZE, 
        hasTooltip: true,
        hasOrderBy: true,
        orderByFn: getAgentsOrdered,
        orderBy: agentListProfileOrderBy || 'asc',
        orderType: 'default'
      }
    ];

    if (userProfile !== UserProfile.Employee) { // Só mostrar menu de ações para agentes admin, proprietário ou master
      tempListHeaders.push({ title: t("list_headers.actions"), field: 'action', width: 'auto' });
    }

    setListHeaders(tempListHeaders);
  }, []);

  useEffect(() => {
    if (apiHeader) {
      setCurrentPage(1);
      const params = { limit: LIMIT_PAGINATION, page: currentPage || 1, sort_by: agentListSortBy || 'name' };

      if (params.sort_by === 'name') {
        params['order_by'] = agentListNameOrderBy || 'asc';
      } else {
        params['order_by'] = agentListProfileOrderBy || 'asc';
      }

      getAgents(params);
    }
  }, [apiHeader]);

  useEffect(() => {
    configMainHeader();
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = () => {
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    setMainHeader({
      pageTitle: t('header.welcome_page'),
      pageSubtitle: t('header.sub_info'),
      hasSearchComponent: true,
      hasSearchInput: true,
      hasSearchFilter: true,
      searchPage: 'agents',
      searchPlaceholder: t('header.search_placeholder'),
      searchFunction: searchAgents,
      clearSearchFunction: clearSearchAgents,
      hasCreateButton: userProfile !== UserProfile.Employee,
      createButtonText: t('header.button_create'),
      createButtonTooltip: t('header.button_create_tooltip'),
      createButtonAction: openModalNewAgent,
    });
  }

  const socketEventUpdateStatusEmployee = (socket_data: EmployeeInterface) => {
    if (dataObj[socket_data.user_id]) {
      setData(prevItems => {
        for (let i = 0; i < prevItems.length; i++) {
          if (prevItems[i].user_id === socket_data.user_id) {
            prevItems[i].status_employee_id = socket_data.status_employee_id;
            break;
          }
        }
        return prevItems;
      });
    }
  }

  const getAgents = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);

    const config = { headers: apiHeader, params };

    AppRequesterConst.Get(
      '/agent', config,
      () => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }

          if (isScrollPagination) {
            const new_array = [...data, ...response.data.employees];
            setData(new_array);
          } else {
            setData(response.data.employees || []);
            setDataObj(getDataObject(response.data.employees));
          }

          processPaginationDetails(response.data.pagination);
        } else {
          setData([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getAgentsPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: agentListSortBy || 'name', ...currentFilter };

    if (params.sort_by === 'name') {
      params['order_by'] = agentListNameOrderBy || 'asc';
    } else {
      params['order_by'] = agentListProfileOrderBy || 'asc';
    }

    if (page) {
      params.page = page;
      setCurrentPage(page);
      getAgents(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getAgents(params, true);
      }
    }
  }

  const reloadAgents = (preserveFilter?: boolean) => {
    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: agentListSortBy || 'name' };

    if (params.sort_by === 'name') {
      params['order_by'] = agentListNameOrderBy || 'asc';
    } else {
      params['order_by'] = agentListProfileOrderBy || 'asc';
    }

    if (preserveFilter) {
      const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
      params.page = currentPage || 1;
      params = { ...params, ...currentFilter };
    }

    getAgents(params, false);
  }

  const searchAgents = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: agentListSortByRef.current || 'name', ...query };
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);

    if (params.sort_by === 'name') {
      params['order_by'] = agentListNameOrderByRef.current || 'asc';
    } else {
      params['order_by'] = agentListProfileOrderByRef.current || 'asc';
    }

    getAgents(params);
  }

  const clearSearchAgents = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: agentListSortByRef.current || 'name' };
    

    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }

    if (params.sort_by === 'name') {
      params['order_by'] = agentListNameOrderByRef.current || 'asc';
    } else {
      params['order_by'] = agentListProfileOrderByRef.current || 'asc';
    }

    getAgents(params);
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };

    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (key === 'sector') {
        tempFilterApplied.selectedSector = [];
      } else if (key === 'search') {
        currentSearchTerm = '';
        clearFilterTermFunction();
      }

      setFilterApplied(tempFilterApplied);
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, 'agents'), tempFilterApplied);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters = [];

    Object.keys(queryFilterRef.current).forEach(key => {
      const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
      
      if (key === 'search') {
        item.value = queryFilterRef.current[key];
      }

      if (key === 'sector') {
        item.value = filterApplied.selectedSector?.map(item => item.value).join(', ');
      }

      filters.push(item);
    });

    setFilterMode('main');

    return filters;
  }

  const getAgentsOrdered = async (field: string, order: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: field, order_by: order, ...currentFilter };

    setHeaderOrderBy(field, order);
    
    getAgents(params);
  }

  const setHeaderOrderBy = (field: string, order: 'asc' | 'desc'): void => {
    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === field) {
          item.orderBy = order;
        }
      });

      if (field === 'name') {
        dispatch(setAgentListNameOrderBy(order));
        agentListNameOrderByRef.current = order
      } else if (field === 'profile_id') {
        dispatch(setAgentListProfileOrderBy(order));
        agentListProfileOrderByRef.current = order;
      }
      dispatch(setAgentListSortBy(field as SortByType));
      agentListSortByRef.current = field as SortByType;

      return prevState;
    });
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const getDataObject = (data: EmployeeInterface[]) => {
    const obj = {};
    data.forEach(item => {
      obj[item.user_id] = item.profile_id === UserProfile.Owner ? 'disabled' : item.user_id;
    });
    return obj;
  }

  const selectItem = (item: EmployeeInterface, event: MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    event.stopPropagation();
    if (item.profile_id !== UserProfile.Owner) {
      if (selectedItems.includes(item.user_id)) {
        const new_selected = [...selectedItems];
        const index = new_selected.indexOf(item.user_id);
        if (index > -1) {
          new_selected.splice(index, 1);
          setSelectedItems(new_selected);
        }
      } else {
        const new_selected = [...selectedItems, item.user_id];
        setSelectedItems(new_selected);
      }
    }
  }

  const removeSelectedItem = useCallback((ids: string[]): void => {
    setSelectedItems(prevSelected => prevSelected.filter(id => !ids.includes(id)));
  }, []);

  const selectAllItems = (): void => {
    if (data.length > 0) {
      const localSelect = (id: string, array: string[]) => {
        if (array.includes(id)) {
          const index = array.indexOf(id);
          array.splice(index, 1);
        } else {
          array = [...array, id];
        }
        return array;
      }
  
      let count = 0;
  
      let selected = Object.assign([], selectedItems);
  
      selected.forEach(id => {
        if (dataObj[id] && dataObj[id] !== 'disabled') {
          count += 1;
        }
      });
  
      if (count === data.filter(item => item.profile_id !== UserProfile.Owner).length) { // Se todos na página tiver selecionados, remove apenas o da página
        Object.keys(dataObj).forEach(id => {
          if (dataObj[id] !== 'disabled') {
            const item = data.find(item => item.user_id === id);

            if (item?.profile_id !== UserProfile.Owner) {
              selected = localSelect(id, selected);
            }
          }
        });
      } else { // Senão, marca apenas os que estão desmarcados
        Object.keys(dataObj).forEach(id => {
          const item = data.find(item => item.user_id === id);

          if (item?.profile_id !== UserProfile.Owner && !selectedItems.includes(id)) {
            selected = localSelect(id, selected);
          }
        });
      }
  
      setSelectedItems(selected);
    }
  }

  const isSelectedItem = (id: string): boolean => {
    return selectedItems.includes(id);
  }

  const isAllSelectedItems = (): boolean => {
    if (selectedItems.length > 0) {
      let count = 0;

      Object.keys(dataObj).forEach(id => {
        if (selectedItems.includes(id)) {
          count += 1;
        }
      });

      return data.filter(item => item.profile_id !== UserProfile.Owner).length === count;
    } else {
      return false;
    }
  }

  const hasSomeSelected = (): boolean => {
    return selectedItems.length > 0;
  }

  const hasSomeSelectedCheck = (): boolean => {
    let hasSome = false;

    const pageIds = Object.keys(dataObj);

    for (let i = 0; i < pageIds.length; i++) {
      if (selectedItems.includes(pageIds[i])) {
        hasSome = true;
        break;
      }
    }

    return hasSome;
  }

  const deleteSelected = () => {
    if (selectedItems.length === 1) { // Se for só um, se comportar como o menu de ações
      const agent = data.find(item => item.user_id === selectedItems[0]);
      openModalDeleteAgent('menu-action', [{ userId: selectedItems[0], agentName: agent?.name || 'N/A' }]);
    } else {
      openModalDeleteAgent('table-action', selectedItems.map(userId => ({ userId })));
    }
  }

  const clearSelected = () => {
    setSelectedItems([]);
  }

  const clearPageSelected = () => {
    let selected = Object.assign([], selectedItems);

    Object.keys(dataObj).forEach(id => {
      if (selected.includes(id)) {
        const index = selected.indexOf(id);
        selected.splice(index, 1);
      }
    });
    setSelectedItems(selected);
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders.filter(item => !item.hidden);
  }

  const getDisabledItemClick = () => {
    return userProfile === UserProfile.Employee;
  }

  const openModalEditAgent = (item: EmployeeInterface, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (userProfile !== UserProfile.Employee) {
      setShowModalAgent(true);
      setTimeout(() => {
        emitter.emit('open-modal-agent', { userId: item.user_id });
      }, 50);
    }
  }

  const openModalNewAgent = () => {
    if (userProfile !== UserProfile.Employee) {
      setShowModalAgent(true);
      setTimeout(() => {
        emitter.emit('open-modal-agent', null);
      }, 50);
    }
  }

  const closeModalAgent = (reload: boolean) => {
    if (reload) {
      reloadAgents(true);
    }
    setShowModalAgent(false);
  }

  const openModalAgentConsumers = (item: EmployeeInterface, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (userProfile !== UserProfile.Employee) {
      setShowModalAgentConsumers(true);
      setTimeout(() => {
        emitter.emit('open-modal-list-agent-consumers', { userId: item.user_id, agentName: item.name });
      }, 50);
    }
  }

  const closeModalAgentConsumers = () => {
    setShowModalAgentConsumers(false);
  }

  const openModalDeleteAgent = (type: 'menu-action' | 'table-action', userInfo: { userId: string, agentName?: string}[]) => {
    setShowModalDeleteAgent(true);
    setTimeout(() => {
      if (type === 'menu-action') {
        emitter.emit('open-modal-delete-agent', userInfo);
      } else {
        const formatted = selectedItems.map(item => ({ userId: item }));
        emitter.emit('open-modal-delete-agent', formatted);
      }
    }, 50);
  }

  const closeModalDeleteAgent = (reload: boolean, userIds: string[]) => {
    if (reload) {
      reloadAgents(true);
      
      if (userIds?.length > 0) {
        removeSelectedItem(userIds);
      }
    }
    setShowModalDeleteAgent(false);
  }

  const handlePopoverAgentsListItemAction = (event: any, data: EmployeeInterface, type: 'open-agent-tickets' | 'open-agent-consumers' | 'edit-agent' | 'delete-agent') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-agents');

    if (type === 'open-agent-tickets') {
      navigate(
        `${constsRouters.routers.agentTickets.path}`, 
        { 
          state: {
            agentUserId: data.user_id,
            agentName: data.name || 'N/A',
            page: 'agents', 
            prevPath: constsRouters.routers.agents.path 
          } 
        }
      );
    } else if (type === 'open-agent-consumers') {
      openModalAgentConsumers(data, event);
    } else if (type === 'edit-agent') {
      openModalEditAgent(data, event);
    } else if (type === 'delete-agent') {
      openModalDeleteAgent('menu-action', [{ userId: data.user_id, agentName: data.name }]);
    } 
  }

  const popoverItemAction = (data: EmployeeInterface) => {
    const showOpenAgentTicket = true;
    const showOpenAgentConsumers = userProfile !== UserProfile.Employee;
    const showEditAgent = userProfile !== UserProfile.Employee;
    const showDeleteAgent = userProfile !== UserProfile.Employee && data.profile_id !== UserProfile.Owner;

    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenAgentTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverAgentsListItemAction(e, data, 'open-agent-tickets')}>
              <IconTicket />{t('list_popover_actions.open_agent_tickets')}
            </span>
          }
          { showOpenAgentConsumers &&
            <span className='popover-item' onClick={(e) => handlePopoverAgentsListItemAction(e, data, 'open-agent-consumers')}>
              <IconUsers />{t('list_popover_actions.open_agent_consumers')}
            </span>
          }
          { showEditAgent &&
            <span className='popover-item' onClick={(e) => handlePopoverAgentsListItemAction(e, data, 'edit-agent')}>
              <IconEdit />{t('list_popover_actions.edit_agent')}
            </span>
          }
          { showDeleteAgent &&
            <span className='popover-item' onClick={(e) => handlePopoverAgentsListItemAction(e, data, 'delete-agent')}>
              <IconTrashX />{t('list_popover_actions.delete_agent')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfAgents 
      t={t}
      listHeaders={getListHeaders}
      listData={data}
      paginationDetails={paginationDetails}
      getAgentsPagination={getAgentsPagination}
      isLoading={isLoading}
      errorLoading={errorLoading}
      reloadAgents={reloadAgents}
      selectItem={selectItem}
      selectAllItems={selectAllItems}
      isSelectedItem={isSelectedItem}
      selectedItemsLength={selectedItems.length}
      hasSomeSelected={hasSomeSelected}
      hasSomeSelectedCheck={hasSomeSelectedCheck}
      isAllSelectedItems={isAllSelectedItems}
      deleteSelected={deleteSelected}
      clearSelected={clearSelected}
      clearPageSelected={clearPageSelected}
      sidebarOpened={sidebarOpened}
      showModalAgent={showModalAgent}
      closeModalAgent={closeModalAgent}
      showModalDeleteAgent={showModalDeleteAgent}
      closeModalDeleteAgent={closeModalDeleteAgent}
      showModalAgentConsumers={showModalAgentConsumers}
      closeModalAgentConsumers={closeModalAgentConsumers}
      filters={filters}
      clearSearchAgents={clearSearchAgents}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}
      openModalEditAgent={openModalEditAgent}
      getDisabledItemClick={getDisabledItemClick}
    />
  );
};

export default ListOfAgentsController;
